import React from "react";
import logo from "../../assets/img/logo.svg";

function VerifyEmail() {
  return (
    <div id="app" className="page-login">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="user-section">
        <div className="flex-container ">
          <div className="grey-wrap bank-wrap verifyOtp-wrap">
            <div className="login-box">
              <div className="title">
                <h1>Email Verification</h1>
                <p>
                  You would have received a verification email with code in
                  user14@gmail.com.
                  <br />
                  Please provide the code below
                </p>
              </div>
              <form className="otp">
                <input
                  type="text"
                  maxLength="1"
                  value="4"
                  size="1"
                  min="0"
                  max="9"
                  pattern="[0-9]{1}"
                />
                <input
                  type="text"
                  maxLength="1"
                  value="5"
                  size="1"
                  min="0"
                  max="9"
                  pattern="[0-9]{1}"
                />
                <input
                  type="text"
                  maxLength="1"
                  value="6"
                  size="1"
                  min="0"
                  max="9"
                  pattern="[0-9]{1}"
                />
                <input
                  type="text"
                  maxLength="1"
                  value="7"
                  size="1"
                  min="0"
                  max="9"
                  pattern="[0-9]{1}"
                />
                <button type="submit" className="primary-button">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyEmail;
