import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Validation } from "../../helpers/validations";
import eyeopen from "../../assets/img/eyeopen.svg";
import eyeclose from "../../assets/img/eyeclose.svg";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import logo from "../../assets/img/logo.svg";
import login from "../../assets/img/login.svg";
import user from "../../assets/img/user.svg";
import padlock from "../../assets/img/padlock.svg";
import google from "../../assets/img/google.svg";
import facebook from "../../assets/img/facebook.svg";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  LoginAction,
  verifyEmail,
  SocialLogin,
} from "../../redux/actions/user";
import { appId, clientId } from "../../helpers/constants";
import moment from "moment-timezone";
import Store from "../../redux/store/store";
const localTZone = moment.tz.guess();
const Cryptr = require("cryptr");
const cryptr = new Cryptr("myTotalySecretKey");

function Login({ history, LoginAction, role, isAuth }) {
  const [cookies, setCookie, removeCookie] = useCookies(["UserConsent"]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [showRememberMe, setShowRememberMe] = useState(
    cookies?.UserConsent?.includes("prefer") ? true : false
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [showPass, setShowPass] = useState(false);
  // const [showMaintenance ,setShowMaintenance] = useState(false);

  const params = useParams();

  useEffect(() => {
    document.title = "LiveStage | Login";
    document.head.querySelector('meta[name="description"]').content =
      "Your Gateway to Art. Artists can stream their live events simple and free. Fans of art and culture can purchase tickets to live shows prior to or during any show. Express yourself on the live chat and communicate directly with your favorite artists.";
    document.head.querySelector('meta[name="keywords"]').content =
      "livestage , live stage, live streaming, artists, watch live streaming, watch live shows, art streaming, live events";
      document.head.querySelector('meta[property="og:title"]').content = `LiveStage | Login`;
      document.head.querySelector('meta[property="og:description"]').content = `Your Gateway to Art. Artists can stream their live events simple and free. Fans of art and culture can purchase tickets to live shows prior to or during any show. Express yourself on the live chat and communicate directly with your favorite artists.`;
      document.head.querySelector('meta[property="og:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[name="twitter:title"]').content = `LiveStage | Login`;
      document.head.querySelector('meta[name="twitter:description"]').content = `Your Gateway to Art. Artists can stream their live events simple and free. Fans of art and culture can purchase tickets to live shows prior to or during any show. Express yourself on the live chat and communicate directly with your favorite artists.`;
      document.head.querySelector('meta[name="twitter:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[property="og:url"]').content = `https://www.livestage.stream/login/`;
      document.head.querySelector('meta[property="twitter:url"]').content = `https://www.livestage.stream/login/`;
    }, [history]);

  // Login function
  const signin = (e) => {
    e.preventDefault();

    let fields = ["username", "password"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    const payload = {
      email: username,
      password,
      userTimeZone: localTZone,
      fcmToken: localStorage.getItem("fcmToken")
        ? localStorage.getItem("fcmToken")
        : "",
    };

    LoginAction(payload, onComplete);
  };

  const onComplete = (response) => {
    console.log(response);

    if (response !== 200) {
      console.log(response);
      setError({
        ...error,
        loginError: response,
      });
      setUsername("");
      setPassword("");
    }
  };

  //Validator function
  const handleValidation = (type) => {
    if (type === "username") {
      if (Validation.empty(username)) {
        setError({ ...error, username: "Please enter your registered email" });
        return false;
      } else if (!Validation.email(username)) {
        setError({
          ...error,
          username: "Please enter the email in a valid format",
        });
        return false;
      } else if (!Validation.emailLength(username)) {
        setError({ ...error, username: "Max 100 characters allowed" });
        return false;
      }
    } else if (type === "password") {
      if (Validation.empty(password)) {
        setError({ ...error, password: "Please enter your password" });
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (params.token) {
      // API call for validating email after signup
      // console.log("API Call")
      verifyEmail(params.token);
    }
  }, []);

  useEffect(() => {
    console.log(isAuth, role);
    if (isAuth) {
      // if (role === "user" || role === "corporate") {
      if (role === "User" || role === "Corporate") {
        history.push("/home/user");
        // } else if (role === "actor") {
      } else if (role === "Actor") {
        history.push("/home/actor");
      }

      if (rememberMe) {
        const encryptedString = cryptr.encrypt(password);
        localStorage.setItem(
          "savedCreds",
          JSON.stringify({ username: username, password: encryptedString })
        );
      }
    }
  }, [isAuth, role]);

  useEffect(() => {
    let creds = JSON.parse(localStorage.getItem("savedCreds"));

    if (creds) {
      const decryptedString = cryptr.decrypt(creds.password);
      setUsername(creds.username);
      setPassword(decryptedString);
      // setPassword(creds.password);
      setRememberMe(true);
    }
  }, []);

  const facebookFailureResponse = (res) =>
    console.log("Component clicked!!!", res);
  const responseFacebook = async (res) => {
    console.log("Response facebook callback!!!", res);
    const { name, email, picture, userID } = res;
    const userData = {
      name,
      email,
      picture,
      userID,
      type: "facebook",
    };

    const payload = {
      firstName: name?.slice(0, name?.indexOf(" ")),
      lastName: name?.slice(name?.indexOf(" ")),
      email: email,
      socialType: userData.type,
      socialId: userID,
      // profilePhoto: profileObj.imageUrl,
      phone: "",
      userTimeZone: localTZone,
      fcmToken: localStorage.getItem("fcmToken")
        ? localStorage.getItem("fcmToken")
        : "",
    };

    try {
      let response = await SocialLogin(payload);
      console.log(response.data);
      if (response.data.status === 200) {
        localStorage.setItem("petros-user-token", response.data.data);
        Store.store.dispatch({ type: "LOGIN", payload: response.data.user });
        if (cookies?.UserConsent?.includes("prefer")) {
          document.cookie = `jwtTokenPetros=${
            response.data.data
          }; path=/; expires=${new Date(
            new Date().getFullYear() + 100,
            new Date().getMonth(),
            new Date().getDate()
          )}`;
        } else {
          document.cookie = `jwtTokenPetros=${response.data.data}; path=/`;
        }
        localStorage.setItem("isLogout", false);
      } else {
        history.push({
          pathname: "/signup/user",
          state: userData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const responseGoogleFailure = (res) =>
    console.log("Response GOOGLE failure clicked !!!", res);
  const responseGoogle = async (res) => {
    const { profileObj } = res;
    const userData = {
      profileObj,
      type: "google",
    };
    console.log(userData);

    const payload = {
      firstName: profileObj.givenName,
      lastName: profileObj.familyName,
      email: profileObj.email,
      socialType: userData.type,
      socialId: profileObj.googleId,
      // profilePhoto: profileObj.imageUrl,
      phone: "",
      userTimeZone: localTZone,
      fcmToken: localStorage.getItem("fcmToken")
        ? localStorage.getItem("fcmToken")
        : "",
    };

    try {
      let response = await SocialLogin(payload);
      console.log(response.data);
      if (response.data.status === 200) {
        localStorage.setItem("petros-user-token", response.data.data);
        Store.store.dispatch({ type: "LOGIN", payload: response.data.user });
        if (cookies?.UserConsent?.includes("prefer")) {
          document.cookie = `jwtTokenPetros=${
            response.data.data
          }; path=/; expires=${new Date(
            new Date().getFullYear() + 100,
            new Date().getMonth(),
            new Date().getDate()
          )}`;
        } else {
          document.cookie = `jwtTokenPetros=${response.data.data}; path=/`;
        }
        localStorage.setItem("isLogout", false);
      } else {
        history.push({
          pathname: "/signup/user",
          state: userData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    if (!rememberMe) {
      const encryptedString = cryptr.encrypt(password);
      localStorage.setItem(
        "savedCreds",
        JSON.stringify({ username: username, password: encryptedString })
        // JSON.stringify({ username: username, password: password })
      );
    } else {
      localStorage.removeItem("savedCreds");
    }
  };

  return (
    <div>
      <div id="app" className="page-login">
        <header className="app-header">
          <div className="flex-container">
            <div className="flex-header">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <section className="user-section">
          <div className="flex-container">
            <div className="grey-wrap login-wrap">
              <div className="flex-row flex-centered">
                <div className="flex-col-2">
                  <div className="img side-line">
                    <img src={login} alt="Hero" />
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="login-box">
                    <div className="title">
                      <h1>Welcome!</h1>
                      <p>Please Login to continue</p>
                    </div>
                    {error && (
                      <span style={{ color: "red" }}>{error?.loginError}</span>
                    )}
                    <div className="form-login">
                      <div className="field-wrap">
                        <img className="icon-inputtype" src={user} alt="img" />
                        <input
                          type="text"
                          name=""
                          required=""
                          placeholder="Email"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          onFocus={(e) =>
                            setError({ ...error, username: "", loginError: "" })
                          }
                          onBlur={(e) => handleValidation("username")}
                          // autoFocus
                        />
                      </div>
                      {error && (
                        <span style={{ color: "red" }}>{error?.username}</span>
                      )}
                      <div className="field-wrap">
                        <a
                          className="icon-viewpswd hide"
                          onClick={() => setShowPass(!showPass)}
                        >
                          <img src={showPass ? eyeopen : eyeclose} alt="img" />
                        </a>
                        <img
                          className="icon-inputtype"
                          src={padlock}
                          alt="img"
                        />
                        <input
                          type={showPass ? "text" : "password"}
                          name=""
                          required=""
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={(e) =>
                            setError({ ...error, password: "", loginError: "" })
                          }
                          onBlur={(e) => handleValidation("password")}
                        />
                      </div>
                      {error && (
                        <span style={{ color: "red" }}>{error?.password}</span>
                      )}
                      <div
                        className={`forgotlink ${
                          !showRememberMe && "justifyEnd"
                        }`}
                      >
                        {showRememberMe && (
                          <div>
                            <label className="pure-checkbox">
                              <input
                                type="checkbox"
                                checked={rememberMe}
                                onClick={(e) => handleRememberMe()}
                              />
                              <span className="checkmark"></span>Remember Me
                            </label>
                          </div>
                        )}
                        <div>
                          <a
                            className="cursorPointer"
                             onClick={() => history.push("/forgotPassword")}
                            //onClick={()=>setShowMaintenance(true)}
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="primary-button"
                         onClick={(e) => signin(e)}
                        //  onClick={()=>setShowMaintenance(true)}
                      >
                        Continue
                      </button>
                      <p className="or">
                        <span>or sign in with</span>
                      </p>
                      <div className="flex-justify-space">
                        <a>
                          <GoogleLogin
                            clientId={clientId} // Need to provide google client Id of client here
                            icon={true}
                            render={(renderProps) => (
                              <img
                                 onClick={renderProps.onClick}
                                // onClick={()=>setShowMaintenance(true)}
                                className="cursorPointer"
                                src={google}
                                alt="google"
                              />
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogleFailure}
                          />
                        </a>
                        <a>
                          <FacebookLogin
                            appId={appId} // Need to provide facebook app Id of client here
                            autoLoad={false}
                            fields="name,email,picture"
                            onFailure={facebookFailureResponse}
                            callback={responseFacebook}
                            textButton=""
                            render={(renderProps) => (
                              <img
                                className="cursorPointer"
                                onClick={renderProps.onClick}
                               // onClick={()=>setShowMaintenance(true)}
                                src={facebook}
                                alt="facebook"
                              />
                            )}
                            disableMobileRedirect={true}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="bottom-links">
                      <p style={{ fontSize: "17px" }}>
                        Don’t have an account? <a href="#"></a>
                        <a
                          className="cursorPointer"
                           onClick={() => history.push("/signup/user")}
                          //onClick={()=>setShowMaintenance(true)}
                        >
                          <strong>Sign up</strong>
                        </a>
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        Interested to become a performer on Livestage?
                        <a
                          className="cursorPointer"
                          onClick={() => history.push("/signup/actor")}
                          //onClick={()=>setShowMaintenance(true)}
                        >
                          <strong>Please click here.</strong>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* POP UP Message */}
        {/* {showMaintenance ? (
          <div
            className="overlay-box video-confirm-pop"
            style={{ maxWidth: "800px" }}
          >
            <h3>
              LiveStage is improving. We will be back on the 21/07/2022.
              <br />
              For any inquiries please contact us at{" "}
              <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
            </h3>
            <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="primary-button"
                onClick={() => setShowMaintenance(false)}
              >
                Back
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`overlay fix-opaicty ${showMaintenance && "show"}`}
        ></div> */}
{/* ============== */}
      </div>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
    isAuth: USER_REDUCER.isAuth,
  };
};

export default connect(mapStateToProps, {
  LoginAction,
})(Login);
