import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import eyeclose from "../../assets/img/eyeclose.svg";
import eyeopen from "../../assets/img/eyeopen.svg";
import dpPhoto from "../../assets/img/img_avatar.png";
import SignUpSuccess from "./SignUpSuccess";
import { Validation } from "../../helpers/validations";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { REACT_APP_BASE_URL } from "../../helpers/env";
import {
  RegisterUserActor,
  FileUpload,
  CheckEmailForCorporateRegister,
  GetCountryList,
} from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";
import { useParams } from "react-router-dom";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("myTotalySecretKey");

function UserSignup({ history }) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [role, setRole] = useState();
  const [terms, setTerms] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [photo, setPhoto] = useState();
  const [photoPreview, setPhotoPreview] = useState();
  const [socialId, setSocialId] = useState();
  const [socialType, setSocialType] = useState();
  const [error, setError] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [disableRole, setDisableRole] = useState(false);
  const [countryList, setCountryList] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [dialCode, setDialCode] = useState("");
  // const [showMaintenance ,setShowMaintenance] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    cName: "",
    cAddress: {
      line1: "",
      line2: "",
      city: "",
      postalCode: "",
    },
    cCountry: "",
    cRegNumber: "",
    cVAT: "",
  });
  const params = useParams();

  // console.log(history.location.state);

  let SocialProfileObject = history.location?.state;

  useEffect(() => {
    document.title = "LiveStage | Signup";
    document.head.querySelector('meta[name="description"]').content =
      "Create your LiveStage account. Watch live shows from your favourite artists through an exclusive digital live experience which promotes interactivity and allows your to support artists.";
    document.head.querySelector('meta[name="keywords"]').content =
      "livestage , live stage, live streaming, artists, watch live streaming, watch live shows, art streaming, create livestage account , support artists, live events";
      document.head.querySelector('meta[property="og:title"]').content = `LiveStage | Signup`;
      document.head.querySelector('meta[property="og:description"]').content = `Create your LiveStage account. Watch live shows from your favourite artists through an exclusive digital live experience which promotes interactivity and allows your to support artists.`;
      document.head.querySelector('meta[property="og:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[name="twitter:title"]').content = `LiveStage | Signup`;
      document.head.querySelector('meta[name="twitter:description"]').content = `Create your LiveStage account. Watch live shows from your favourite artists through an exclusive digital live experience which promotes interactivity and allows your to support artists.`;
      document.head.querySelector('meta[name="twitter:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[property="og:url"]').content = `https://www.livestage.stream/signup/user/`;
      document.head.querySelector('meta[property="twitter:url"]').content = `https://www.livestage.stream/signup/user/`;
      window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // const encryptedString = cryptr.encrypt('corporate2000@yopmail.com');
    // const decryptedString = cryptr.decrypt(encryptedString);
    // console.log(encryptedString, decryptedString);

    if (params.mail) {
      // const decryptedString = cryptr.decrypt(params.mail);
      // setEmail(decryptedString);
      setEmail(params.mail);
      setDisableRole(true);
      setRole("Corporate");
    }

    GetCountryList({ mail: params?.mail })
      .then((response) => {
        // console.log(response.data.data.country);
        setCountryList(response.data.data.country);
        // setEmail(response.data.data.email);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (role === "User") {
      setCompanyDetails({
        cName: "",
        cAddress: {
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
        },
        cCountry: "",
        cRegNumber: "",
        cVAT: "",
      });
      setError({
        ...error,
        cName: "",
        cAddrLine1: "",
        cAddrCity: "",
        cAddrPostalCode: "",
        cCountry: "",
        cRegNumber: "",
        cVAT: "",
      });
    }
  }, [role]);

  // Setting user details from social signups
  useEffect(() => {
    if (SocialProfileObject?.type === "google") {
      setFirstName(SocialProfileObject?.profileObj?.givenName);
      setLastName(SocialProfileObject?.profileObj?.familyName);
      setEmail(SocialProfileObject?.profileObj?.email);
      setSocialId(SocialProfileObject?.profileObj?.googleId);
      setSocialType(SocialProfileObject.type);
      setPhotoPreview(SocialProfileObject?.profileObj?.imageUrl);
      setPhoto(SocialProfileObject?.profileObj?.imageUrl);
    } else if (SocialProfileObject?.type === "facebook") {
      setFirstName(
        SocialProfileObject?.name.slice(
          0,
          SocialProfileObject?.name.indexOf(" ")
        )
      );
      setLastName(
        SocialProfileObject?.name.slice(SocialProfileObject?.name.indexOf(" "))
      );
      setEmail(SocialProfileObject?.email);
      setSocialId(SocialProfileObject?.userID);
      setSocialType(SocialProfileObject.type);
      setPhotoPreview(SocialProfileObject?.picture?.data.url);
      setPhoto(SocialProfileObject?.picture?.data.url);
    }
  }, []);

  // Register function for End user/ Corporate User
  const handleRegister = async (e) => {
    e.preventDefault();

    // let fields = [
    //   "firstName",
    //   "lastName",
    //   "email",
    //   "phone",
    //   "password",
    //   "confirmPass",
    //   "role",
    // ];
    // let isvalid = false;
    // for (let i = 0; i < fields.length; i++) {
    //   isvalid = handleValidation(fields[i]);
    //   if (!isvalid) break;
    // }
    // if (!isvalid) return;

    if (newValidation()) {
      if (terms) {
        let payload = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          phone: "+" + phone,
          password: password ? password : "",
          socialId: socialId ? socialId : "",
          socialType: socialType ? socialType : "",
          role: role,
          profilePhoto: photo,
          legalCompanyName: companyDetails.cName,
          companyAddressLineOne: companyDetails.cAddress.line1,
          companyAddressLineTwo: companyDetails.cAddress.line2,
          companyCity: companyDetails.cAddress.city,
          companyPostalCode: companyDetails.cAddress.postalCode,
          companyEstablishmentCountry: companyDetails.cCountry,
          companyRegNo: companyDetails.cRegNumber,
          companyVatNo: companyDetails.cVAT,
          countryCode: countryCode.toUpperCase(),
          phoneNumber: phone.slice(dialCode.length)
        };

        console.log(payload);

        try {
          let response = await RegisterUserActor(payload);
          if (response && response.status === 200) {
            localStorage.setItem("petros-user-token", response.data.data);
            setShowSuccess(true);
            Notify("success", "Signed up successfully!!");
          }
        } catch (error) {
          console.log(error);
          Notify("danger", `Error: ${error.response.data.message}`);
        }
      } else {
        setError({
          ...error,
          terms: "Please agree to the Terms and Conditions and Privacy Policy",
        });
      }
    }
  };

  const newValidation = () => {
    let error = {};

    // firstname
    if (Validation.empty(firstName)) {
      error = { ...error, firstName: "First Name is required" };
    } else if (!Validation.name(firstName)) {
      error = { ...error, firstName: "Min 2 and Max 50 characters allowed" };
    }

    // lastname
    if (Validation.empty(lastName)) {
      error = { ...error, lastName: "Last Name is required" };
    } else if (!Validation.name(lastName)) {
      error = { ...error, lastName: "Min 2 and Max 50 characters allowed" };
    }

    // email
    if (Validation.empty(email)) {
      error = { ...error, email: "Email is required" };
    } else if (!Validation.email(email)) {
      error = {
        ...error,
        email: "Please enter the email in a valid format",
      };
    } else if (!Validation.emailLength(email)) {
      error = { ...error, email: "Max 100 characters allowed" };
    }
    // else if (checkEmail(email)) {
    //   // setError({ ...error, email: "Some error check console" });
    // }

    // phone
    if (Validation.empty(phone)|| !phone.slice(dialCode.length)) {
      error = { ...error, phone: "Phone number is required" };
    } else if (phone.length < 7) {
      error = {
        ...error,
        phone: "Please enter a valid phone number",
      };
    }

    if (!SocialProfileObject?.type) {
      // password
      if (Validation.empty(password)) {
        error = { ...error, password: "Password is required" };
      } else if (!Validation.password(password)) {
        error = {
          ...error,
          password:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        };
      }

      // confirm password
      if (Validation.empty(confirmPass)) {
        error = { ...error, confirmPass: "Confirm Password is required" };
      } else if (password !== confirmPass) {
        error = {
          ...error,
          confirmPass: "Passwords do not match",
        };
      }
    }

    // role
    if (Validation.empty(role)) {
      error = { ...error, role: "Please select a role" };
    }

    if (role === "Corporate") {
      if (Validation.empty(companyDetails.cName)) {
        error = { ...error, cName: "Legal Company Name is required" };
      }

      if (Validation.empty(companyDetails.cAddress.line1)) {
        error = { ...error, cAddrLine1: "Company Address is required" };
      }

      if (Validation.empty(companyDetails.cAddress.city)) {
        error = { ...error, cAddrCity: "Company City is required" };
      }

      if (Validation.empty(companyDetails.cAddress.postalCode)) {
        error = {
          ...error,
          cAddrPostalCode: "Company Postal Code is required",
        };
      } else if (companyDetails.cAddress.postalCode.length < 4) {
        error = {
          ...error,
          cAddrPostalCode: "Postal Code should be greater than 3 characters",
        };
      }

      if (Validation.empty(companyDetails.cCountry)) {
        error = { ...error, cCountry: "Country of Establishment  is required" };
      }

      if (Validation.empty(companyDetails.cRegNumber)) {
        error = { ...error, cRegNumber: "Registration Number is required" };
      } else if (companyDetails.cRegNumber.length < 7) {
        error = {
          ...error,
          cRegNumber: "Registration Number should be greater than 6 characters",
        };
      }

      if (Validation.empty(companyDetails.cVAT)) {
        error = { ...error, cVAT: "VAT Registration Number is required" };
      } else if (companyDetails.cVAT.length < 7) {
        error = {
          ...error,
          cVAT: "VAT Registration Number should be greater than 6 characters",
        };
      }
    }

    // terms
    if (!terms) {
      error = {
        ...error,
        terms: "Please agree to the Terms and Conditions and Privacy Policy",
      };
    }

    console.log(error);
    if (Object.keys(error).length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  // Validator function
  const handleValidation = (type) => {
    if (type === "firstName") {
      if (Validation.empty(firstName)) {
        setError({ ...error, firstName: "First Name is required" });
        return false;
      } else if (!Validation.name(firstName)) {
        setError({
          ...error,
          firstName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "lastName") {
      if (Validation.empty(lastName)) {
        setError({ ...error, lastName: "Last Name is required" });
        return false;
      } else if (!Validation.name(lastName)) {
        setError({ ...error, lastName: "Min 2 and Max 50 characters allowed" });
        return false;
      }
    } else if (type === "email") {
      if (Validation.empty(email)) {
        setError({ ...error, email: "Email is required" });
        return false;
      } else if (!Validation.email(email)) {
        setError({
          ...error,
          email: "Please enter the email in a valid format",
        });
        return false;
      } else if (!Validation.emailLength(email)) {
        setError({ ...error, email: "Max 100 characters allowed" });
        return false;
      }
      // else if (checkEmail(email)) {
      //   console.log("CHECK CHECK");
      //   // setError({ ...error, email: "Some error check console" });
      //   // return false;
      //   return true;
      // }
    } else if (type === "phone") {
      if (Validation.empty(phone)) {
        setError({ ...error, phone: "Phone number is required" });
        return false;
      } else if (phone.length < 7) {
        setError({
          ...error,
          phone: "Please enter a valid phone number",
        });
        return false;
      }
    } else if (!SocialProfileObject?.type && type === "password") {
      if (Validation.empty(password)) {
        setError({ ...error, password: "Password is required" });
        return false;
      } else if (!Validation.password(password)) {
        setError({
          ...error,
          password:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        });
        return false;
      }
    } else if (!SocialProfileObject?.type && type === "confirmPass") {
      if (Validation.empty(confirmPass)) {
        setError({ ...error, confirmPass: "Confirm Password is required" });
        return false;
      } else if (password !== confirmPass) {
        setError({
          ...error,
          confirmPass: "Passwords do not match",
        });
        return false;
      }
    } else if (type === "role") {
      if (Validation.empty(role)) {
        setError({ ...error, role: "Please select a role" });
        return false;
      }
    } else if (role === "Corporate") {
      if (type === "companyName") {
        if (Validation.empty(companyDetails.cName)) {
          setError({ ...error, cName: "Legal Company Name is required" });
          return false;
        }
      } else if (type === "companyAddress") {
        if (Validation.empty(companyDetails.cAddress.line1)) {
          setError({ ...error, cAddrLine1: "Company Address is required" });
          return false;
        }
      } else if (type === "companyCity") {
        if (Validation.empty(companyDetails.cAddress.city)) {
          setError({ ...error, cAddrCity: "Company City is required" });
          return false;
        }
      } else if (type === "companyPostalCode") {
        if (Validation.empty(companyDetails.cAddress.postalCode)) {
          setError({
            ...error,
            cAddrPostalCode: "Company Postal Code is required",
          });
          return false;
        } else if (companyDetails.cAddress.postalCode.length < 4) {
          setError({
            ...error,
            cAddrPostalCode: "Postal Code should be greater than 3 characters",
          });
          return false;
        }
      } else if (type === "companyCountry") {
        if (Validation.empty(companyDetails.cCountry)) {
          setError({
            ...error,
            cCountry: "Country of Establishment is required",
          });
          return false;
        }
      } else if (type === "companyReg") {
        if (Validation.empty(companyDetails.cRegNumber)) {
          setError({ ...error, cRegNumber: "Registration Number is required" });
          return false;
        } else if (companyDetails.cRegNumber.length < 7) {
          setError({
            ...error,
            cRegNumber:
              "Registration Number should be greater than 6 characters",
          });
          return false;
        }
      } else if (type === "companyVAT") {
        if (Validation.empty(companyDetails.cVAT)) {
          setError({ ...error, cVAT: "VAT Registration Number is required" });
          return false;
        } else if (companyDetails.cVAT.length < 7) {
          setError({
            ...error,
            cVAT: "VAT Registration Number should be greater than 6 characters",
          });
          return false;
        }
      }
    } else if (type === "terms") {
      if (terms) {
        setError({
          ...error,
          terms: "Please agree to the Terms and Conditions and Privacy Policy",
        });
        return false;
      }
    }
    return true;
  };

  // File Handling and upload
  const handleImage = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        if (file.size > 1048576) {
          setError({ ...error, photo: "File size is greater than 1 MB" });
          return;
        } else {
          let preview = URL.createObjectURL(file);
          setPhotoPreview(preview);

          const formdata = new FormData();
          formdata.append("image", file);

          try {
            const response = await FileUpload(formdata);

            if (response && response.status === 200) {
              // setPhoto(`${REACT_APP_BASE_URL}${response.data.fileName}`);
              setPhoto(response.data.fileName);
              setError({ ...error, photo: "" });
            }
          } catch (error) {
            console.log(error);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Check coporate email
  const checkEmail = async (testEmail) => {
    let payload = {
      email: testEmail,
    };

    console.log(payload);

    try {
      const response = await CheckEmailForCorporateRegister(payload);

      if (response && response.status === 200) {
        console.log(response.data);
        setDisableRole(false);
        // setRole("");
        return false;
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response.data.role === "Corporate") {
        setRole("Corporate");
        setDisableRole(true);
        setError({ ...error, role: "" });
        return true;
      }
      setDisableRole(false);
      setRole("");
    }
  };

  return (
    <div>
      <div id="app" className="page-login">
        <header className="app-header">
          <div className="flex-container">
            <div className="flex-header">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <section className="user-section">
          <div className="flex-container ">
            {showSuccess ? (
              <SignUpSuccess role={"User"} />
            ) : (
              <div className="grey-wrap register-wrap">
                <div className="center-box">
                  <div className="title">
                    <h1>Sign up Now</h1>
                    <p>Please fill in this form to create an account!</p>
                  </div>
                  <div className="profile-photo">
                    <div
                      className="file-field"
                      style={{
                        backgroundImage: `url(${
                          photoPreview ? `${photoPreview}` : dpPhoto
                        })`,
                        border: "0.4px solid white",
                      }}
                    >
                      {/* <img
                        src={photoPreview ? `${photoPreview}` : dpPhoto}
                        alt="img"
                      /> */}
                      <div className="icon-upload">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                    {error && (
                      <span style={{ color: "red" }}>{error?.photo}</span>
                    )}
                  </div>
                  <form className="form-signup">
                    <div className="flex-row gutter-space">
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            value={firstName}
                            required=""
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, firstName: "" })
                            }
                            onBlur={(e) => handleValidation("firstName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            value={lastName}
                            required=""
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, lastName: "" })
                            }
                            onBlur={(e) => handleValidation("lastName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.lastName}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="email"
                            name=""
                            value={email}
                            required=""
                            placeholder="Email Address"
                            disabled={disableRole}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) => setError({ ...error, email: "" })}
                            onBlur={(e) => handleValidation("email")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>{error?.email}</span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <div className="phonenumber">
                            {/* <input
                            type="text"
                            name=""
                            value={phone}
                            required=""
                            placeholder="Phone Number"
                            onChange={(e) =>
                              setPhone(e.target.value.replace(/[^0-9]/g, ""))
                            }
                            onFocus={(e) => setError({ ...error, phone: "" })}
                            onBlur={(e) => handleValidation("phone")}
                          /> */}

                            <PhoneInput
                              placeholder="Phone Number"
                              value={phone}
                              country={"us"}
                              onChange={(
                                phonenumber,
                                value,
                                country,
                                event,
                                formattedValue
                              ) => {
                                console.log(value, event, phonenumber);

                                if (value.dialCode) {
                                  if (phone) {
                                    setPhone(phonenumber);
                                  } else {
                                    setPhone(`${value.dialCode}${phone}`);
                                  }
                                  setDialCode(value.dialCode);
                                  setCountryCode(value.countryCode);
                                } else {
                                  setError({
                                    ...error,
                                    phone: "Please select dial code first",
                                  });
                                }
                              }}
                              onFocus={(e) => setError({ ...error, phone: "" })}
                              onBlur={(e) => handleValidation("phone")}
                            />
                          </div>

                          {error && (
                            <span style={{ color: "red" }}>{error?.phone}</span>
                          )}
                        </div>
                      </div>

                      {!SocialProfileObject?.type && (
                        <>
                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <a
                                className="icon-viewpswd"
                                onClick={() => setShowPass(!showPass)}
                              >
                                <img
                                  src={showPass ? eyeopen : eyeclose}
                                  alt="img"
                                />
                              </a>
                              <input
                                type={showPass ? "text" : "password"}
                                name=""
                                value={password}
                                required=""
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={(e) =>
                                  setError({ ...error, password: "" })
                                }
                                onBlur={(e) => handleValidation("password")}
                              />
                              {error && (
                                <span style={{ color: "red" }}>
                                  {error?.password}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <a
                                className="icon-viewpswd hide"
                                onClick={() =>
                                  setShowConfirmPass(!showConfirmPass)
                                }
                              >
                                <img
                                  src={showConfirmPass ? eyeopen : eyeclose}
                                  alt="img"
                                />
                              </a>
                              <input
                                type={showConfirmPass ? "text" : "password"}
                                name=""
                                value={confirmPass}
                                required=""
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirmPass(e.target.value)}
                                onFocus={(e) =>
                                  setError({ ...error, confirmPass: "" })
                                }
                                onBlur={(e) => handleValidation("confirmPass")}
                              />
                              {error && (
                                <span style={{ color: "red" }}>
                                  {error?.confirmPass}
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="flex-col-1">
                        <div className="field-wrap">
                          <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            onFocus={(e) => setError({ ...error, role: "" })}
                            onBlur={(e) => handleValidation("role")}
                            disabled={disableRole}
                            title={
                              role === "User"
                                ? "Viewer - to stream live events from your favourite artists."
                                : role === "Corporate"
                                ? " Corporate/Business - if you are a venue and would like stream private live events from artists."
                                : "Choose Account Type"
                            }
                          >
                            <option
                              style={{ backgroundColor: "black" }}
                              value=""
                            >
                              Choose Account Type
                            </option>
                            <option
                              style={{ backgroundColor: "black" }}
                              // value="user"
                              value="User"
                            >
                              Viewer - to stream live events from your favourite
                              artists.
                            </option>
                            <option
                              style={{ backgroundColor: "black" }}
                              // value="corporate"
                              value="Corporate"
                            >
                              Corporate/Business - if you are a venue and would
                              like stream private live events from artists.
                            </option>
                          </select>
                          {error && (
                            <span style={{ color: "red" }}>{error?.role}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    {role === "Corporate" && (
                      <>
                        {/* <div className="flex-col-1"> */}

                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            value={companyDetails.cName}
                            required=""
                            placeholder="Legal Company Name"
                            onChange={(e) =>
                              setCompanyDetails({
                                ...companyDetails,
                                cName: e.target.value,
                              })
                            }
                            onFocus={(e) => setError({ ...error, cName: "" })}
                            onBlur={(e) => handleValidation("companyName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>{error?.cName}</span>
                          )}
                        </div>

                        <div className="flex-row gutter-space">
                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <input
                                type="text"
                                name=""
                                value={companyDetails.cAddress?.line1}
                                required=""
                                placeholder="Company's Address Line 1"
                                onChange={(e) =>
                                  setCompanyDetails({
                                    ...companyDetails,
                                    cAddress: {
                                      ...companyDetails.cAddress,
                                      line1: e.target.value,
                                    },
                                  })
                                }
                                onFocus={(e) =>
                                  setError({ ...error, cAddrLine1: "" })
                                }
                                onBlur={(e) =>
                                  handleValidation("companyAddress")
                                }
                              />
                              {error && (
                                <span style={{ color: "red" }}>
                                  {error?.cAddrLine1}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <input
                                type="text"
                                name=""
                                value={companyDetails.cAddress?.line2}
                                required=""
                                placeholder="Company's Address Line 2 (optional)"
                                onChange={(e) =>
                                  setCompanyDetails({
                                    ...companyDetails,
                                    cAddress: {
                                      ...companyDetails.cAddress,
                                      line2: e.target.value,
                                    },
                                  })
                                }
                                // onFocus={(e) => setError({ ...error, email: "" })}
                                // onBlur={(e) => handleValidation("email")}
                              />
                              {/* {error && (
                            <span style={{ color: "red" }}>
                              {error?.companyName}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>

                        <div className="flex-row gutter-space">
                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <input
                                type="text"
                                name=""
                                value={companyDetails.cAddress?.city}
                                required=""
                                placeholder="City"
                                onChange={(e) =>
                                  setCompanyDetails({
                                    ...companyDetails,
                                    cAddress: {
                                      ...companyDetails.cAddress,
                                      city: e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ""
                                      ),
                                    },
                                  })
                                }
                                onFocus={(e) =>
                                  setError({ ...error, cAddrCity: "" })
                                }
                                onBlur={(e) => handleValidation("companyCity")}
                              />
                              {error && (
                                <span style={{ color: "red" }}>
                                  {error?.cAddrCity}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="flex-col-2">
                            <div className="field-wrap">
                              <input
                                type="text"
                                name=""
                                value={companyDetails.cAddress?.postalCode}
                                required=""
                                placeholder="Postal Code"
                                maxLength={10}
                                onChange={(e) =>
                                  setCompanyDetails({
                                    ...companyDetails,
                                    cAddress: {
                                      ...companyDetails.cAddress,
                                      postalCode: e.target.value.toUpperCase(),
                                    },
                                  })
                                }
                                onFocus={(e) =>
                                  setError({ ...error, cAddrPostalCode: "" })
                                }
                                onBlur={(e) =>
                                  handleValidation("companyPostalCode")
                                }
                              />
                              {error && (
                                <span style={{ color: "red" }}>
                                  {error?.cAddrPostalCode}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field-wrap">
                          <select
                            value={companyDetails.cCountry}
                            onChange={(e) =>
                              setCompanyDetails({
                                ...companyDetails,
                                cCountry: e.target.value,
                              })
                            }
                            onFocus={(e) =>
                              setError({ ...error, cCountry: "" })
                            }
                            onBlur={(e) => handleValidation("companyCountry")}
                          >
                            <option
                              style={{ backgroundColor: "black" }}
                              value=""
                            >
                              Choose Country of Establishment
                            </option>
                            {Object.keys(countryList)?.map((item, index) => (
                              <option
                                style={{ backgroundColor: "black" }}
                                value={item}
                                key={index}
                              >
                                {countryList[item]}
                              </option>
                            ))}
                          </select>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.cCountry}
                            </span>
                          )}
                        </div>

                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            value={companyDetails.cRegNumber}
                            required=""
                            placeholder="Registration Number"
                            maxLength={50}
                            onChange={(e) =>
                              setCompanyDetails({
                                ...companyDetails,
                                cRegNumber: e.target.value
                                  .replace(/[^a-zA-Z0-9]/g, "")
                                  .toUpperCase(),
                              })
                            }
                            onFocus={(e) =>
                              setError({ ...error, cRegNumber: "" })
                            }
                            onBlur={(e) => handleValidation("companyReg")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.cRegNumber}
                            </span>
                          )}
                        </div>

                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            value={companyDetails.cVAT}
                            required=""
                            placeholder="VAT Registration Number"
                            maxLength={30}
                            onChange={(e) =>
                              setCompanyDetails({
                                ...companyDetails,
                                cVAT: e.target.value
                                  .replace(/[^a-zA-Z0-9]/g, "")
                                  .toUpperCase(),
                              })
                            }
                            onFocus={(e) => setError({ ...error, cVAT: "" })}
                            onBlur={(e) => handleValidation("companyVAT")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>{error?.cVAT}</span>
                          )}
                        </div>
                      </>
                    )}

                    <div className="forgotlink">
                      <label
                        className="pure-checkbox"
                        style={{ cursor: "auto" }}
                      >
                        <input
                          type="checkbox"
                          // value={terms}
                          checked={terms}
                          onChange={(e) => {
                            setTerms(!terms);
                            if (!terms) {
                              setError({ ...error, terms: "" });
                            } else {
                              handleValidation("terms");
                            }
                          }}
                        />
                        <span className="checkmark cursorPointer"></span> Accept{" "}
                        <a
                          href="/termsOfUse"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Terms of use
                        </a>{" "}
                        &amp;{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Privacy policy
                        </a>
                        .
                      </label>
                      {error && (
                        <span style={{ color: "red" }}>{error?.terms}</span>
                      )}
                    </div>
                    <div className="btn-wrap text-center">
                      <button
                        type="button"
                        className="primary-button"
                        onClick={(e) => handleRegister(e)}
                        // onClick={()=>setShowMaintenance(true)}
                      >
                        Register
                      </button>
                    </div>
                    <div className="have-accuont">
                      <p>
                        Already have an account?{" "}
                        <a
                          href="javascript:void(0);"
                           onClick={() => history.push("/login")}
                          // onClick={()=>setShowMaintenance(true)}
                        >
                          <b>Log in</b>
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </section>
            {/* POP UP Message */}
            {/* {showMaintenance ? (
          <div
            className="overlay-box video-confirm-pop"
            style={{ maxWidth: "800px" }}
          >
            <h3>
              LiveStage is improving. We will be back on the 21/07/2022.
              <br />
              For any inquiries please contact us at{" "}
              <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
            </h3>
            <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="primary-button"
                onClick={() => setShowMaintenance(false)}
              >
                Back
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`overlay fix-opaicty ${showMaintenance && "show"}`}
        ></div> */}
{/* ============== */}
      </div>
    </div>
  );
}

export default UserSignup;
