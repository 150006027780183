import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function TermsCond({ role, history }) {
  useEffect(() => {
    document.title = "LiveStage | Terms And Conditions";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a
                className="cursorPointer"
                href={
                  role == "User"
                    ? "/home/user"
                    : role == "Actor"
                      ? "/home/actor"
                      : "/"
                }
              >
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Terms And Conditions</h1>
          </div>
          <div className="content miscellaneous">
            <p>
              <i>Last updated: February 2024</i>
            </p>
          </div>
          <div className="content miscellaneous">
            <p>
              The website www.LiveStage.stream and the LiveStage App
              (hereinafter both referred to as the “Platform” or “LiveStage”)
              belong to the Company “Livestage Streaming Services Ltd” (“<b>Company</b>”, “<b>We</b>”), with
              registered seat in Cyprus, 19A Nafpaktou Str., 3051 Limassol.
              Livestage is an interactive platform for live events and offers
              its services (“<b>Platform Services</b>” or “<b>Services</b>”) to two types of
              audiences, either “<b>End Users</b>” or “<b>Viewers</b>” that want to enjoy
              events from their favorite Artists/Performers or “<b>Corporates</b>” that
              would like to stream private live events from Artists/Performers
              (“<b>End Users</b>” and “<b>Corporates</b>” will be collectively referred to as
              “<b>Users</b>” or “<b>you</b>”).
            </p>
            <p>
              You are subject to the following Terms of Use
              (“Terms”), which you are requested to read carefully and use the
              Platform Services only if you totally agree with them and accept
              their full application. By using the Website by any means, you
              totally accept these Terms. The Terms of Use, together with the
              Privacy Policy are the only legally binding agreement between you
              and the Company and they apply with full force, just as if they
              had been made in writing and signed. If you do not agree to these
              Terms, you should not use LiveStage.
              {/* <span
                className="cursorPointer"
                onClick={() => history.push("/privacy")}
              >
                <u>Privacy Policy</u>
              </span>{" "}
              are the only legally binding agreement between you and the Company
              and they apply with full force, just as if they had been made in
              writing and signed. If you do not agree to these Terms, you should
              not use LiveStage.{" "} */}
            </p>
            <p>
              The Livestage Services are not offered to End Users under 18
              years. By agreeing to these Terms or by using the Platform
              Services, you represent and warrant that you have the authority,
              right, and capacity to enter into this Agreement and that you
              abide by all of the present Terms.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>1. ACCOUNT CREATION</h3>
            <div style={{ padding: "10px" }}>
              <p>
                To use the Platform Services, you have to create a personal
                account either as an <b>End User</b> or as a <b>Corporate</b>.{" "}
              </p>
              <p>
                <b>1.1 Sign up</b>. To create a personal account (“<b>Account</b>”) you
                have to submit the requested information depending on the
                account type (e.g., full name, valid e-mail address, phone
                number, password, company details etc.). By submitting your
                info, you will receive an automated e-mail with a link to verify
                your Account. Once you verify your Account, you will receive a
                second automated e-mail informing you that the registration
                procedure is complete.{" "}
              </p>
              <p>
                <b>1.2 Sign in</b>. Once your Account is activated, you will be
                able to login by using the email address and password indicated.
                You can also sign in with your Google or Facebook account.{" "}
              </p>
              <p>
                <b>1.3 Account</b>. You can edit your Account details at any
                time (e.g., add profile photo, add payment methods, link with
                accounts on other platforms such as Google and Facebook, manage
                bank account, etc.).{" "}
              </p>
              <p>
                <b>1.4 Bookings</b>. Once your Account is set up, as an “End
                User”, you will be able to purchase tickets and attend the
                events of your choice, such as music and stage shows, stand up
                comedies etc. (“<b>LiveStage Events</b>” or “<b>Events</b>”). If you register
                as a “Corporate”, you will be able to receive personal ticket
                purchase invitations from artists or purchase corporate events
                and stream the Events of your choice.{" "}
              </p>
              <p>
                <b>1.5 Contact Us</b>. In case you have any questions, you can
                contact us by submitting a message in the “Contact Us” form.{" "}
              </p>
              <p>
                <b>1.6</b> You are personally and solely liable for all actions
                which take place via your Account and for your proper Log-Out
                after the end of each use of the Services. You are obliged to
                immediately inform the Company in case you become aware of any
                unauthorized use of your Account or any possible security
                violation. You are solely responsible for the careful use of
                your Account. Company is not liable for any damage caused to
                viewers who fail to comply with the present condition.{" "}
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>2. USER’S OBLIGATIONS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>2.1</b> You may use the Platform for legitimate purposes, in
                accordance with Cypriot, European and international law, as well
                as the instructions of our Platform. You should act in
                accordance with the requirements of good faith and morality at
                all times.
              </p>
              <p>
                <b>2.2</b> It is up to you whether to use the Platform, which
                you can do using your own hardware and software. You are
                personally responsible for safeguarding your computer against
                viruses, hacking/cyberattacks, and malware.
              </p>
              <p>
                <b>2.3</b> You must refrain from doing anything that could limit
                or prevent third parties from using the Platform, and/or any
                action which may overload the Platform, undermine or corrupt it,
                bring it down, damage it or cause it to malfunction. You must
                also refrain from any illegal, abusive or improper acts when
                using the Platform.
              </p>
              <p>
                <b>2.4</b> You agree not to create false personas, multiple
                identities, multiple user Accounts, set up an Account on behalf
                of someone other than yourself, use bots or other automated
                software programs to defraud or which otherwise violate these
                Terms.
              </p>
              <p>
                <b>2.5</b> You are responsible for any harm done to us through
                your acts or omissions, which constitute a breach of law or your
                obligations under these Terms. This also includes cases where we
                become embroiled in litigation or are obliged to pay fines or
                compensation to third parties because you breached your
                obligations as a User. We reserve our right to seek redress
                against you in these cases.
              </p>
              <p>
                <b>2.6</b> Your Account may be terminated with or without a
                notice at any time, if you fail to comply with the present
                Terms.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>3. INTELLECTUAL PROPERTY RIGHTS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>3.1</b> The Platform is intellectual property of the Company,
                its licensors and suppliers according to the applicable Cypriot
                Law about copyright, as amended and currently in force as well
                as to international conventions signed by Cyprus.{" "}
              </p>
              <p>
                <b>3.2</b> Company, its licensors and suppliers maintain
                exclusively any intellectual property rights on the design,
                source code and the entire content of the Platform (including
                but not limited to pictures, music, video, graphics,
                photographs, texts and all other files of this Platform).
                Violation by any means of these rights will draw the subsequent
                penalties and liability defined by applicable laws. Except
                otherwise authorized by the Company, you agree not to copy,
                modify, publish, download, distribute by any means the content
                of the Platform. Decompiling or unauthorized use of the
                Platform’s software and source code is strictly prohibited.
              </p>
              <p>
                <b>3.3</b> Any trademarks, brand names and logos appearing on
                the Platform are either registered trademarks of the Company
                and/or third parties and they are protected by the relevant
                provisions of Cypriot and European trademark laws, and by
                international conventions and treaties. In any event the use of
                these trademarks, brand names and logos, without granting a
                license by the Company, is absolutely prohibited.
              </p>
              <p>
                <b>3.4</b> The Content of any Event is offered to the End Users
                for personal, non-commercial use. End Users are not allowed to
                use this Content for any commercial or professional purpose.{" "}
                <b>
                  You are not allowed to download, reproduce, edit, process,
                  save, publish, make available or repost the Content of any
                  Event.
                </b>{" "}
                Corporates are only allowed to stream Events for which they have
                bought a ticket to their audience in their private space or
                venue.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>4. PROCESSING OF PERSONAL DATA</h3>
            <p style={{ padding: "10px" }}>
              The processing of your personal data is carried out in accordance
              with the Privacy Policy and the Cookies Policy of the Platform.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>5. PRICING POLICY </h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>5.1</b> For each LiveStage Event, we provide certain
                information, including the ticket price. All ticket prices on
                the Platform are in euros (EUR), including the imposed VAT. We
                must receive the total amount of the ticket price in order to
                issue a ticket. Any fees of third parties, such as bank
                commission, should be charged to the User. Ticket prices may be
                subject to changes without notice. The price displayed on the
                Platform at the time of the order applies. Price changes will
                not affect confirmed orders.
              </p>
              <p>
                <b>5.2 Tips</b>. During an Event, Users may also tip Performers
                by selecting one of the amount choices provided (e.g., 5 or 10
                euros). Tipping is conducted by card. Your Tip will appear
                publicly during the Event.
              </p>
              <p>
                <b>5.3 Payment methods</b>. You can pay by using a credit or
                debit card. We save your card details to facilitate your future
                transactions. If we engage a third-party payment service
                provider, your payment information is not visible to us and it
                is transferred with the highest encryption standards to our
                provider.{" "}
              </p>
              <p>
                <b>5.4 Transaction security</b>. Our Company uses Stripe
                (https://stripe.com) to provide the highest level of security
                for payments processed through this Platform. Stripe ensures
                your security by encrypting the connection when transmitting
                banking information on the network, and by safeguarding your
                financial data throughout the ordering and payment process. The
                security of the Platform is validated through an SSL
                Certificate. Please note that if the security department
                suspects fraud, we have the right to cancel the transaction for
                security reasons. In most cases, you will be notified of
                authorization or fraud issues, if they occur, before you
                complete the checkout process.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>6. POLICY FOR EVENT RESCHEDULING, DELAY, OR CANCELLATION </h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>6.1 Event Rescheduling</b>. When a Performer reschedules an
                Event, the Event ticket already paid is valid for the new Event
                date. A notification about the rescheduling will be sent to your
                account and you will be informed by email too.{" "}
              </p>
              <p>
                <b>6.2 Event Cancellation – Voucher</b>. When you have already
                paid a ticket for an Event, and the Performer cancels the Event,
                we offer you a voucher of the same value. You will be informed
                about this by email and by a notification sent to your account.
                You can redeem your voucher when booking a new Event of equal or
                higher value than your voucher by entering the voucher code. The
                voucher value will be deducted from the total price of your new
                ticket. This voucher is valid for one (1) year.{" "}
              </p>
              <p>
                <b>6.3 Event delay</b>. If the Event delays by ten (10) minutes,
                the Event is automatically canceled, and we offer users a
                voucher of the same value. You will be informed accordingly by
                email and by a notification sent to your account.
              </p>
              <p>
                <b>6.4 Force Majeure</b>. In no event shall Company be held
                liable to you for any failure, delay, malperformance or
                non-performance of its obligations arising out or caused by
                forces beyond its reasonable control including without
                limitation strikes, acts of war or terrorism, pandemic,
                lockdown, governmental orders, civil or military disturbances,
                natural catastrophes, loss or malfunctions of utilities,
                communications or computer services. Company’s obligations may
                be suspended during a force majeure event.{" "}
              </p>
              <p>
                <b>6.5 No right of withdrawal</b>. Under the Cyprus Law on
                Consumer Rights and distance contracts, as in force, services
                associated with leisure activities are expressly excluded from
                the right of withdrawal, within the meaning of this law, when
                the contract stipulates a specific date or time limit.
                Therefore, this exception applies to all kinds of tickets for
                LiveStage Events made available through the Platform. By
                registering for our Services,{" "}
                <b>
                  you understand that you are not entitled to the right of
                  withdrawal. LiveStage Event tickets are non-refundable
                </b>
                .
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>7. CODE OF CONDUCT</h3>
            <div style={{ padding: "10px" }}>
              <p>
                The following rules, policies, and disclaimers shall govern
                and/or apply to your use of the Platform. You agree that by
                using the Platform, its chats, communication methods, and any
                and all its Services you shall not:
              </p>
              <p>
                1. Upload, post, transmit or otherwise disseminate Content that
                is vulgar, indecent, obscene, pornographic, sexual or that is,
                in a reasonable person's view, otherwise offensive or
                objectionable;
              </p>
              <p>
                2. Libel, ridicule, defame, mock, stalk, intimidate, threaten,
                harass, or abuse anyone, hatefully, racially, ethnically or in
                any other manner;
              </p>
              <p>
                3. Upload or transmit (or attempt to upload or transmit) files
                that contain viruses, Trojan horses, worms, time bombs,
                cancelbots, corrupted files or data, or any other similar
                software or programs that may damage the operation of LiveStage
                or the computers of other users of the Platform;
              </p>
              <p>
                4. Violate the contractual, personal, intellectual property or
                other rights of any party including by using, uploading,
                transmitting, distributing, or otherwise making available any
                information or material made available through the Service in
                any manner that infringes any copyright, trademark, patent,
                trade secret, or other right of any party (including rights of
                privacy or publicity);
              </p>
              <p>
                5. Create false personas, multiple identities, multiple user
                Accounts, set up an Account on behalf of someone other than
                yourself, use bots or other automated software programs to
                defraud or which otherwise violate these Terms and Conditions.
              </p>
              <p>
                6. Post or publish by any means personal data of other persons
                without their explicit consent.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>8. TERMINATION</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>8.1</b> The Company reserves the right to terminate your
                access to the Platform or suspend any and all Services, as well
                as any registered Account immediately, without prior notice or
                liability, for any reason, including the breach of any of these
                Terms. Upon termination of the Account, the viewer’s right to
                use the Services will automatically cease.
              </p>
              <p>
                <b>8.2</b> If you wish to terminate your Account, you can simply
                stop using the Services of the Platform and deactivate your
                Account.{" "}
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>9. LINKS TO THIRD PARTY WEBSITES</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>9.1</b> The Platform may contain links to other websites,
                which are controlled by third parties. In no event shall Company
                or its representatives be held liable for the content of such
                sites, nor for any financial or other form of loss or damage
                suffered by a viewer or User visiting such sites. Under no
                circumstances shall Company or its representatives guarantee
                that these links will operate without interruption. Furthermore,
                these external links are indicative, not exclusive or limiting
                in nature. Company reserves the right to remove or add links
                without notice, and to change the content of the Platform at any
                time.
              </p>
              <p>
                <b>9.2</b> When using LiveStage, you acknowledge that under no
                circumstances shall Company or its representatives be held
                liable for the content of the hyperlinks it contains, the
                services/products they may offer or the advertisements they may
                feature.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>10. NO WARRANTY</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>10.1</b> All services and any kind of content of the Platform
                is offered ‘as is’, with no guarantee, explicit or implied, of
                any kind in relation to its commercial use or its suitability
                for any specific purpose.
              </p>
              <p>
                <b>10.2</b> No explicit guarantee is given that the pages,
                services, features, options and contents of the Platform will be
                provided without interruption or errors, or that any errors will
                be corrected. Despite our efforts, there is no guarantee that
                the Platformdoes not contain viruses or other harmful content.
              </p>
              <p>
                <b>10.3</b> LiveStage may modify, update, or change the scope of
                the Services at any time. If we consider that a change of scope
                is of major importance to you, you will be notified with a
                statement sent to your account
              </p>
              <p>
                <b>10.4</b> In no event shall LiveStage or its contractors,
                partners and employees be held liable for any damage or loss the
                viewer may sustain from the use of this Platform.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>11. LIMITATION OF LIABILITY</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>11.1</b> Given the nature and volume of the information on
                the Internet, under no circumstances, including that of
                negligence, shall Company or its representatives be held liable
                for any form of loss or damage sustained by the viewer of the
                services, options and content of the Platform, use of which
                he/she makes entirely and solely at his/her own initiative and
                responsibility. Under no circumstances, including, but not
                limited to, negligence will Company or its representatives be
                held liable for any form of loss or damage arising out of or
                relating to these Terms or that result from your use or your
                inability to use the Services of the Platform.{" "}
              </p>
              <p>
                <b>11.2</b> You acknowledge that you may be exposed to content
                that may be offensive, indecent or illegal in general. Company
                shall not be held liable for the content provided by Performers
                during each Event and you hereby waive any legal rights or
                remedies you may have against Company with respect thereto. In
                case you see any inappropriate, disrespectful or other illegal
                content during an Event on the Platform, you can use the
                “Report” button to inform us of such action.{" "}
              </p>
              <p>
                <b>11.3</b> During an Event a public chat is available to all
                End-Users who have bought a ticket. End-Users will be able to
                comment on the live chat during the Event, and LiveStage does
                not perform any check or control on their comments. You
                acknowledge and accept that You and other End-Users may be
                exposed to offensive, indecent, inappropriate or even illegal
                and harmful content in the live chat of the Event. You may
                report the user posting this kind of content and they will be
                banned from the live chat of the Event. Under no circumstances,
                including, but not limited to, negligence will the Company or
                its representatives be held liable for any form of loss or
                damage that you or other End-Users may sustain from the use of
                the live chat.{" "}
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>12. INDEMNIFICATION</h3>
            <p style={{ padding: "10px" }}>
              You are liable towards Company and its Third Party providers, for
              any damage caused as a result of illegal or harmful use of the
              Platform from your side, as well as for the illicit use of the
              Services provided by LiveStage or any use in a manner that does
              not comply with the present Terms. You agree to indemnify, defend
              and hold us harmless immediately on demand, from and against all
              liability, damages, losses, costs and expenses, including legal
              fees, arising out of any breach of these Terms by you or other
              liabilities arising out of your use of LiveStage.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>13. AMENDMENT OF TERMS</h3>
            <p style={{ padding: "10px" }}>
              Company reserves the right to modify at any time the contents and
              scope of these Terms even without notice. For this reason, we
              encourage you to visit this page from time to time to get informed
              of any changes. In the event of any subsequent amendment of the
              Terms, if you use the Platform at a future date, this shall imply
              that you accept the new Terms.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>14. APPLICABLE LAW AND OTHER TERMS</h3>
            <p style={{ padding: "10px" }}>
              The above Terms, and any amendment thereof, are governed and
              supplemented by Cypriot law, the law of the European Union and the
              relevant international conventions to which Cyprus is a signatory.
              It is agreed that all the above Terms of Use are of equal
              substance. If any provision of the above is contrary to the law,
              shall automatically cease to apply and shall be removed, without
              affecting in any way the validity of the other terms. You
              irrevocably agree that the courts of Nicosia Cyprus shall have
              exclusive jurisdiction to settle any dispute arising out of these
              Terms & Conditions.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>15. DISCLOSURES</h3>
            <p style={{ padding: "10px" }}>
              Ιf you have any questions regarding the above Terms or would like
              more information, please contact us via e-mail at
              desk@livestage.stream or write us at 103B Arch. Makarios III
              Avenue, 3021 Limassol, Cyprus.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, null)(TermsCond);
