import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {

  useEffect(() => {
    document.title = "LiveStage | 404";
  }, []);

  return (
    <div className="error">
      <div className="error-code">404</div>
      <div className="error-content">
        <div className="error-message">Page Not Found</div>
        <div className="error-desc">
          The page you're looking for doesn't exist. <br />
          Sorry for the inconvenience, please Go Back to Home.
        </div>
        <div>
          <Link to="/" className="primary-button">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
