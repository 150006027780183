import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import eyeclose from "../../assets/img/eyeclose.svg";
import eyeopen from "../../assets/img/eyeopen.svg";
import addSvg from "../../assets/img/add.svg";
import dpPhoto from "../../assets/img/img_avatar.png";
import SignUpSuccess from "./SignUpSuccess";
import MinusPNG from "../../assets/img/icon-minus-lite.png";
import { Validation } from "../../helpers/validations";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { REACT_APP_BASE_URL } from "../../helpers/env";
import {
  RegisterUserActor,
  BankDetails,
  FileUpload,
  FileUploadAdmin,
  GetCategoryList,
  GetCountryList,
} from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";

function ActorSignup({ history }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [terms, setTerms] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [photo, setPhoto] = useState();
  const [photoPreview, setPhotoPreview] = useState();
  const [frontId, setFrontId] = useState();
  const [backId, setBackId] = useState();
  const [selfieId, setSelfieId] = useState();
  const [frontidAdmin, setFrontIdAdmin] = useState();
  const [backidAdmin, setBackIdAdmin] = useState();
  const [selfieidAdmin, setSelfieIdAdmin] = useState();
  const [frontIdName, setFrontIdName] = useState("");
  const [backIdName, setBackIdName] = useState("");
  const [selfieIdName, setSelfieIdName] = useState("");
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [code, setCode] = useState();
  const [acHolderName, setAcHolderName] = useState();
  const [isBank, setIsBank] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState({});
  const [bankError, setBankError] = useState({});
  const [actorCountry, setActorCountry] = useState("");
  const [socialMediaArray, setSocialMediaArray] = useState([
    {
      link: "",
    },
  ]);

  const [exampleArray, setExampleArray] = useState([
    {
      link: "",
    },
  ]);
  const [countryList, setCountryList] = useState("");
  const [companyDetails, setCompanyDetails] = useState({
    cName: "",
    cAddress: {
      line1: "",
      line2: "",
      city: "",
      postalCode: "",
    },
    cCountry: "",
    cRegNumber: "",
    cVAT: "",
  });
  const [yesno, setYesNo] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [dialCode, setDialCode] = useState("");
  // const [showMaintenance ,setShowMaintenance] = useState(false);

  useEffect(() => {
    document.title = "LiveStage | Register as a Performer";
    document.head.querySelector('meta[name="description"]').content =
      "Free live streaming - No cost to register as an artist, to create an event or to stream your live event to your fans. Artists can showcase their live shows and make a living online! You choose the date/time, and ticket price. This is your online stage.";
    document.head.querySelector('meta[name="keywords"]').content =
      "stream your events , live stage, live streaming, artists, make a living online, simple live streaming, stream simple, live streaming platform, live stream, showcase talent, innovation live stream, ticketed live stream, ticketed live streaming , sell tickets online, live events, stream live events";
      document.head.querySelector('meta[property="og:title"]').content = `LiveStage | Register as a Performer`;
      document.head.querySelector('meta[property="og:description"]').content = `Free live streaming - No cost to register as an artist, to create an event or to stream your live event to your fans. Artists can showcase their live shows and make a living online! You choose the date/time, and ticket price. This is your online stage.`;
      document.head.querySelector('meta[property="og:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[name="twitter:title"]').content = `LiveStage | Register as a Performer`;
      document.head.querySelector('meta[name="twitter:description"]').content = `Free live streaming - No cost to register as an artist, to create an event or to stream your live event to your fans. Artists can showcase their live shows and make a living online! You choose the date/time, and ticket price. This is your online stage.`;
      document.head.querySelector('meta[name="twitter:image"]').content = `https://www.livestage.stream/favicon-325x325.png`;
      document.head.querySelector('meta[property="og:url"]').content = `https://www.livestage.stream/signup/actor/`;
      document.head.querySelector('meta[property="twitter:url"]').content = `https://www.livestage.stream/signup/actor/`;
      window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    GetCountryList()
      .then((response) => {
        setCountryList(response.data.data.country);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  // Fetching Category List
  useEffect(() => {
    GetCategoryList()
      .then((response) => {
        setCategoryList([...response.data.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    if (yesno === "no") {
      setCompanyDetails({
        cName: "",
        cAddress: {
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
        },
        cCountry: "",
        cRegNumber: "",
        cVAT: "",
      });
      setError({
        ...error,
        cName: "",
        cAddrLine1: "",
        cAddrCity: "",
        cAddrPostalCode: "",
        cCountry: "",
        cRegNumber: "",
        cVAT: "",
      });
    }
  }, [yesno]);

  // Register function for Actor
  const handleRegister = async (e) => {
    e.preventDefault();

    // let fields = [
    //   "firstName",
    //   "lastName",
    //   "email",
    //   "phone",
    //   "password",
    //   "confirmPass",
    //   "socialMediaLink",
    //   "workExampleLink",
    //   "category",
    //   "frontId",
    //   "backId",
    //   "selfieId",
    // ];

    // let isvalid = false;
    // for (let i = 0; i < fields.length; i++) {
    //   isvalid = handleValidation(fields[i]);
    //   if (!isvalid) break;
    // }
    // if (!isvalid) return;

    if (newValidation()) {
      for (let i = 0; i < socialMediaArray.length; i++) {
        if (socialMediaArray[i].link === "") {
          console.log("in for loop socialMEdia", socialMediaArray[i].link);
          setError({
            ...error,
            socialMediaLink: "Social Media Links cannot be empty",
          });
          return;
        }
      }

      for (let i = 0; i < exampleArray.length; i++) {
        if (exampleArray[i].link === "") {
          setError({
            ...error,
            workExampleLink: "Work Examples cannot be empty",
          });
          return;
        }
      }

      if (terms) {
        let payload = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          phone: "+" + phone,
          password: password,
          // socialId: "",
          // socialType: "",
          // role: "actor",
          role: "Actor",
          frontid: frontId,
          backid: backId,
          selfieid: selfieId,
          profilePhoto: photo,
          category: category,
          socialLink: socialMediaArray,
          workExample: exampleArray,
          actorDocIssueCountry: actorCountry,
          legalCompanyName: companyDetails.cName,
          companyAddressLineOne: companyDetails.cAddress.line1,
          companyAddressLineTwo: companyDetails.cAddress.line2,
          companyCity: companyDetails.cAddress.city,
          companyPostalCode: companyDetails.cAddress.postalCode,
          companyEstablishmentCountry: companyDetails.cCountry,
          companyRegNo: companyDetails.cRegNumber,
          companyVatNo: companyDetails.cVAT,
          frontidAdmin : frontidAdmin,
          backidAdmin : backidAdmin,
          selfieidAdmin : selfieidAdmin,
          countryCode: countryCode.toUpperCase(),
          phoneNumber: phone.slice(dialCode.length)
        };

        console.log(payload);

        try {
          let response = await RegisterUserActor(payload);

          if (response && response.status === 200) {
            localStorage.setItem("petros-user-token", response.data.data);
            setIsBank(true);
          }
        } catch (error) {
          console.log(error);

          Notify("danger", `Error: ${error.response.data.message}`);
        }
      } else {
        setError({
          ...error,
          terms: "Please agree to the Terms and Conditions and Privacy Policy",
        });
      }
    }
  };

  // Bank Details Registration for Actor
  const handleBankDetails = async (e) => {
    let fields = ["bankName", "accountNumber", "code", "acHolderName"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleBankValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    let payload = {
      bankName: bankName,
      accountNumber: accountNumber,
      code: code,
      // iban: iban
      accountHolderName: acHolderName,
    };

    console.log(payload);

    try {
      let response = await BankDetails(payload);

      if (response && response.status === 200) {
        Notify("success", "Details have been submitted successfully!!");

        setShowSuccess(true);
      }
    } catch (error) {
      console.log(error);

      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  const newValidation = () => {
    let error = {};

    // firstname
    if (Validation.empty(firstName)) {
      error = { ...error, firstName: "First Name is required" };
    } else if (!Validation.name(firstName)) {
      error = { ...error, firstName: "Min 2 and Max 50 characters allowed" };
    }

    // lastname
    if (Validation.empty(lastName)) {
      error = { ...error, lastName: "Last Name is required" };
    } else if (!Validation.name(lastName)) {
      error = { ...error, lastName: "Min 2 and Max 50 characters allowed" };
    }

    // email
    if (Validation.empty(email)) {
      error = { ...error, email: "Email is required" };
    } else if (!Validation.email(email)) {
      error = {
        ...error,
        email: "Please enter the email in a valid format",
      };
    } else if (!Validation.emailLength(email)) {
      error = { ...error, email: "Max 100 characters allowed" };
    }

    // phone
    if (Validation.empty(phone)) {
      error = { ...error, phone: "Phone number is required" };
    } else if (phone.length < 7) {
      error = {
        ...error,
        phone: "Please enter a valid phone number",
      };
    }

    // password
    if (Validation.empty(password)) {
      error = { ...error, password: "Password is required" };
    } else if (!Validation.password(password)) {
      error = {
        ...error,
        password:
          "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
      };
    }

    // confirm password
    if (Validation.empty(confirmPass)) {
      error = { ...error, confirmPass: "Confirm Password is required" };
    } else if (password !== confirmPass) {
      error = {
        ...error,
        confirmPass: "Passwords do not match",
      };
    }

    //socialMediaLink
    if (socialMediaArray[0].link === "") {
      error = { ...error, socialMediaLink: "Social Media Links are required" };
    }

    // workExampleLink
    if (exampleArray[0].link === "") {
      error = { ...error, workExampleLink: "Work Examples are required" };
    }

    //category
    if (Validation.empty(category)) {
      error = { ...error, category: "Please select a category" };
    }

    // frontID
    if (Validation.empty(frontId)) {
      error = { ...error, frontId: "Front ID is required" };
    }

    // backID
    if (Validation.empty(backId)) {
      error = { ...error, backId: "Back ID is required" };
    }

    // selfieID
    if (Validation.empty(selfieId)) {
      error = { ...error, selfieId: "Selfie ID is required" };
    }

    // actorCountry
    if (Validation.empty(actorCountry)) {
      error = { ...error, actorCountry: "Country of Issue of ID is required" };
    }

    // yesno
    if (Validation.empty(yesno)) {
      error = { ...error, yesno: "Please select an option" };
    }

    if (yesno === "yes") {
      if (Validation.empty(companyDetails.cName)) {
        error = { ...error, cName: "Legal Company Name is required" };
      }

      if (Validation.empty(companyDetails.cAddress.line1)) {
        error = { ...error, cAddrLine1: "Company Address is required" };
      }

      if (Validation.empty(companyDetails.cAddress.city)) {
        error = { ...error, cAddrCity: "Company City is required" };
      }

      if (Validation.empty(companyDetails.cAddress.postalCode)) {
        error = {
          ...error,
          cAddrPostalCode: "Company Postal Code is required",
        };
      } else if (companyDetails.cAddress.postalCode.length < 4) {
        error = {
          ...error,
          cAddrPostalCode: "Postal Code should be greater than 3 characters",
        };
      }

      if (Validation.empty(companyDetails.cCountry)) {
        error = { ...error, cCountry: "Country of Establishment  is required" };
      }

      if (Validation.empty(companyDetails.cRegNumber)) {
        error = { ...error, cRegNumber: "Registration Number is required" };
      } else if (companyDetails.cRegNumber.length < 7) {
        error = {
          ...error,
          cRegNumber: "Registration Number should be greater than 6 characters",
        };
      }

      if (Validation.empty(companyDetails.cVAT)) {
        error = { ...error, cVAT: "VAT Registration Number is required" };
      } else if (companyDetails.cVAT.length < 7) {
        error = {
          ...error,
          cVAT: "VAT Registration Number should be greater than 6 characters",
        };
      }
    }

    // terms
    if (!terms) {
      error = {
        ...error,
        terms: "Please agree to the Terms and Conditions and Privacy Policy",
      };
    }

    console.log(error);
    if (Object.keys(error).length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  // Validator function for Bank Details
  const handleValidation = (type) => {
    if (type === "firstName") {
      if (Validation.empty(firstName)) {
        setError({ ...error, firstName: "First Name is required" });
        return false;
      } else if (!Validation.name(firstName)) {
        setError({
          ...error,
          firstName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "lastName") {
      if (Validation.empty(lastName)) {
        setError({ ...error, lastName: "Last Name is required" });
        return false;
      } else if (!Validation.name(lastName)) {
        setError({ ...error, lastName: "Min 2 and Max 50 characters allowed" });
        return false;
      }
    } else if (type === "email") {
      if (Validation.empty(email)) {
        setError({ ...error, email: "Email is required" });
        return false;
      } else if (!Validation.email(email)) {
        setError({
          ...error,
          email: "Please enter the email in a valid format",
        });
        return false;
      } else if (!Validation.emailLength(email)) {
        setError({ ...error, email: "Max 100 characters allowed" });
        return false;
      }
    } else if (type === "phone") {
      if (Validation.empty(phone)) {
        setError({ ...error, phone: "Phone Number is required" });
        return false;
      } else if (phone.length < 7) {
        setError({
          ...error,
          phone: "Please enter a valid phone number",
        });
        return false;
      }
    } else if (type === "password") {
      if (Validation.empty(password)) {
        setError({ ...error, password: "Password is required" });
        return false;
      } else if (!Validation.password(password)) {
        setError({
          ...error,
          password:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        });
        return false;
      }
    } else if (type === "confirmPass") {
      if (Validation.empty(confirmPass)) {
        setError({ ...error, confirmPass: "Confirm Password is required" });
        return false;
      } else if (password !== confirmPass) {
        setError({
          ...error,
          confirmPass: "Passwords do not match",
        });
        return false;
      }
    } else if (type === "socialMediaLink") {
      if (socialMediaArray[0].link === "") {
        setError({
          ...error,
          socialMediaLink: "Social Media Links are required",
        });
        return false;
      }
    } else if (type === "workExampleLink") {
      if (exampleArray[0].link === "") {
        setError({ ...error, workExampleLink: "Work Examples are required" });
        return false;
      }
    } else if (type === "category") {
      if (Validation.empty(category)) {
        setError({ ...error, category: "Please select a category" });
        return false;
      }
    } else if (type === "terms") {
      if (terms) {
        setError({
          ...error,
          terms: "Please agree to the Terms and Conditions and Privacy Policy",
        });
        return false;
      }
    } else if (type === "frontId") {
      if (Validation.empty(frontId)) {
        setError({ ...error, frontId: "Front ID is required" });
        return false;
      }
    } else if (type === "backId") {
      if (Validation.empty(backId)) {
        setError({ ...error, backId: "Back ID is required" });
        return false;
      }
    } else if (type === "selfieId") {
      if (Validation.empty(selfieId)) {
        setError({ ...error, selfieId: "Selfie ID is required" });
        return false;
      }
    } else if (type === "actorCountry") {
      if (Validation.empty(actorCountry)) {
        setError({
          ...error,
          actorCountry: "Country of Issue of ID is required",
        });
        return false;
      }
    } else if (yesno === "yes") {
      if (type === "companyName") {
        if (Validation.empty(companyDetails.cName)) {
          setError({ ...error, cName: "Legal Company Name is required" });
          return false;
        }
      } else if (type === "companyAddress") {
        if (Validation.empty(companyDetails.cAddress.line1)) {
          setError({ ...error, cAddrLine1: "Company Address is required" });
          return false;
        }
      } else if (type === "companyCity") {
        if (Validation.empty(companyDetails.cAddress.city)) {
          setError({ ...error, cAddrCity: "Company City is required" });
          return false;
        }
      } else if (type === "companyPostalCode") {
        if (Validation.empty(companyDetails.cAddress.postalCode)) {
          setError({
            ...error,
            cAddrPostalCode: "Company Postal Code is required",
          });
          return false;
        } else if (companyDetails.cAddress.postalCode.length < 4) {
          setError({
            ...error,
            cAddrPostalCode: "Postal Code should be greater than 3 characters",
          });
          return false;
        }
      } else if (type === "companyCountry") {
        if (Validation.empty(companyDetails.cCountry)) {
          setError({
            ...error,
            cCountry: "Country of Establishment is required",
          });
          return false;
        }
      } else if (type === "companyReg") {
        if (Validation.empty(companyDetails.cRegNumber)) {
          setError({ ...error, cRegNumber: "Registration Number is required" });
          return false;
        } else if (companyDetails.cRegNumber.length < 7) {
          setError({
            ...error,
            cRegNumber:
              "Registration Number should be greater than 6 characters",
          });
          return false;
        }
      } else if (type === "companyVAT") {
        if (Validation.empty(companyDetails.cVAT)) {
          setError({ ...error, cVAT: "VAT Registration Number is required" });
          return false;
        } else if (companyDetails.cVAT.length < 7) {
          setError({
            ...error,
            cVAT: "VAT Registration Number should be greater than 6 characters",
          });
          return false;
        }
      }
    }
    return true;
  };

  // Validator function for Bank Details
  const handleBankValidation = (type) => {
    if (type === "bankName") {
      if (Validation.empty(bankName)) {
        setBankError({ ...bankError, bankName: "Bank Name is required" });
        return false;
      } else if (!Validation.name(bankName)) {
        setBankError({
          ...bankError,
          bankName: "Min 2 & Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "accountNumber") {
      if (Validation.empty(accountNumber)) {
        setBankError({
          ...bankError,
          accountNumber: "IBAN / Account Number is required",
        });
        return false;
      }
      // else if (!Validation.number(accountNumber)) {
      //   setBankError({ ...bankError, accountNumber: "Should be a number" });
      //   return false;
      // }
      // else if (accountNumber.length < 9 || accountNumber.length > 18) {
      else if (accountNumber.length < 6 || accountNumber.length > 34) {
        setBankError({
          ...bankError,
          // accountNumber: "Min 9 & Max 18 digits allowed",
          accountNumber: "Min 6 & Max 34 alphanumeric characters allowed",
        });
        return false;
      }
    } else if (type === "code") {
      if (Validation.empty(code)) {
        setBankError({
          ...bankError,
          code: "Swift code / Sort code is required",
        });
        return false;
      } else if (code.length < 8 || code.length > 11) {
        setBankError({
          ...bankError,
          code: "Min 8 & Max 11 characters allowed",
        });
        return false;
      }
    } else if (type === "acHolderName") {
      if (Validation.empty(acHolderName)) {
        setBankError({
          ...bankError,
          acHolderName: "Account Holder's Name is required",
        });
        return false;
      } else if (!Validation.name(acHolderName)) {
        setBankError({
          ...bankError,
          acHolderName: "Min 2 & Max 50 characters allowed",
        });
        return false;
      }
    }
    return true;
  };

  // Update Social Media Link Text
  const handleSocialMediaLink = (text, index) => {
    let newArray = socialMediaArray.map((links, i) =>
      index == i
        ? {
            ...links,
            link: text,
          }
        : links
    );

    setSocialMediaArray(newArray);
  };

  // Add New Social Media Field
  const addSocialMedia = () => {
    setSocialMediaArray([
      ...socialMediaArray,
      {
        link: "",
      },
    ]);
  };

  // Remove Social Media Link
  const removeSocialMedia = (index) => {
    let newArray = socialMediaArray.filter((item, i) => index !== i);
    setSocialMediaArray(newArray);
  };

  // Update Talent Example Link Text
  const handleExampleLink = (text, index) => {
    let newArray = exampleArray.map((links, i) =>
      index == i
        ? {
            ...links,
            link: text,
          }
        : links
    );

    setExampleArray(newArray);
  };

  // Add New Example Link Text
  const addExampleLink = () => {
    setExampleArray([
      ...exampleArray,
      {
        link: "",
      },
    ]);
  };

  // Remove Example Link
  const removeExample = (index) => {
    let newArray = exampleArray.filter((item, i) => index !== i);
    setExampleArray(newArray);
  };

  // File handling and Upload
  const handleImage = async (file, text) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        const formdata = new FormData();
        formdata.append("image", file);

        if (text === "profile") {
          if (file.size > 1048576) {
            setError({ ...error, photo: "File size is greater than 1 MB" });
            return;
          } else {
            let preview = URL.createObjectURL(file);
            setPhotoPreview(preview);

            try {
              const response = await FileUpload(formdata);

              if (response && response.status === 200) {
                // setPhoto(`${REACT_APP_BASE_URL}${response.data.fileName}`);
                setPhoto(response.data.fileName);
                setError({ ...error, photo: "" });
              }
            } catch (error) {
              console.log(error);
            }
          }
        } else if (text === "frontid") {
          if (file.size > 1048576) {
            setError({ ...error, frontId: "File size is greater than 1 MB" });
            return;
          } else {
            setFrontIdName(
              file.name.length > 15 ? file.name.slice(0, 15) + "..." : file.name
            );
            try {
              const formdataAdmin = new FormData();
              formdataAdmin.append("file", file);

              const response = await FileUpload(formdata);
              const result = await FileUploadAdmin(formdataAdmin);


              if (response && response.status === 200) {
                // setFrontId(`${REACT_APP_BASE_URL}${response.data.fileName}`);
                setFrontId(response.data.fileName);
                setFrontIdAdmin(`${REACT_APP_BASE_URL}${result.data.fileName}`);
                setError({ ...error, frontId: "" });
              }
            } catch (error) {
              console.log(error);
            }
          }
        } else if (text === "backid") {
          if (file.size > 1048576) {
            setError({ ...error, backId: "File size is greater than 1 MB" });
            return;
          } else {
            setBackIdName(
              file.name.length > 15 ? file.name.slice(0, 15) + "..." : file.name
            );
            try {
              const formdataAdmin = new FormData();
              formdataAdmin.append("file", file);

              const response = await FileUpload(formdata);
              const result = await FileUploadAdmin(formdataAdmin);


              if (response && response.status === 200) {
                // setBackId(`${REACT_APP_BASE_URL}${response.data.fileName}`);
                setBackId(response.data.fileName);
                setBackIdAdmin(`${REACT_APP_BASE_URL}${result.data.fileName}`);
                setError({ ...error, backId: "" });
              }
            } catch (error) {
              console.log(error);
            }
          }
        } else if (text === "selfieid") {
          if (file.size > 1048576) {
            setError({ ...error, selfieId: "File size is greater than 1 MB" });
            return;
          } else {
            setSelfieIdName(
              file.name.length > 15 ? file.name.slice(0, 15) + "..." : file.name
            );
            try {
              const formdataAdmin = new FormData();
              formdataAdmin.append("file", file);

              const response = await FileUpload(formdata);
              const result = await FileUploadAdmin(formdataAdmin);

              if (response && response.status === 200) {
                // setSelfieId(`${REACT_APP_BASE_URL}${response.data.fileName}`);
                setSelfieId(response.data.fileName);
                setSelfieIdAdmin(`${REACT_APP_BASE_URL}${result.data.fileName}`);
                setError({ ...error, selfieId: "" });
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div id="app" className="page-login">
        <header className="app-header">
          <div className="flex-container">
            <div className="flex-header">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <section className="user-section">
          <div className="flex-container">
            {showSuccess ? (
              <SignUpSuccess role={"Actor"} />
            ) : isBank ? (
              <div className="grey-wrap bank-wrap">
                <div className="center-box">
                  <div className="title">
                    <h1>Bank Details</h1>
                    <p>Bank Account Information</p>
                  </div>
                  <form>
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        placeholder="Bank Name*"
                        value={bankName}
                        onChange={(e) => {
                          // setBankName(e.target.value.replace(/[0-9]/g,""));
                          setBankName(e.target.value);
                        }}
                        onFocus={(e) =>
                          setBankError({ ...bankError, bankName: "" })
                        }
                        onBlur={(e) => handleBankValidation("bankName")}
                      />
                      {bankError && (
                        <span style={{ color: "red" }}>
                          {bankError?.bankName}
                        </span>
                      )}
                    </div>
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        placeholder="Account Holder's Name*"
                        value={acHolderName}
                        // maxLength="50"
                        onChange={(e) => {
                          // setIban(e.target.value.replace(/[^A-Z0-9]/g, ""))
                          // setAcHolderName(e.target.value.replace(/[0-9]/g,""));
                          setAcHolderName(e.target.value);
                        }}
                        onFocus={(e) =>
                          setBankError({ ...bankError, acHolderName: "" })
                        }
                        onBlur={(e) => handleBankValidation("acHolderName")}
                      />
                      {bankError && (
                        <span style={{ color: "red" }}>
                          {bankError?.acHolderName}
                        </span>
                      )}
                    </div>
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        placeholder="IBAN / Account Number*"
                        value={accountNumber}
                        // maxLength="31"
                        onChange={(e) =>
                          setAccountNumber(
                            // e.target.value.replace(/[^0-9]/g, "")
                            e.target.value
                              .replace(/[^a-zA-Z0-9]/g, "")
                              .toUpperCase()
                          )
                        }
                        onFocus={(e) =>
                          setBankError({ ...bankError, accountNumber: "" })
                        }
                        onBlur={(e) => handleBankValidation("accountNumber")}
                      />
                      {bankError && (
                        <span style={{ color: "red" }}>
                          {bankError?.accountNumber}
                        </span>
                      )}
                    </div>
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        placeholder="Swift code / Sort code*"
                        value={code}
                        // maxLength="11"
                        onChange={(e) =>
                          setCode(
                            e.target.value
                              .replace(/[^a-zA-Z0-9]/g, "")
                              .toUpperCase()
                          )
                        }
                        onFocus={(e) =>
                          setBankError({ ...bankError, code: "" })
                        }
                        onBlur={(e) => handleBankValidation("code")}
                      />
                      {bankError && (
                        <span style={{ color: "red" }}>{bankError?.code}</span>
                      )}
                    </div>
                    <p className="note">
                      Note: Please provide your bank details. Let's make sure
                      you can receive the funds from your events.
                    </p>
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="primary-button full-width"
                        onClick={(e) => handleBankDetails(e)}
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="grey-wrap register-wrap">
                <div className="center-box">
                  <div className="title">
                    <h1>Thank you for your interest!</h1>
                    <p>
                      Please complete the form below so you can create your
                      Livestage performer account.
                    </p>
                  </div>
                  <div className="profile-photo">
                    <div
                      className="file-field"
                      style={{
                        backgroundImage: `url(${
                          photoPreview ? `${photoPreview}` : dpPhoto
                        })`,
                        border: "0.4px solid white",
                      }}
                    >
                      {/* <img
                        src={photoPreview ? `${photoPreview}` : dpPhoto}
                        alt="img"
                      /> */}
                      <div className="icon-upload">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            handleImage(e.target.files[0], "profile")
                          }
                        />
                      </div>
                    </div>
                    {error && (
                      <span style={{ color: "red" }}>{error?.photo}</span>
                    )}
                  </div>
                  <form className="form-signup">
                    <div className="flex-row gutter-space">
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            required=""
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            onFocus={(e) =>
                              setError({ ...error, firstName: "" })
                            }
                            onBlur={(e) => handleValidation("firstName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="text"
                            name=""
                            required=""
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            onFocus={(e) =>
                              setError({ ...error, lastName: "" })
                            }
                            onBlur={(e) => handleValidation("lastName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.lastName}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <input
                            type="email"
                            name=""
                            required=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email Address"
                            onFocus={(e) => setError({ ...error, email: "" })}
                            onBlur={(e) => handleValidation("email")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>{error?.email}</span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <div className="phonenumber">
                            <PhoneInput
                              placeholder="Phone Number"
                              country={"us"}
                              value={phone}
                              onChange={(
                                phonenumber,
                                value,
                                country,
                                event,
                                formattedValue
                              ) => {
                                // console.log(value, event, phonenumber);

                                if (value.dialCode) {
                                  if (phone) {
                                    setPhone(phonenumber);
                                  } else {
                                    setPhone(`${value.dialCode}${phone}`);
                                  }
                                  setDialCode(value.dialCode);
                                  setCountryCode(value.countryCode);
                                } else {
                                  setError({
                                    ...error,
                                    phone: "Please select dial code first",
                                  });
                                }
                              }}
                              onFocus={(e) => setError({ ...error, phone: "" })}
                              onBlur={(e) => handleValidation("phone")}
                            />
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>{error?.phone}</span>
                          )}
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <a
                            className="icon-viewpswd"
                            onClick={() => setShowPass(!showPass)}
                          >
                            <img
                              src={showPass ? eyeopen : eyeclose}
                              alt="img"
                            />
                          </a>
                          <input
                            type={showPass ? "text" : "password"}
                            name=""
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            onFocus={(e) =>
                              setError({ ...error, password: "" })
                            }
                            onBlur={(e) => handleValidation("password")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.password}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <a
                            className="icon-viewpswd hide"
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                          >
                            <img
                              src={showConfirmPass ? eyeopen : eyeclose}
                              alt="img"
                            />
                          </a>
                          <input
                            type={showConfirmPass ? "text" : "password"}
                            name=""
                            required=""
                            value={confirmPass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                            placeholder="Confirm Password"
                            onFocus={(e) =>
                              setError({ ...error, confirmPass: "" })
                            }
                            onBlur={(e) => handleValidation("confirmPass")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.confirmPass}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          {socialMediaArray.map((links, index) => (
                            <div
                              className="inner-field-wrapper minus-outer"
                              key={index}
                            >
                              {index < 1 ? (
                                <a
                                  className="icon-viewpswd cursorPointer"
                                  onClick={() => addSocialMedia()}
                                >
                                  <img src={addSvg} alt="img" />
                                </a>
                              ) : (
                                <a
                                  className="icon-minus-field cursorPointer"
                                  style={{ top: "0px" }}
                                  onClick={() => removeSocialMedia(index)}
                                >
                                  <img src={MinusPNG} alt="img" />
                                </a>
                              )}

                              <input
                                type="text"
                                name=""
                                required=""
                                value={links.link}
                                onChange={(e) =>
                                  handleSocialMediaLink(e.target.value, index)
                                }
                                onFocus={(e) =>
                                  setError({ ...error, socialMediaLink: "" })
                                }
                                placeholder="Social Media Link*"
                              />
                            </div>
                          ))}

                          <p className="suggestion">
                            Please provide us links of your official page(s)
                          </p>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.socialMediaLink}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          {exampleArray.map((links, index) => (
                            <div
                              className="inner-field-wrapper minus-outer"
                              key={index}
                            >
                              {index < 1 ? (
                                <a
                                  className="icon-viewpswd hide cursorPointer"
                                  onClick={() => addExampleLink()}
                                >
                                  <img src={addSvg} alt="img" />
                                </a>
                              ) : (
                                <a
                                  className="icon-minus-field cursorPointer"
                                  style={{ top: "0px" }}
                                  onClick={() => removeExample(index)}
                                >
                                  <img src={MinusPNG} alt="img" />
                                </a>
                              )}
                              <input
                                type="text"
                                name=""
                                required=""
                                value={links.link}
                                onChange={(e) =>
                                  handleExampleLink(e.target.value, index)
                                }
                                onFocus={(e) =>
                                  setError({ ...error, workExampleLink: "" })
                                }
                                placeholder="Example of your work*"
                              />
                            </div>
                          ))}

                          <p>Please provide us a link showcasing your work</p>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.workExampleLink}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-1">
                        <div className="field-wrap">
                          <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, category: "" })
                            }
                            onBlur={(e) => handleValidation("category")}
                          >
                            <option
                              style={{ backgroundColor: "black" }}
                              value=""
                            >
                              Select Category
                            </option>

                            {categoryList.map(
                              (cat, index) =>
                                cat.actorCategory && (
                                  <option
                                    style={{ backgroundColor: "black" }}
                                    value={cat.categoryName}
                                    key={index}
                                  >
                                    {cat.categoryName}
                                  </option>
                                )
                            )}
                          </select>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.category}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <div className="field-upload">
                            <label className="label-upload">Upload ID </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImage(e.target.files[0], "frontid")
                              }
                            />
                            <p>
                              {frontIdName ? frontIdName : "No files selected"}
                            </p>
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.frontId}
                            </span>
                          )}
                          <p>Upload the front side of your ID</p>
                        </div>
                      </div>
                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <div className="field-upload">
                            <label className="label-upload">Upload ID</label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImage(e.target.files[0], "backid")
                              }
                            />
                            <p>
                              {backIdName ? backIdName : "No files selected"}
                            </p>
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.backId}
                            </span>
                          )}
                          <p>Upload the back side of your ID</p>
                        </div>
                      </div>
                      <div className="flex-col-1">
                        <div className="field-wrap">
                          <div className="field-upload">
                            <label className="label-upload">
                              Upload Selfie{" "}
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImage(e.target.files[0], "selfieid")
                              }
                            />
                            <p>
                              {selfieIdName
                                ? selfieIdName
                                : "No files selected"}
                            </p>
                          </div>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.selfieId}
                            </span>
                          )}
                          <p>
                            Upload a selfie holding your ID and facing the
                            camera
                          </p>
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <label
                            className="label-upload"
                            style={{
                              color: "#ffffff",
                              background: "transparent",
                              cursor: "text",
                            }}
                          >
                            Country of issue of ID :
                          </label>
                        </div>
                      </div>

                      <div className="flex-col-2">
                        <div className="field-wrap">
                          <select
                            value={actorCountry}
                            onChange={(e) => setActorCountry(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, actorCountry: "" })
                            }
                            onBlur={(e) => handleValidation("actorCountry")}
                          >
                            <option
                              style={{ backgroundColor: "black" }}
                              value=""
                            >
                              Choose Country
                            </option>
                            {Object.keys(countryList)?.map((item, index) => (
                              <option
                                style={{ backgroundColor: "black" }}
                                value={item}
                                key={index}
                              >
                                {countryList[item]}
                              </option>
                            ))}
                          </select>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.actorCountry}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field-wrap">
                      <label
                        className="label-upload"
                        style={{
                          color: "#ffffff",
                          background: "transparent",
                          cursor: "text",
                        }}
                      >
                        Do you have a company?
                      </label>
                      <div className="note">
                        <p>
                          Note: In order to host corporate events, you must
                          complete your company details.
                        </p>
                      </div>

                      <div className="flex-row">
                        <div className="ticket corpTicket">
                          <label className="pure-checkbox">
                            <input
                              type="radio"
                              value="yes"
                              name="yesno"
                              onChange={(e) => {
                                setYesNo(e.target.value);
                                setError({
                                  ...error,
                                  yesno: "",
                                });
                              }}
                            />
                            <span
                              className="checkmark"
                              style={{ top: "5px" }}
                            ></span>
                            Yes
                          </label>
                        </div>

                        <div className="ticket corpTicket">
                          <label className="pure-checkbox">
                            <input
                              type="radio"
                              value="no"
                              name="yesno"
                              onChange={(e) => {
                                setYesNo(e.target.value);
                                setError({
                                  ...error,
                                  yesno: "",
                                });
                              }}
                            />
                            <span
                              className="checkmark"
                              style={{ top: "5px" }}
                            ></span>
                            No
                          </label>
                        </div>
                      </div>
                      {error && (
                        <span style={{ color: "red" }}>{error?.yesno}</span>
                      )}

                      {yesno === "yes" && (
                        <div>
                          <div className="field-wrap">
                            <input
                              type="text"
                              name=""
                              value={companyDetails.cName}
                              required=""
                              placeholder="Legal Company Name"
                              onChange={(e) =>
                                setCompanyDetails({
                                  ...companyDetails,
                                  cName: e.target.value,
                                })
                              }
                              onFocus={(e) => setError({ ...error, cName: "" })}
                              onBlur={(e) => handleValidation("companyName")}
                            />
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cName}
                              </span>
                            )}
                          </div>

                          <div className="flex-row gutter-space">
                            <div className="flex-col-2">
                              <div className="field-wrap">
                                <input
                                  type="text"
                                  name=""
                                  value={companyDetails.cAddress?.line1}
                                  required=""
                                  placeholder="Company's Address Line 1"
                                  onChange={(e) =>
                                    setCompanyDetails({
                                      ...companyDetails,
                                      cAddress: {
                                        ...companyDetails.cAddress,
                                        line1: e.target.value,
                                      },
                                    })
                                  }
                                  onFocus={(e) =>
                                    setError({ ...error, cAddrLine1: "" })
                                  }
                                  onBlur={(e) =>
                                    handleValidation("companyAddress")
                                  }
                                />
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.cAddrLine1}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="flex-col-2">
                              <div className="field-wrap">
                                <input
                                  type="text"
                                  name=""
                                  value={companyDetails.cAddress?.line2}
                                  required=""
                                  placeholder="Company's Address Line 2 (optional)"
                                  onChange={(e) =>
                                    setCompanyDetails({
                                      ...companyDetails,
                                      cAddress: {
                                        ...companyDetails.cAddress,
                                        line2: e.target.value,
                                      },
                                    })
                                  }
                                  // onFocus={(e) => setError({ ...error, email: "" })}
                                  // onBlur={(e) => handleValidation("email")}
                                />
                                {/* {error && (
                            <span style={{ color: "red" }}>
                              {error?.companyName}
                            </span>
                          )} */}
                              </div>
                            </div>
                          </div>

                          <div className="flex-row gutter-space">
                            <div className="flex-col-2">
                              <div className="field-wrap">
                                <input
                                  type="text"
                                  name=""
                                  value={companyDetails.cAddress?.city}
                                  required=""
                                  placeholder="City"
                                  onChange={(e) =>
                                    setCompanyDetails({
                                      ...companyDetails,
                                      cAddress: {
                                        ...companyDetails.cAddress,
                                        city: e.target.value.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        ),
                                      },
                                    })
                                  }
                                  onFocus={(e) =>
                                    setError({ ...error, cAddrCity: "" })
                                  }
                                  onBlur={(e) =>
                                    handleValidation("companyCity")
                                  }
                                />
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.cAddrCity}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="flex-col-2">
                              <div className="field-wrap">
                                <input
                                  type="text"
                                  name=""
                                  value={companyDetails.cAddress?.postalCode}
                                  required=""
                                  placeholder="Postal Code"
                                  maxLength={10}
                                  onChange={(e) =>
                                    setCompanyDetails({
                                      ...companyDetails,
                                      cAddress: {
                                        ...companyDetails.cAddress,
                                        postalCode:
                                          e.target.value.toUpperCase(),
                                      },
                                    })
                                  }
                                  onFocus={(e) =>
                                    setError({ ...error, cAddrPostalCode: "" })
                                  }
                                  onBlur={(e) =>
                                    handleValidation("companyPostalCode")
                                  }
                                />
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.cAddrPostalCode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="field-wrap">
                            <select
                              value={companyDetails.cCountry}
                              onChange={(e) =>
                                setCompanyDetails({
                                  ...companyDetails,
                                  cCountry: e.target.value,
                                })
                              }
                              onFocus={(e) =>
                                setError({ ...error, cCountry: "" })
                              }
                              onBlur={(e) => handleValidation("companyCountry")}
                            >
                              <option
                                style={{ backgroundColor: "black" }}
                                value=""
                              >
                                Choose Country of Establishment
                              </option>
                              {Object.keys(countryList)?.map((item, index) => (
                                <option
                                  style={{ backgroundColor: "black" }}
                                  value={item}
                                  key={index}
                                >
                                  {countryList[item]}
                                </option>
                              ))}
                            </select>
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cCountry}
                              </span>
                            )}
                          </div>

                          <div className="field-wrap">
                            <input
                              type="text"
                              name=""
                              value={companyDetails.cRegNumber}
                              required=""
                              placeholder="Registration Number"
                              maxLength={50}
                              onChange={(e) =>
                                setCompanyDetails({
                                  ...companyDetails,
                                  cRegNumber: e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .toUpperCase(),
                                })
                              }
                              onFocus={(e) =>
                                setError({ ...error, cRegNumber: "" })
                              }
                              onBlur={(e) => handleValidation("companyReg")}
                            />
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cRegNumber}
                              </span>
                            )}
                          </div>

                          <div className="field-wrap">
                            <input
                              type="text"
                              name=""
                              value={companyDetails.cVAT}
                              required=""
                              placeholder="VAT Registration Number"
                              maxLength={30}
                              onChange={(e) =>
                                setCompanyDetails({
                                  ...companyDetails,
                                  cVAT: e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .toUpperCase(),
                                })
                              }
                              onFocus={(e) => setError({ ...error, cVAT: "" })}
                              onBlur={(e) => handleValidation("companyVAT")}
                            />
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cVAT}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="note">
                      <p>
                        Note: This information is collected upon registration so
                        we can verify your identity and allow you to be a
                        performer on LiveStage
                      </p>
                    </div>
                    <div className="forgotlink">
                      <label
                        className="pure-checkbox"
                        style={{ cursor: "auto" }}
                      >
                        <input
                          type="checkbox"
                          // value={terms}
                          checked={terms}
                          onChange={(e) => {
                            setTerms(!terms);
                            if (!terms) {
                              setError({ ...error, terms: "" });
                            } else {
                              handleValidation("terms");
                            }
                          }}
                        />
                        <span className="checkmark cursorPointer"></span> Accept{" "}
                        <a
                          href="/termsAndConditions"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Terms And Conditions
                        </a>{" "}
                        &amp;{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Privacy policy
                        </a>
                        .
                      </label>
                      {error && (
                        <span style={{ color: "red" }}>{error?.terms}</span>
                      )}
                    </div>
                    <div className="btn-wrap text-center">
                      <button
                        type="button"
                        className="primary-button"
                         onClick={(e) => handleRegister(e)}
                        //onClick={()=>setShowMaintenance(true)}
                      >
                        Continue
                      </button>
                    </div>
                    <div className="have-accuont">
                      <p>
                        Already have an account?
                        <a
                          className="cursorPointer"
                           onClick={() => history.push("/login")}
                         // onClick={()=>setShowMaintenance(true)}
                        >
                          <b>Log in</b>
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </section>

    {/* POP UP Message */}
    {/* {showMaintenance ? (
          <div
            className="overlay-box video-confirm-pop"
            style={{ maxWidth: "800px" }}
          >
            <h3>
              LiveStage is improving. We will be back on the 21/07/2022.
              <br />
              For any inquiries please contact us at{" "}
              <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
            </h3>
            <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="primary-button"
                onClick={() => setShowMaintenance(false)}
              >
                Back
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`overlay fix-opaicty ${showMaintenance && "show"}`}
        ></div> */}
{/* ============== */}

      </div>
    </div>
  );
}

export default ActorSignup;
