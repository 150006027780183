import React from "react";
import Logo from "react-loader-spinner";

function Loader() {
  return (
    <div
      className="custom-loader"
    >
      <Logo
        type="TailSpin"
        color="red"
        secondaryColor="red"
        height={75}
        width={100}
      />
    </div>
  );
}

export default Loader;
