import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function Privacy({ role, history }) {
  useEffect(() => {
    document.title = "LiveStage | Privacy Policy";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a
                className="cursorPointer"
                href={
                  role == "User"
                    ? "/home/user"
                    : role == "Actor"
                      ? "/home/actor"
                      : "/"
                }
              >
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Privacy Policy</h1>
          </div>
          <div className="content miscellaneous">
            <p>
              <i>Last updated: November 2021</i>
            </p>
          </div>
          <div className="content miscellaneous">
            <p>
              The website www.LiveStage.stream and the LiveStage App
              (hereinafter both referred to as the <b>Platform</b> “<b>Platform</b>” or
              “<b>LiveStage</b>”) belong to the company “Livestage Streaming Services Ltd”
              (“<b>Company</b>”, “<b>us</b>”, “<b>our</b>” “<b>we</b>”). We respect the protection of
              Platform visitors and users (“<b>Users</b>”, “<b>you</b>”, “<b>your</b>”) personal data
              and their privacy and comply with the applicable data protection
              and privacy laws. This Policy explains how we process your
              personal data when you use the Platform and its services
              (“Platform Services”). We encourage you to read it carefully. We
              have written this Policy in plain language to facilitate its
              understanding and help you decide whether you want to provide us
              with your data voluntarily and freely.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>1. WHO IS THE DATA CONTROLLER?</h3>
            <div style={{ padding: "10px" }}>
              <p>
                The Platform is operated by “<b>Livestage Streaming Services Ltdx </b>”, a company
                legally seated in Cyprus, 19A Nafpaktou Str., 3051 Limassol
                (“<b>Company</b>”). Company is the Data Controller within the meaning
                of the General Data Protection Regulation (EU) 2016/679
                (“GDPR”).
              </p>
              <p>
                <u>Contact details:</u>
              </p>
              <p>Tel: +30 6940977479, +357 99447316</p>
              <p>E-mail: desk@LiveStage.stream </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>2. WHAT PERSONAL DATA DO WE COLLECT?</h3>
            <div style={{ padding: "10px" }}>
              <p>
                Company collects and processes personal data that you
                voluntarily provide in the following cases:
              </p>
              <h3>ACCOUNT AND BOOKING INFORMATION</h3>
              <p>a) Performers/Artists</p>
              <p>
                When You create a personal account on the Platform, You have to
                submit personal information. Such information may indicatively
                be your full name, valid e-mail address, phone number, password,
                your official page, category (e.g., comedian, DJ. etc.), photo
                of personal ID, country of issue of ID, selfie, etc. as well as
                bank account details (bank name, account holder’s name,
                IBAN/Account number, swift code/sort code). If You wish to
                create Corporate Events though, You will be asked to submit your
                company details, since Corporate Events are considered a B2B
                service. You may edit your account details and provide more data
                at any time (e.g., add profile photo, description, company
                details, manage bank account details, etc.). When You create an
                Event, You are required to fill in certain information about the
                Event (e.g., determine how much You would like to earn per
                ticket e.g.).
              </p>
              <p>
                b) End Users – Corporates (collectively referred to as “Users”)
              </p>
              <p>
                When You create a personal account on the Platform, You have to
                submit personal information. Such information may indicatively
                be your full name, valid e-mail address, phone number, password,
                company’s details (etc. company’s name, registration number
                etc.). You can always edit your account details and provide more
                data (e.g., add profile photo, description, company details,
                etc.). Your card details are stored in order to facilitate your
                experience to the Platform. You can delete your card details at
                any time, through your account. When You purchase Event tickets,
                we also collect bank account details.
              </p>
              <h3>SIGN IN OR LINK WITH THIRD PARTY PROVIDERS’ ACCOUNTS</h3>
              <p>
                Once you register, you can either sign in by submitting the
                email address and password indicated or sign in with Third Party
                Providers’ account (e.g., Google, Facebook). In this case, Third
                Party Providers will disclose to us certain information
                necessary for signing in (e.g., Google provides us with the
                following data: Your full name, email address, language
                preference, profile picture). These Third Party Providers
                undertake to inform you about what kind of data they disclose to
                us. We also have access to certain information when you link
                your account on LiveStage with other account on Third Party
                Providers platforms (e.g., full name, email address, profile
                picture).
              </p>
              <h3>INFORMATION COLLECTED DURING AND AFTER A LIVESTAGE EVENT</h3>
              <p>
                During a LiveStage Event, we may collect some information about
                you through the following features/services:
              </p>
              <p>
                (a) <b>Live chat</b>. LiveStage offers a live chat service,
                where users can submit comments during an Event.
              </p>
              <p>
                (b) <b>Tips</b>. Users may also tip Performers. The fact that a
                User tips a Performer as well as the exact amount of money that
                such User selects to tip are publicly available in the chat.{" "}
              </p>
              <p>
                (c) <b>Rating</b>. After the Event is over, Users can rate the
                Performer (e.g., “perfect”/”bad”). The ratings of each Performer
                are publicly available to the Platform Users.
              </p>
              <h3>CONTACT FORM</h3>
              <p>
                When you submit inquiries through the Contact Form of LiveStage,
                we also collect your full name, e-mail address, phone number and
                the message you submit to us.
              </p>
              <h3>AUTOMATICALLY COLLECTED INFORMATION</h3>
              <p>
                We also collect information automatically when you use the
                Platform. Company collects information that is necessary for the
                correct functioning of the Services, for example your IP
                address, and information collected through the use of Cookies.
                For more information about the use of cookies, please read the{" "}
                <span
                  className="cursorPointer"
                  onClick={() => history.push("/cookiePreference")}
                >
                  <b>
                    <u>Cookies Policy</u>
                  </b>
                </span>{" "}
                of LiveStage.{" "}
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>
              3. WHAT ARE THE PURPOSES AND THE LEGAL BASES FOR THE PROCESSING OF
              PERSONAL DATA?
            </h3>
            <div style={{ padding: "10px" }}>
              <p>
                Depending on how you interact with us, we will process your
                personal data for the following purposes:
              </p>
              <h3>ACCOUNT CREATION – BOOKINGS </h3>
              <p>
                When you sign up, we collect all the necessary information to
                identify you, manage your registration and provide you with our
                services. We also process the information necessary for the
                execution of transactions. The legal basis for the processing is
                the performance of the contract (article 6 (1) (b) GDPR) to
                which you are party by accepting the Terms of Use upon
                registration. We also process data necessary for compliance with
                legal obligations to which we are subject (article 6 (1) (c)
                GDPR) (e.g., keeping transaction records for tax purposes).{" "}
              </p>
              <h3>MARKETING </h3>
              <p>
                We may also use contact details of registered Users for
                marketing purposes to inform them about our latest offers,
                services, features, etc. This processing may be regarded as
                carried out for our legitimate interest to promote our products
                and services (article 6 (1) (f) GDPR). You are always entitled
                to object to this processing since we provide an opt-out option
                (“unsubscribe”) within each marketing email you receive.
              </p>
              <h3>CONTACT FORM</h3>
              <p>
                When you use the contact form of the Platform, we process the
                requested data to fulfill your requests by answering your
                questions and providing information. In this case, the legal
                basis for the processing is the consent (article 6 (1) (a) GDPR)
                that you give by ticking the specific check-box before
                submitting your message. You do not have an obligation to
                consent. However, if you do not do so, you will not be able to
                send a message through our contact form.
              </p>
              <h3>INFORMATION COLLECTED DURING AND AFTER A LIVESTAGE EVENT</h3>
              <p>
                <b>(a) Live chat.</b> LiveStage offers a live chat service to
                enable interaction between Platform Users during Events. The
                legal basis for the processing is the performance of the
                contract (article 6 (1) (b) GDPR) to which you are party by
                accepting the Terms of Use upon registration.
              </p>
              <p>
                <b>(b) Tips.</b> Tipping Performers is considered part of the
                Services. The legal basis for the processing is the performance
                of the contract (article 6 (1) (b) GDPR) to which you are party
                by accepting the Terms of Use upon registration.
              </p>
              <p>
                <b>(c) Rating.</b> User ratings help us and Performers get
                feedback about Events. The legal basis for the processing is the
                performance of the contract (article 6 (1) (b) GDPR) to which
                you are party by accepting the Terms of Use upon registration.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>4. WHO DO WE SHARE YOUR PERSONAL DATA WITH?</h3>
            <div style={{ padding: "10px" }}>
              <p>
                Your data shall not be disclosed to any third party, apart from
                the following:
              </p>
              <p>
                • Vendors (service providers) who are required to have access to
                personal data to provide their services (e.g., IT services
                company, accounting office, host providers). All vendors are
                bound by non-disclosure and confidentiality agreements.{" "}
              </p>
              <p>
                • Authorized employees who have access to personal data only
                when this is necessary. All such employees are bound by
                non-disclosure and confidentiality agreements.
              </p>{" "}
              <p>
                • Third-party partners setting cookies. Some Cookies are put in
                place by third-party service providers. These partners have
                access to cookie-related information that may under certain
                circumstances constitute personal data (for more information
                about cookies, see our{" "}
                <span
                  className="cursorPointer"
                  onClick={() => history.push("/cookiePreference")}
                >
                  <b>
                    <u>Cookies Policy</u>
                  </b>
                </span>
                ).
              </p>
              <p>
                • Public authorities such as Public Prosecutor's Office, Cyprus
                Cybercrime Division, Office of the Commissioner for Data
                Protection (DPA), etc. when that disclosure is necessary to
                comply with a law or to prevent unlawful acts against us or
                Users of LiveStage.
              </p>
              <h3>INFORMATION PUBLICLY ACCESSIBLE</h3>
              <p>
                Performers should assume that anything they post on their
                profile (except for user passwords, bank details, etc.) may be
                publicly accessible on the Platform. Please be careful about
                posting sensitive information about yourself on your profile
                (e.g., religious or political beliefs, health information,
                sexual preferences, etc.) There is no requirement to provide
                this kind of information. When you choose to do so, you are
                explicitly consenting to the processing of such information and
                making it public and accessible by other users of the Platform.
              </p>
              <h3>SIGN IN OR LINK WITH THIRD PARTY PROVIDERS’ ACCOUNTS</h3>
              <p>
                Users can sign in or link with Third Party Providers’ accounts
                (e.g., Google, Facebook) In this case, we may share your
                information with these Third Party Providers. The Third Party
                Providers’ use of the shared information will be governed by the
                social media services’ privacy policy and your settings in your
                accounts to those providers. If you do not want your information
                shared in this way, do not connect your account on LiveStage
                with your accounts on Third Party Providers’ platforms. We
                disclose only the necessary information each time, and we ensure
                you that we have explicitly asked for the maximum safeness and
                confidentiality for your data from our partners and third
                parties and that our data processors may only collect and
                process your personal data in accordance with our instructions.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>5. DATA RETENTION</h3>
            <p style={{ padding: "10px" }}>
              We will retain your personal data for the period necessary to
              fulfill the purposes outlined in this Privacy Policy unless a
              longer retention period is required or permitted by law or if you
              request so. In particular, we will retain your registration and
              account data until for so long as your account is active. We will
              keep transaction data for so long as it is necessary to comply
              with the applicable Cypriot law (e.g., tax purposes). When you
              fill out the contact form, we will keep the requested data until
              the communication request is fulfilled.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>6. DATA SECURITY - INTERNATIONAL DATA TRANSFERS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                We have adopted measures of a technical and organizational
                nature required to guarantee the security of your data and
                prevent them from being lost, processed, or accessed illegally
              </p>
              <p>
                Our servers are located within the European Economic Area (EEA).
                For service efficiency purposes, some of our third-party
                providers such as advertising and marketing related partners,
                hold servers outside the EEA. We inform you that this data is
                transferred with adequate safeguards and is always kept safe.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>7. LINKS TO THIRD-PARTY WEBSITES</h3>
            <p style={{ padding: "10px" }}>
              The Platform may contain links, hyperlinks, banners or tabs
              leading to websites operated by third-parties other than us. We
              kindly recommend that you review the Privacy Notice of each
              external website and get informed about how each third-party uses
              your personal data. Company shall not be held responsible for
              processing activities carried out by those third-parties.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>8. WHAT ARE THE RIGHTS OF DATA SUBJECTS?</h3>
            <div style={{ padding: "10px" }}>
              <p>
                We want to ensure that you can exercise your rights enshrined
                under the applicable laws. To this end, for as long we retain
                your data you may exercise your rights free of charge. However,
                we may charge a reasonable fee in case of manifestly unfounded,
                disproportionate or repeated requests. In particular, you have
                the following rights:
              </p>
              <p>• to request access to the personal data that we hold;</p>
              <p>
                • to request rectification of inaccurate or incomplete data;
              </p>
              <p>
                • to request erasure of your personal data to the extent that
                they are no longer necessary for the purpose for which we need
                to keep processing them, as we have explained above, or when we
                are no longer legally permitted to process them;
              </p>
              <p>
                • to request that we limit the processing of your personal data,
                which entails that in certain cases you can request us to
                temporally suspend the processing of the data or that we keep
                them longer than necessary;
              </p>
              <p>
                • if you have given us your consent to process your data, you
                also have the right to withdraw such consent at any time. In the
                event that you withdraw your consent, this will not affect the
                legality of the processing carried out previously.
              </p>
              <p>
                • When we process your data based on your consent of for the
                purposes of a contract, you can also request portability of your
                personal data.
              </p>
              <p>
                • When the processing of your data is based on our legitimate
                interest, you are entitled to object to the processing.
              </p>
              <p>
                You can exercise the above rights by sending us an email at
                desk@livestage.stream.
              </p>
              <p>
                Finally, we inform you that you have the right to lodge a
                complaint with the Office of the Commissioner for Personal Data
                Protection in case you consider that your rights are in any way
                violated. For the Commissioner’s competence as well as for
                detailed information on how to lodge a complaint you may visit
                the website{" "}
                <a href="http://www.dataprotection.gov.cy/.">
                  <u>http://www.dataprotection.gov.cy/</u>
                </a>
                .
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>9. CHANGES TO THE PRIVACY POLICY</h3>
            <p style={{ padding: "10px" }}>
              We may amend the information contained in this Privacy Policy when
              we consider this appropriate having regard the applicable laws.
              The version of the Privacy Policy that applies to the processing
              of your data, is the one available at the Platform when you visit
              it. In case of an amendment, we will also change the “Last
              Updated” date at the beginning of this Privacy Policy.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>10. CONTACT US</h3>
            <p style={{ padding: "10px" }}>
              In case you need any clarification about the processing of your
              personal data, please do not hesitate to contact us at
              desk@livestage.stream.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, null)(Privacy);
