import React from "react";
import accounInfo from "../../assets/img/accoun-info.svg";

function ResetSuccess({history}) {
  return (
    <div className="grey-wrap reset-confirm-wrap">
      <div className="login-box">
        <img src={accounInfo} alt="img" />
        <div className="title">
          <h1>Woo hoo!</h1>
          <p className="mt-15">
            Your password has been reset successfully! Now login with your new
            password.
          </p>
        </div>
        <div className="btn-wrap">
          <button type="button" className="primary-button" onClick={() => history.push('/login')}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetSuccess;
