import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function Cookies({ role }) {
  useEffect(() => {
    document.title = "LiveStage | Cookie Policy";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a
                className="cursorPointer"
                href={
                  role == "User"
                    ? "/home/user"
                    : role == "Actor"
                      ? "/home/actor"
                      : "/"
                }
              >
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Cookie Policy</h1>
          </div>
          <div className="content miscellaneous">
            <p>
              <i>Last updated: February 2024</i>
            </p>
          </div>
          <div className="content miscellaneous">
            <p>
              The Platform www.LiveStage.stream and the LiveStage application
              (hereinafter both referred to as the “Platform”, “LiveStage” or
              “We”) use Cookies. We have drafted this Cookies Policy to provide
              users (“<b>You</b>”) with more information on cookies and the purposes
              for which we use them.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>1. What are cookies?</h3>
            <p style={{ padding: "10px" }}>
              Cookies are little text files which are sent to the visitors’
              browsers and are stored on their devices when they visit a website
              or application. The term “Cookies” also refers to other similar
              technologies used to install and/or collect information on or from
              the visitors’ terminal equipment such as pixels.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>2. What are the categories of cookies?</h3>
            <div style={{ padding: "10px" }}>
              <p>
                In general, there are three different ways to classify cookies:
                their provenance, how long they endure, and what purpose they
                serve.
              </p>
              <p>
                Depending on their provenance cookies are classified as
                “First-party Cookies” and “Third-party cookies”. First party
                Cookies are set by the website or app you’re visiting.
                Third-party Cookies are set by an entity that is distinct from
                the one that operates the website/app visited by the user. This
                happens when a website/app uses external services placing their
                own Cookies.
              </p>
              <p>
                Depending on their endurance cookies are classified as “Session
                Cookies” and “Persistent Cookies”. A Session Cookie is
                automatically deleted when the user closes his/her browser. A
                “persistent cookie” remains stored in the user’s terminal device
                until it reaches a defined expiration date.
              </p>
              <p>
                Finally, depending on their purpose, Cookies can be classified
                as “Strictly necessary Cookies”, “Preferences Cookies” (also
                known as “Functionality Cookies”), “Performance cookies” (also
                known as “Statistics Cookies”), and “Marketing Cookies” (also
                known as “Targeting Cookies”). The function of each category is
                explained in the section below.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>
              3. Why are cookies important? What types of cookies do we use?
            </h3>
            <div style={{ padding: "10px" }}>
              <p>
                In general, the main goal of Cookies is to make your browsing
                experience easier and more efficient.
              </p>
              <p>
                “Strictly Necessary Cookies” are required for the proper
                functioning of the Platform. These cookies ensure basic
                functionalities and security features of the Platform. They are
                used to respond to actions made by you when requesting services,
                such as filling out forms. If you deactivate these cookies, the
                Platform services will not work. Therefore, these cookies are
                always active, and, according to the applicable law, Website
                operators do not need your consent to use them.
              </p>
              <p>
                “Preferences Cookies” enhance the functionality of the Platform.
                They allow Platform to memorize information about your choices
                and preferences on the Platform, such as your username or the
                selected language. In this way your experience will be improved
                the next time you visit the Platform. They do not track browsing
                activity across other websites. If you block these cookies, some
                of the Platform’s services will not function properly.
              </p>
              <p>
                “Performance Cookies” help Platform count visits and traffic
                sources so they can improve their content, presentation, and
                performance according to visitors’ preferences. They help
                Platform to know which pages are the most and least popular and
                see how visitors move around the Platform. If you block these
                Cookies, the Platform will not know when you visited it.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>4. Cookies and personal data processing</h3>
            <p style={{ padding: "10px" }}>
              The use of Cookies may be related to personal data processing
              (e.g., behavioral data) Therefore, we recommend you consult our
              Privacy Policy, available on the Platform, to be informed about
              how we process users’ personal data.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>5. International Data Transfers</h3>
            <p style={{ padding: "10px" }}>
              Some Cookies are put in place by third-party partners that provide
              their services. For service efficiency purposes, some of these
              providers’ servers may be located outside the European Economic
              Area (EEA). Therefore, some cookie-related information may be
              transferred outside the EEA. We inform you that this data is
              transferred with adequate safeguards and is always kept safe.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>6. How to manage Cookies? Can I change my settings? </h3>
            <p style={{ padding: "10px" }}>
              In the “Cookie preferences” section, available on the Platform,
              you can enable or disable the use of Cookies that are not strictly
              necessary for the Platform functioning. You can withdraw your
              consent to the use of Cookies and change the settings of Cookies
              at any time. Alternatively, you can enable, disable, or even
              completely delete Cookies through the setup options in your
              browser.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>7. Contact Us</h3>
            <p style={{ padding: "10px" }}>
              For more information on the use of Cookies, please do not hesitate
              to contact us at desk@livestage.stream
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, null)(Cookies);
