import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordion: {
    background: "#0e0e0e",
    color: "#fff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    flexBasis: "100%",
    flexShrink: 0,
    fontWeight: "bold"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: "#fff",
  },
  icon: {
    color: "#fff",
  },
}));

function Faq({ role }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    document.title = "LiveStage | FAQ";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a
                className="cursorPointer"
                href={
                  role == "User"
                    ? "/home/user"
                    : role == "Actor"
                    ? "/home/actor"
                    : "/"
                }
              >
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>FAQ</h1>
          </div>
          <div className="panels">
            <div>
              <h1>About LiveStage</h1>
            </div>
            <div className={classes.root}>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>About</Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The idea of LiveStage was born inside a tiny room in
                    Barcelona on March 2020 by Petros Tryfonos and brought to
                    life in October 2021. LiveStage is a live streaming and
                    ticketing platform which hosts live & interactive
                    performances from artists. Fans and artists get to connect
                    through an exclusive digital live experience which promotes
                    interactivity.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>
                    What is LiveStage?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    LiveStage is a live streaming and ticketing platform which
                    hosts live & interactive performances from artists. Fans and
                    artists get to connect through an exclusive digital live
                    experience which promotes interactivity and allows fans to
                    actively support their favorite artists.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography className={classes.heading}>
                    Who is LiveStage for?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    LiveStage is for artists/performers, fans, music venues, and
                    for anyone who loves performing arts!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>
                    How does it work?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Artists/Performers create an event, choose the date and
                    time, set the price, share the event link to their fans to
                    sell tickets, and when it is time, they start the live
                    event. Simplicity is priceless! To join an event, fans need
                    to purchase their tickets prior or during an event. Fans can
                    support their favorite artists further using the tipping
                    feature that is shown on the right of their screen. Fans can
                    view any live event from their mobile or tablet device,
                    laptop, or Android TV (coming soon).
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="panels">
            <div>
              <h1>Account Types</h1>
            </div>
            <div className={classes.root}>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography className={classes.heading}>
                    What is a User Account?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The account you should create if you want to watch live
                    shows from your favorite artists.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography className={classes.heading}>
                    What is a Corporate Account?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The account you should create if you are a music venue/or
                    business and would like to stream private live events from
                    any artist. To create a corporate account, you must complete
                    your company details upon registration. When artists are
                    creating corporate events, they have the option to sell the
                    event to only specific users by entering their email
                    addresses.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography className={classes.heading}>
                    What is a Performer/Artist Account?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The account you should create if you to become a performer
                    on LiveStage. Artists have the potential to earn an income
                    online. LiveStage is free for all artists to use. There is
                    no cost to register as a performer, to create an event or to
                    stream your live event to your fans. Live Stage is dedicated
                    in providing artists with the greatest amount of the sales
                    proceeds (ticket sales + tips). Performers/Artists earn 100%
                    of the sales proceeds from their events as they have the
                    option to specify how much they would like to earn per
                    ticket sold. Using a formula, we calculate the final ticket
                    price that users will pay which covers our streaming,
                    service, and maintenance costs. This allows us to: serve the
                    arts by designing, developing, updating, enhancing, and
                    maintaining the platform – and – to ensure smooth operations
                    by administration.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="panels">
            <div>
              <h1>Viewing Events on LiveStage – End users & Corporate Users</h1>
            </div>
            <div className={classes.root}>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel8bh-content"
                  id="panel8bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I purchase a ticket for an event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Once you are logged in, you can purchase a ticket through
                    the ‘Home’ section or through the ‘All Events’ section of
                    our website or application. All you have to do is to click
                    on the event and then on ‘Book Now’. You will be redirected
                    to the Event Booking page where you will be able to purchase
                    tickets for your or your friends. In case you are buying
                    ticket for someone else you will also need to add their
                    email address.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel9bh-content"
                  id="panel9bh-header"
                >
                  <Typography className={classes.heading}>
                    What are the payment methods to purchase a ticket for an
                    event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can purchase a ticket using your credit/debit card.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel10bh-content"
                  id="panel10bh-header"
                >
                  <Typography className={classes.heading}>
                    Do event times display in my local time?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes – based on your country and location, event times
                    display in your local time zone.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel11"}
                onChange={handleChange("panel11")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel11bh-content"
                  id="panel11bh-header"
                >
                  <Typography className={classes.heading}>
                    What happens if an event I booked is rescheduled from the
                    artist?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If any artist decides to cancel their event, they need to
                    fill out a form specifying the reason of cancelling and they
                    also have the option to leave a note to their fans who
                    purchased tickets for the event. This information will be
                    communicated directly to you by email, and you will receive
                    a voucher code (full amount) for any other upcoming events
                    on LiveStage.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel12"}
                onChange={handleChange("panel12")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel12bh-content"
                  id="panel12bh-header"
                >
                  <Typography className={classes.heading}>
                    What happens if I purchased a ticket, and the event is not
                    started on time from the artist?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Artists can start their event 5 minutes prior the event
                    start time, and they have a window of 10 minutes after the
                    event start time to start streaming their show. For example,
                    if the event is at 22:00, an artist may start the event from
                    21:55 until 22:10. If the event is not started after 22:10
                    then the event will be considered as a cancelled event and
                    all ticket holders will automatically receive a voucher code
                    (full amount) which can be used for any other upcoming
                    events on LiveStage.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel13"}
                onChange={handleChange("panel13")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel13bh-content"
                  id="panel13bh-header"
                >
                  <Typography className={classes.heading}>
                    Where can I find my vouchers and how can I redeem them?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can find your vouchers under ‘My Account’  ‘Redeem your
                    Vouchers’. You can redeem any of your vouchers when trying
                    to book a ticket for any upcoming event on LiveStage. On the
                    event booking page just enter your voucher code on the field
                    ‘enter voucher code’ and click apply. The amount of your
                    voucher will be applied automatically towards the ticket
                    amount you are trying to purchase.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel14"}
                onChange={handleChange("panel14")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel14bh-content"
                  id="panel14bh-header"
                >
                  <Typography className={classes.heading}>
                    Where can I see my upcoming/past bookings?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can find your upcoming or past bookings by clicking on
                    ‘My Bookings’ section of your account.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel15"}
                onChange={handleChange("panel15")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel15bh-content"
                  id="panel15bh-header"
                >
                  <Typography className={classes.heading}>
                    Can I request a refund for a ticket I booked?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    As per the terms of use of LiveStage, all tickets are
                    non-refundable.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel16"}
                onChange={handleChange("panel16")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel16bh-content"
                  id="panel16bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I view an event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If you have purchased a ticket prior to the event, you will
                    receive a push notification when an artist starts their
                    event. To view they event you can click on the push
                    notification which will redirect you to the event page and
                    then click on ‘Watch Now’. Alternatively, you can click on
                    ‘My bookings’  click on the event from your Upcoming Events
                     and then click ‘Watch Now’.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel17"}
                onChange={handleChange("panel17")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel17bh-content"
                  id="panel17bh-header"
                >
                  <Typography className={classes.heading}>
                    What devices can I view events on?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    LiveStage offers a website application
                    (www.livestage.stream), a mobile application for Android and
                    iOS devices, and a TV application for Android TVs.
                    Therefore, you can view all events on your computer, your
                    mobile device, or your TV.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel18"}
                onChange={handleChange("panel18")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel18bh-content"
                  id="panel18bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I communicate with an Artist/Performer during a show?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We have integrated a chat feature in all our events from
                    wherever you are streaming any event (computer/mobile
                    device/TV). You can communicate with your favorite artists
                    by typing a message, sending a GIF, and sending a picture.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel19"}
                onChange={handleChange("panel19")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <Typography className={classes.heading}>
                    How to tip an artist during a live event?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    On the right of your screen, click on ‘Add Tip’, select your
                    tip and voila! Your name and amount of tip will be displayed
                    in the chat section of the event. You can select from preset
                    amounts or enter a custom amount to tip.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel20"}
                onChange={handleChange("panel20")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <Typography className={classes.heading}>
                    I see that another use is misbehaving in the comments
                    section and is abusive to the artist or other users – what
                    can I do?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    On the website – click on the 3 dots next to the comment you
                    want to report and then click Report. If you are streaming
                    from the mobile app – long click on the comment you want to
                    report for 2-3 seconds and then click Report. If the user is
                    reported too many times, they will not be able to comment
                    again during the live event.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="panels">
            <div>
              <h1>Performing on LiveStage</h1>
            </div>
            <div className={classes.root}>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel21"}
                onChange={handleChange("panel21")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel21bh-content"
                  id="panel21bh-header"
                >
                  <Typography className={classes.heading}>
                    How can I become a performer/artist on LiveStage?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    In the Login page of LiveStage there is a section saying
                    ‘Interested to become a performer on LiveStage? Please click
                    here’. Once you click the link, you will be redirected to
                    fill out the form that will allow you to become an artist on
                    LiveStage. After the form is completed, you will be under
                    approval from the admin as we need to ensure that all
                    details are correct. We will update you by email within a
                    few hours If your account is approved or rejected. If you
                    get approved, you can start streaming your live shows and
                    earn an income online straight away!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel22"}
                onChange={handleChange("panel22")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel22bh-content"
                  id="panel22bh-header"
                >
                  <Typography className={classes.heading}>
                    Is there a cost to perform on LiveStage?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    LiveStage is free for all artists to use. There is no cost
                    to register as a performer, to create an event or to stream
                    your live event to your fans. Live Stage is dedicated in
                    providing artists with the greatest amount of the sales
                    proceeds (ticket sales + tips). Performers/Artists earn 100%
                    of the sales proceeds from their events as they have the
                    option to specify how much they would like to earn per
                    ticket sold. Using a formula, we calculate the final ticket
                    price that users will pay which covers our streaming,
                    service, and maintenance costs. This allows us to: serve the
                    arts by designing, developing, updating, enhancing, and
                    maintaining the platform – and – to ensure smooth operations
                    by administration.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel23"}
                onChange={handleChange("panel23")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel23bh-content"
                  id="panel23bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I create an event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    On the website – all you have to do is click ‘Create Events’
                    from the top menu in your account. On the mobile app – from
                    the ‘Events Dashboard’ just click ‘Create Events’.
                    Alternatively, click the menu icon on the top left, and then
                    on ‘Create an Event’.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel24"}
                onChange={handleChange("panel24")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel24bh-content"
                  id="panel24bh-header"
                >
                  <Typography className={classes.heading}>
                    What is a Public Event?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A public event is created when you want to stream an event
                    to all your fans. Your fans can join your event if they have
                    purchased a ticket prior or during your live show. Public
                    Events can have Limited or Unlimited Tickets as well as
                    Early Bird tickets at a discount. You choose the date, time,
                    and duration of your event.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel25"}
                onChange={handleChange("panel25")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel25bh-content"
                  id="panel25bh-header"
                >
                  <Typography className={classes.heading}>
                    What is a Corporate Event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A corporate event is created when you want to stream your
                    event privately to a business. When creating a corporate
                    event, you can specify the email address of the individual
                    who wishes to stream your event (bar, club, music hall,
                    theatre, etc.). LiveStage gives you the opportunity to sell
                    your event to several people at different prices as well. If
                    an email address is not specified upon creating your
                    corporate event, your event will be showed only to corporate
                    users. This means that any corporate user will be able to
                    purchase your private event and then stream it. Please note
                    that to host corporate events you must complete your company
                    details in section ‘My Account’ as this is a
                    business-to-business relationship. In addition, corporate
                    users must also complete their company details upon
                    registration.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel26"}
                onChange={handleChange("panel26")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel26bh-content"
                  id="panel26bh-header"
                >
                  <Typography className={classes.heading}>
                    How long can my event be?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Your event can be from 30 minutes up to 4 hours. You choose
                    the duration of your event when creating an event.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel27"}
                onChange={handleChange("panel27")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel27bh-content"
                  id="panel27bh-header"
                >
                  <Typography className={classes.heading}>
                    Will my event be stopped if the duration is over?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If your event is for 3 hours, then your event will be
                    automatically stopped after the 3 hours have passed. Do not
                    worry though, as we have placed a timer on your streaming
                    screen, so you are aware of the time left and you get to say
                    a goodbye and a big thank you to your fans for attending
                    your show before the show is stopped.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel28"}
                onChange={handleChange("panel28")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel28bh-content"
                  id="panel28bh-header"
                >
                  <Typography className={classes.heading}>
                    Can I stop my event before the duration is over?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, you can – on the top right corner of your screen click
                    on ‘End’ button. All your fans will be notified that the
                    event is over, and they will be asked to rate their
                    experience with you on LiveStage.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel29"}
                onChange={handleChange("panel29")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel29bh-content"
                  id="panel29bh-header"
                >
                  <Typography className={classes.heading}>
                    My event is over – how do I get paid?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    When you registered yourself as a performer/artist on
                    LiveStage, we asked for your bank account details. When your
                    event is over, you will be sent automatically an email with
                    the breakdown of your earnings. You will also be able to see
                    your earnings in the ‘Home’ section of your account. Payment
                    will be made through bank transfer, and it will take between
                    3 to 10 days working days for the payment to reach your
                    account. Please note that to get paid from LiveStage your
                    event must be completed (not cancelled).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel30"}
                onChange={handleChange("panel30")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel30bh-content"
                  id="panel30bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I reschedule my event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Click on your event and then click on ‘Change Date / Cancel
                    Event’ button. After that, click on ‘Reschedule’. You can
                    reschedule your events only on a later date and time than
                    the preplanned date and time, and all ticket holders will be
                    notified by email and their ticket remains active. Please
                    note that you can reschedule your event up to 3 times.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel31"}
                onChange={handleChange("panel31")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel31bh-content"
                  id="panel31bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I cancel my event?
                  </Typography>
                  {/* <Typography className={classes.secondaryHeading}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Click on your event and then click on ‘Change Date / Cancel
                    Event’ button. After that, click on ‘Cancel Event’. We will
                    then ask you to provide the reason for cancelling the event,
                    and we ask you to leave a note. The information for
                    cancelling the event will be sent to all your fans who
                    purchased tickets to your live event, and they will receive
                    a full amount voucher that they can use for any other
                    upcoming show on LiveStage. Please note that to get paid
                    from LiveStage your event must be completed.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel32"}
                onChange={handleChange("panel32")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel32bh-content"
                  id="panel32bh-header"
                >
                  <Typography className={classes.heading}>
                    I did not start my event on time – what happens?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can start your event 5 minutes prior the event start
                    time, and you have a window of 10 minutes after the event
                    start time to start streaming the show. For example, if the
                    event is at 22:00, you may start the event from 21:55 until
                    22:10. If the event is not started after 22:10 then the
                    event will be considered as a cancelled event and all ticket
                    holders will automatically receive a voucher code (full
                    amount) which can be used for any other upcoming events on
                    LiveStage.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel33"}
                onChange={handleChange("panel33")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel33bh-content"
                  id="panel33bh-header"
                >
                  <Typography className={classes.heading}>
                    How are tips from my fans calculated?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Your fans can tip during your live show. You earn 75% of the
                    total tips gathered during a live event.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "panel34"}
                onChange={handleChange("panel34")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.icon} />}
                  aria-controls="panel34bh-content"
                  id="panel34bh-header"
                >
                  <Typography className={classes.heading}>
                    I see that someone is misbehaving in the comments section of
                    my live event – how do I report them?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    On the website – click on the 3 dots next to the comment you
                    want to report and then click Report. If you are streaming
                    from the mobile app – long click on the comment you want to
                    report for 2-3 seconds and then click Report. As you are the
                    performer and this is your online stage, the user will not
                    be able to comment again during your event.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, null)(Faq);
