const initialState = {
  notiEventID: "",
  notiBookingID: "",
};

const NOTIF_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_EVENTID": {
      return {
        notiEventID: payload.notiEventID,
        notiBookingID: payload.notiBookingID,
      };
    }
    case "EMPTY_EVENTID": {
      return initialState;
    }

    default:
      return state;
  }
};

export default NOTIF_REDUCER;
