const initialState = {
    eventID: "",
    messageArray : [],
    likesCount : 0,
    isLiked: false
}

const CHAT_FILE = (state = initialState, { type, payload }) => {
    switch (type) {
        case "CHAT_MESSAGES": {
            return {
                ...state, 
                messageArray: [...state.messageArray, ...payload]
            };
        }
        case "LIKES_COUNT": {
            return {
                ...state,
                likesCount: state.likesCount + 1
            };
        }
        case "SET_EVENTID": {
            return {
                ...state,
                eventID: payload
            };
        }
        case "CHANGE_LIKED" :{
            return {
                ...state,
                isLiked: true
            };
        }
        case "EMPTY_CHAT": {
            return {
                ...state,
                messageArray : [],
                likesCount: 0,
            }
        }
        default:
            return state;
    }
}

export default CHAT_FILE;