import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function HelpCentre({ role }) {

  useEffect(() => {
    document.title = "LiveStage | Help Centre"

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[]);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
            <a className="cursorPointer" href={role == "User" ? "/home/user" : role == "Actor" ? "/home/actor" : "/"}>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Help Centre</h1>
          </div>
          <div className="content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              mattis augue vel leo malesuada euismod. Nam faucibus venenatis
              ipsum at rhoncus. Sed aliquam commodo ligula, vitae venenatis
              felis tristique in. Nulla id varius lacus. Donec sit amet urna in
              metus malesuada suscipit at gravida ipsum. Etiam malesuada justo
              ut luctus sodales. Fusce id vehicula dolor. Integer eget magna
              purus. Praesent eget mauris metus. Duis hendrerit sem eget
              molestie pellentesque. Integer venenatis aliquet mattis. Mauris
              est diam, vestibulum id semper suscipit, dignissim quis magna. Sed
              at purus elementum, egestas libero placerat, pretium enim.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role
  }
}

export default connect(mapStateToProps, null)(HelpCentre);
