import React from "react";
import AccounInfo from "../../../../assets/img/accoun-info.svg";
// import { useHistory } from "react-router";
import { getEventLocalDate } from "../../../../helpers/constants";

function BookingSuccess({ eventDate, eventTime, eventTitle, eventTZone, setSelectedTab }) {
  // console.log({ eventDate, eventTime });
  // const history = useHistory();

  return (
    <div>
      <section className="user-section">
        <div className="flex-container">
          <div className="grey-wrap bank-wrap book-msg-wrap">
            <div className="login-box box-book-end">
              <img src={AccounInfo} alt="img" />
              <div className="title">
                <h1>Thank you for your order.</h1>
                <p className="mt-15">
                  You have successfully purchased your ticket for {eventTitle}{" "}
                  happening at{" "}
                  {(getEventLocalDate(eventDate, eventTime, eventTZone)).split(",")[0]} at{" "}
                  {(getEventLocalDate(eventDate, eventTime, eventTZone)).split(",")[1]}. We look forward to see you there. Enjoy the show!
                </p>
              </div>
            </div>
            <div className="btn-wrap text-center">
              <button
                type="button"
                className="primary-button"
                onClick={() =>
                  // history.push("/home/user")
                  setSelectedTab(1)
                }
              >
                Great! Thanks
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BookingSuccess;
