import React, { useEffect, useState } from "react";
import iconCal from "../../../../assets/img/icon-cal.svg";
import iconWatch from "../../../../assets/img/icon-watch.svg";
import iconCard from "../../../../assets/img/icon-card.svg";
import dpPhoto from "../../../../assets/img/img_avatar.png";
import IconLike from "../../../../assets/img/icon-like.svg";
import { AddViewer, GetEventBooking } from "../../../../redux/actions/events";
import { Notify } from "../../../../helpers/notifications";
import { useHistory } from "react-router";
import { getEventLocalDate } from "../../../../helpers/constants";
import Logo from "../../../loader/Loader";
import { connect } from "react-redux";
import Store from "../../../../redux/store/store";
// import iconMute from "../../../../assets/img/icon-mute.png";
// import iconUnMute from "../../../../assets/img/icon-unmute.png";

function BookingDetails({
  setSelectedTab,
  eventID,
  bookingID,
  notifBID,
  notifEID,
}) {
  // let notiEventID = localStorage.getItem("notiEventID");
  // let notiBookingID = localStorage.getItem("notiBookingID");
  let notiEventID = notifEID;
  let notiBookingID = notifBID;
  const [eventData, setEventData] = useState({});
  const [videoMute, setVideoMute] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const history = useHistory();
  console.log("Booking Details", history);

  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Booking Details";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setShowLoader(true);

    let payload = {
      eventId: notiEventID ? notiEventID : eventID,
      bookingId: notiBookingID ? notiBookingID : bookingID,
    };

    console.log(payload);

    GetEventBooking(payload)
      .then((response) => {
        console.log(response.data);
        setEventData(response.data.data);
        setShowLoader(false);
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.data.eventImage,
            videoStatus: response.data.data.videoStatus,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  }, [notiEventID]);

  // Handle Booking
  const handleBookEvent = () => {
    console.log("event booking");
    // setSelectedTab(8);
  };

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  // useEffect(() => {
  //   // if (history.location.pathname !== "/home/user/bookingDetails") {
  //     localStorage.removeItem("notiEventID");
  //   // }
  // }, [history.location.pathname]);

  const handleViewer = async () => {
    // let match = document.cookie.match(
    //   new RegExp("(^| )" + "petros-unique-id-view" + "=([^;]+)")
    // )
    //   ? document.cookie.match(
    //       new RegExp("(^| )" + "petros-unique-id-view" + "=([^;]+)")
    //     )[2]
    //   : "";
    // if (match) {
    //   console.log(
    //     "COOKIE",
    //     document.cookie.match(
    //       new RegExp("(^| )" + "petros-unique-id-view" + "=([^;]+)")
    //     )[2]
    //   );
    // }

    let match = localStorage.getItem("petros-unique-id-view");

    let payload = {
      fcmToken: localStorage.getItem("fcmToken"),
      // fcmToken: localStorage.getItem("uniqueTokenforViewing"),
      uniqueId: match,
      eventId: eventData._id,
    };

    try {
      let response = await AddViewer(payload);

      if (response.status === 200) {
        console.log("===============>", response.data);
        console.log(response.data.data.fcmToken, response.data.data.uniqueId);
        localStorage.setItem(
          "uniqueTokenforViewing",
          `${response.data.data.fcmToken}`
        );
        // document.cookie=`petros-unique-id-view=${String(response.data.data.uniqueId)}`;
        localStorage.setItem(
          "petros-unique-id-view",
          response.data.data.uniqueId
        );
        history.push(`/streamEvent`, {
          eventId: eventData._id, shareEventURL: eventData.share_url
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  return (
    <div>
      <section
        className="events-section enduser-section"
        style={{
          paddingTop: "0px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="flex-container">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(3);
                    // localStorage.removeItem("notiEventID");
                    // localStorage.removeItem("notiBookingID");
                    dispatch({ type: "EMPTY_EVENTID" });
                  }}
                >
                  My Bookings
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              {/* <li>
                <a className="cursorPointer" onClick={() => setSelectedTab(2)}>
                  All Events
                </a>{" "}
                <span>&#62;</span>{" "}
              </li> */}
              <li>Booking Details</li>
            </ul>
          </div>

          {showLoader ? (
            <div>
              <Logo />
            </div>
          ) : (
            <div className="event-details">
              <h1>{eventData.eventTitle}</h1>
              <div className="content">
                <div className="date-time">
                  <div className="flex-row">
                    <div>
                      <img src={iconCal} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[0]
                      }
                    </div>
                    <div>
                      <img src={iconWatch} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[1]
                      }
                    </div>
                  </div>

                  <div className="flex-row">
                    <div>BOOKING ID: #{eventData.randomBookingId}</div>
                  </div>
                  <div className="flex-row">
                    <div>
                      <img src={iconCard} alt="User" /> €{" "}
                      {parseFloat(eventData.bookingPrice).toFixed(2)}
                    </div>
                  </div>

                  <div className="flex-row">
                    <div>Quantity: {eventData?.totalTickets}</div>
                  </div>
                  <div className="flex-row">
                    Category : {eventData.eventCategory}
                  </div>
                  {eventData.isVoucherApplied && (
                    <div className="flex-row">
                      Voucher Amount: € {eventData.voucherAmt}
                    </div>
                  )}
                  <div className="flex-row">
                    Duration:{" "}
                    {parseInt(parseInt(eventData?.eventDuration) / 60) >= 1 &&
                      `${parseInt(
                        parseInt(eventData?.eventDuration) / 60
                      )} hours`}{" "}
                    {parseInt(parseInt(eventData?.eventDuration) % 60) > 0 &&
                      `${parseInt(
                        parseInt(eventData?.eventDuration) % 60
                      )} minutes`}
                  </div>
                </div>
                <div className="description-scroll">
                  <p style={{ whiteSpace: "pre-line" }}>
                    {eventData?.description}
                  </p>
                </div>

                {eventData.isSessionStart && !eventData.isCancelled && (
                  <button
                    className="primary-button"
                    type="button"
                    onClick={() => {
                      handleViewer();
                    }}
                  >
                    Watch Now
                  </button>
                )}
                {eventData.isCancelled && (
                  <span style={{ color: "#e50914", fontSize: "30px" }}>
                    Event Cancelled
                  </span>
                )}
              </div>

              <div className="banner-wrap">
                <div
                  className="content banner-content-box"
                  style={{ width: "100%" }}
                >
                  <div className="enduser-artist" style={{ marginTop: "0px" }}>
                    <h4>Artist</h4>
                    <div className="row-content">
                      <div
                        // className="eventImage"
                        style={{
                          // paddingTop: "0px",
                          height: "200px",
                          width: "65%",
                          backgroundImage: `url(${
                            eventData?.userId?.profilePhoto
                              ? eventData.userId?.profilePhoto
                              : dpPhoto
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div className="content">
                        <h5>
                          {eventData?.userId?.firstName}{" "}
                          {eventData?.userId?.lastName}
                        </h5>
                        <h6>{eventData?.userId?.category}</h6>
                        {eventData?.userId?.rateCount > 0 && (
                          <div className="rating">
                            <span
                              className="like-con-book"
                              style={{ marginBottom: "0px", cursor: "auto" }}
                            >
                              <img src={IconLike} alt="" />
                              {eventData.artistRating} %
                            </span>
                          </div>
                        )}
                        <div className="description-scroll">
                          <p
                            className="p-box"
                            style={{
                              maxWidth: "500px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {eventData?.userId?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="banner-wrap">
                <div className="content banner-content-box">
                  <h1>{eventData?.eventTitle}</h1>
                  <div className="content">
                    <div className="date-time">
                      <div className="flex-row">
                        <div>
                          <img src={iconCal} alt="User" />{" "}
                          {
                            getEventLocalDate(
                              eventData.eventDate,
                              eventData.eventTime,
                              eventData.setTimeZone
                            ).split(",")[0]
                          }
                        </div>
                        <div>
                          <img src={iconWatch} alt="User" />{" "}
                          {
                            getEventLocalDate(
                              eventData.eventDate,
                              eventData.eventTime,
                              eventData.setTimeZone
                            ).split(",")[1]
                          }
                        </div>
                      </div>
                      <div className="flex-row">
                        <div>BOOKING ID: #{eventData.randomBookingId}</div>
                      </div>
                      <div className="flex-row">
                        <div>
                          <img src={iconCard} alt="User" /> €{" "}
                          {parseFloat(eventData.bookingPrice).toFixed(2)}
                        </div>
                      </div>

                      <div className="flex-row">
                        <div>Quantity: {eventData?.totalTickets}</div>
                      </div>
                      <div className="flex-row">
                        Category : {eventData.eventCategory}
                      </div>
                      {eventData.isVoucherApplied && (
                        <div className="flex-row">
                          Voucher Amount: {eventData.voucherAmt}
                        </div>
                      )}
                      <div className="flex-row">
                        Duration:{" "}
                        {parseInt(parseInt(eventData?.eventDuration) / 60) >=
                          1 &&
                          `${parseInt(
                            parseInt(eventData?.eventDuration) / 60
                          )} hours`}{" "}
                        {parseInt(parseInt(eventData?.eventDuration) % 60) >
                          0 &&
                          `${parseInt(
                            parseInt(eventData?.eventDuration) % 60
                          )} minutes`}
                      </div>
                    </div>
                    <div className="description-scroll">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {eventData?.description}
                      </p>
                    </div>
                  </div>
                  {eventData.isSessionStart && !eventData.isCancelled && (
                    <button
                      className="primary-button"
                      type="button"
                      onClick={() => {
                        history.push(`/streamEvent`, {
                          eventId: eventData._id,
                        });
                      }}
                    >
                      Watch Now
                    </button>
                  )}
                  {eventData.isCancelled && (
                    <span style={{ color: "#e50914", fontSize: "30px" }}>
                      Event Cancelled
                    </span>
                  )}
                </div>
                <div className="outer-wrappper-1">
                  <div className="preview-img-wrap">
                    <div
                      className="preview-con"
                      style={{ position: "relative" }}
                    >
                      {eventData.videoStatus ? (
                        <>
                          <div
                            className="cursorPointer video-mute-div"
                            onClick={() => handleVideoMute()}
                          >
                            {videoMute ? (
                              <img src={iconMute} />
                            ) : (
                              <img src={iconUnMute} />
                            )}
                          </div>
                          <video
                            // minHeight="480"
                            height="480"
                            width="100%"
                            // controls
                            autoPlay={true}
                            loop={true}
                            muted={videoMute}
                          >
                            <source src={eventData?.eventImage} />
                          </video>
                        </>
                      ) : (
                        <div
                          className="eventImage"
                          style={{
                            // paddingTop: "0px",
                            minHeight: "480px",
                            //   width: "100%",
                            backgroundImage: `url(${
                              eventData?.videoStatus
                                ? eventData?.thumbnailImage
                                : eventData?.eventImage
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-wrap">
                <div
                  className="content banner-content-box"
                  style={{ width: "100%" }}
                >
                  <div className="enduser-artist" style={{ marginTop: "0px" }}>
                    <h4>Artist</h4>
                    <div className="row-content">
                      <div
                        // className="eventImage"
                        style={{
                          // paddingTop: "0px",
                          height: "200px",
                          width: "65%",
                          backgroundImage: `url(${
                            eventData?.userId?.profilePhoto
                              ? eventData.userId?.profilePhoto
                              : dpPhoto
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div className="content">
                        <h5>
                          {eventData?.userId?.firstName}{" "}
                          {eventData?.userId?.lastName}
                        </h5>
                        <h6>{eventData?.userId?.category}</h6>
                        <div className="rating">
                          <span
                            className="like-con-book"
                            style={{ marginBottom: "0px", cursor: "auto" }}
                          >
                            <img src={IconLike} alt="" />
                            {eventData.artistRating} %
                          </span>
                        </div>
                        <div className="description-scroll">
                          <p
                            className="p-box"
                            style={{
                              maxWidth: "500px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {eventData?.userId?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ NOTIF_REDUCER }) => {
  return {
    notifEID: NOTIF_REDUCER.notiEventID,
    notifBID: NOTIF_REDUCER.notiBookingID,
  };
};

export default connect(mapStateToProps, {})(BookingDetails);
