const initialState = {
  myEventsData: {},
  // eventList: {},
  isUserDataExists: false
};

const EVENTS_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_MYEVENTS": {
      return { ...state, myEventsData: payload };
    }

    // case "EVENT_LIST": {
    //   return { ...state, eventList: payload };
    // }

    case "UPDATE_USERDATA": {
      return {...state, isUserDataExists: payload}
    }

    case "EMPTY_DATA": {
      return initialState;
    }

    default:
      return state;
  }
};

export default EVENTS_REDUCER;
