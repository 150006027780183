import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import forgotBGImage from "../../assets/img/forgot-screen-bg-img.svg";
import emailSVG from "../../assets/img/email.svg";
import { UpdateUserInfo } from "../../redux/actions/user";
import { connect } from "react-redux";
import { Notify } from "../../helpers/notifications";
import { Validation } from "../../helpers/validations";

function ChangeEmail({ history, role }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    document.title = "LiveStage | Change Email";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])

  // Submit Email for Change Email
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const payload = { email: email.trim() };

      try {
        const response = await UpdateUserInfo(payload);
        // console.log(response);

        if (response && response.status === 200) {
          Notify(
            "success",
            "A verification link has been sent to the new email."
          );
          if (role === "User" || role === "Corporate") {
            history.push("/home/user");
          } else if (role === "Actor") {
            history.push("/home/actor");
          }
        }
      } catch (error) {
        // console.log(error.response.data.message);
        setError(error.response.data.message);
        Notify("success", `Error: ${error.response.data.message}`);
      }
    }
  };

  // Validator function
  const handleValidation = () => {
    if (Validation.empty(email)) {
      setError("Enter your Email");
      return false;
    } else if (!Validation.email(email)) {
      setError("Please enter the email in a valid format");
      return false;
    } else if (!Validation.emailLength(email)) {
      setError("Max 100 characters allowed");
      return false;
    }
    return true;
  };

  return (
    <div id="app" className="page-login">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a className="cursorPointer" href={(role === "User" || role === "Corporate") ? "/home/user" : "/home/actor"}>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="user-section">
        <div className="flex-container">
          <div className="grey-wrap login-wrap">
            <div className="flex-row flex-centered">
              <div className="flex-col-2">
                <div className="img side-line">
                  <img src={forgotBGImage} alt="Hero" />
                </div>
              </div>
              <div className="flex-col-2">
                <div className="login-box email-box">
                  <div className="title">
                    <h1>Change Email Address</h1>
                    <p>Please provide a email address below.</p>
                  </div>

                  <div className="field-wrap forget-mail">
                    <img className="icon-inputtype" src={emailSVG} alt="img" />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => handleValidation()}
                      onFocus={() => setError("")}
                      placeholder="Enter Your Email"
                    />
                  </div>
                  {error && <span style={{ color: "red" }}>{error}</span>}
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, {})(ChangeEmail);
