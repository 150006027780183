/* eslint-disable */
import React from "react";
import { LogOutAction } from "../../redux/actions/user";
import { connect } from "react-redux";
import logo from "../../assets/img/logo.svg";
import brokenIcon from "../../assets/img/broken.png";
import platform from "platform";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div id="app" className="page-landing">
            <header className="app-header">
              <div className="flex-container">
                <div className="flex-header">
                  <div className="logo">
                    <a href="/">
                      <img src={logo} alt="logo" />
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <section className="user-section">
              <div className="flex-container" style={{ textAlign: "center" }}>
                <img
                  src={brokenIcon}
                  alt="error"
                  style={{ height: "100px", width: "100px" }}
                />
                <h3>Something went wrong.</h3>
                <a href="/">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={(e) => this.props.LogOutAction()}
                  >
                    BACK TO HOME
                  </button>
                </a>
                {platform.name !== "Chrome" && platform.name !== "Microsoft Edge" && platform.name !== "Firefox" && (<div style={{textAlign:"center"}}>
                  For better viewing experience please open this website on Google Chrome, Firefox or Microsoft Edge.
                </div>)}
              </div>
            </section>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default connect(null, { LogOutAction })(ErrorBoundary);
