import React, { useState } from "react";
import {connect} from "react-redux";
import likeAuctionButton from "../../assets/img/like-auction-btn.svg";
import dpPhoto from "../../assets/img/img_avatar.png";

function Comments({ msgItem, key, setShowReportForm, setCommentText, role, setIsReportComment, setCommentBy }) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      <div className="loop user-comment" key={key}>
        <img
          src={msgItem?.profilePhoto ? msgItem.profilePhoto : dpPhoto}
          alt="chat"
          className="user-photo"
        />
        <div className="user-detail">
          <h5>
            {msgItem.firstName + " " + msgItem.lastName}{" "}
            <span className="time">{msgItem.timeStamp}</span>
          </h5>
          {msgItem.tippedAmount && msgItem.userTippedAmount ? (
            <div class="tip-recieved">{role === "Actor" ? `has tipped you €${parseFloat(msgItem.userTippedAmount).toFixed(2)} (received €${parseFloat(msgItem.tippedAmount).toFixed(2)})`: `Tipped €${parseFloat(msgItem.userTippedAmount).toFixed(2)}`}</div>
          ) : msgItem.image ? (
            <div
              style={{
                height: "170px",
                width: "100%",
                backgroundImage: `url(${msgItem.image})`,
                backgroundSize: "contain",
                backgroundPosition: "left center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          ) : (
            <p>{msgItem.msgText}</p>
          )}
        </div>
        <div className="like-auctions">
          {!msgItem.tippedAmount && <button
            className="btn-comment-setting"
            onClick={() => setOpenDialog(!openDialog)}
          >
            <img src={likeAuctionButton} alt="chat" className="heart-like" />
          </button>}
          <div className={`menu-comment-setting ${openDialog && "show"}`}>
            <ul>
              {/* {role === "Actor" && (<li>
                <a>Delete Comment</a>
              </li>)} */}
              <li>
                <a
                  onClick={() => {
                    setOpenDialog(false);
                    setCommentText(msgItem.image ? msgItem.image : msgItem.msgText);
                    setCommentBy(msgItem.userId);
                    setShowReportForm(true);
                    setIsReportComment(true);
                  }}
                >
                  Report Comment
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({USER_REDUCER}) => {
  return {
    role: USER_REDUCER?.userData.role
  }
}

export default connect(mapStateToProps, {})(Comments);
