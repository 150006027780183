import React, { useEffect, useState } from "react";
import Content from "./config/Content";
import {
  getToken,
  // onMessageListener
} from "./helpers/firebase";
import "./App.css";
import { useCookies } from "react-cookie";
import { Notify } from "./helpers/notifications";

function App() {
  // const [isTokenFound, setTokenFound] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["UserConsent"]);

  // console.log("cookies ", cookies.UserConsent)

  localStorage.setItem("uniqueTokenforStreaming", Math.random(12345));
  localStorage.setItem("uniqueTokenforViewing", Math.random(12345));
  localStorage.setItem("isSessionJoined", false);

  getToken();

  // onMessageListener()
  // .then(message => {
  //   console.log("from App.js", message);
  //   Notify("default", `${message.notification.body}`, `${message.notification.title}`)
  // })
  // .catch(error => console.log(error));

  useEffect(() => {
    let ua =
      window.navigator.userAgent || window?.navigator.vendor || window?.opera;
    if (ua.indexOf("FBAN") != -1 || ua.indexOf("FBAV") != -1) {
      Notify(
        "default",
        "For the best viewing experience and to access the full features please use your web or mobile browser",
        "",
        "",
        "",
        1000000
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener("online", (event) => {
      console.log("You are now connected to the network.");
      localStorage.setItem("uniqueTokenforStreaming", "");
      localStorage.setItem("uniqueTokenforViewing", "");
      // document.cookie = "username=actorrrrrrrr";
    });

    window.addEventListener("offline", (event) => {
      console.log("The network connection has been lost.");
      // document.cookie = "petros-session-id=";
      // document.cookie = "petros-unique-id=";
      // document.cookie = "petros-unique-id-view=null";
      localStorage.removeItem("petros-session-id");
      localStorage.removeItem("petros-unique-id");
      localStorage.removeItem("petros-unique-id-view");
      localStorage.removeItem("uniqueTokenforStreaming");
      // localStorage.removeItem("uniqueTokenforViewing");
      localStorage.setItem("uniqueTokenforViewing", "");
    });
  }, []);

  const [showCookieDetails, setShowCookieDetails] = useState(false);
  const [cookieTab, setCookieTab] = useState(1);
  const [cookieBox, setCookieBox] = useState(["strict", "prefer", "performance", "marketing"]);
  const [showCookieBanner, setShowCookieBanner] = useState(
    cookies?.UserConsent ? false : true
  );

  const handleCookieBox = (checked, value) => {
    if (checked) {
      let newArray = [...cookieBox];
      newArray.push(value);
      setCookieBox(newArray);
    } else {
      let filtered = cookieBox.filter((item) => item !== value);
      setCookieBox(filtered);
    }
  };

  const handleAllowAll = () => {
    const array = [
      "strict",
      "prefer",
      "performance",
      "marketing"
    ];
    setCookieBox(array);
    setShowCookieBanner(false);
    setCookie("UserConsent", array, {
      path: "/",
      expires: new Date(
        new Date().getFullYear() + 100,
        new Date().getMonth(),
        new Date().getDate()
      ),
    });
  };

  const handleAllowSelected = () => {
    console.log(cookieBox);
    setShowCookieBanner(false);
    setCookie("UserConsent", cookieBox, {
      path: "/",
      expires: new Date(
        new Date().getFullYear() + 100,
        new Date().getMonth(),
        new Date().getDate()
      ),
    });
  };

  return (
    <div className="App">
      <Content />

      {/* cookies */}
      {showCookieBanner && (
        <div className="cookies-wrapper">
          <div className="cookies-container">
            <div className="cookies-main">
              <div className="content-main">
                <h3 className="title">This website uses cookies</h3>
                <p className="content">
                  We use cookies to personalise content and ads, to provide
                  social media features and to analyse our traffic. We also
                  share information about your use of our site with our social
                  media, advertising and analytics partners who may combine it
                  with other information that you’ve provided to them or that
                  they’ve collected from your use of their services.
                </p>
              </div>
              <div className="cookies-option-panel-main">
                <div className="cookies-btn-main">
                  <button
                    className="allow-btn cookies-btn"
                    onClick={() => handleAllowSelected()}
                  >
                    Allow selection
                  </button>
                  <button
                    className="all-btn cookies-btn"
                    onClick={() => handleAllowAll()}
                  >
                    Allow all cookies
                  </button>
                </div>
              </div>
              <div className="custom-cookies-options">
                <div className="check-box-main">
                  <label
                    className="check-label"
                    htmlFor="strictly-necessary-cookies"
                  >
                    <input
                      className="cookies-check-box"
                      type="checkbox"
                      id="strictly-necessary-cookies"
                      name="cookieCheckbox"
                      value={"strict"}
                      onChange={(e) => {
                        // handleCookieBox(e.target.checked, e.target.value);
                      }}
                      checked={true}
                    />
                    <span>Strictly Necessary Cookies</span>
                  </label>
                  <label className="check-label" htmlFor="prefer-cookies">
                    <input
                      className="cookies-check-box"
                      type="checkbox"
                      id="prefer-cookies"
                      name="cookieCheckbox"
                      value={"prefer"}
                      onChange={(e) => {
                        handleCookieBox(e.target.checked, e.target.value);
                      }}
                      checked={cookieBox.includes("prefer")}
                    />
                    <span>Preferences Cookies</span>
                  </label>
                  <label className="check-label" htmlFor="performane-cookies">
                    <input
                      className="cookies-check-box"
                      type="checkbox"
                      id="performance-cookies"
                      name="cookieCheckbox"
                      value={"performance"}
                      onChange={(e) => {
                        handleCookieBox(e.target.checked, e.target.value);
                      }}
                      checked={cookieBox.includes("performance")}
                    />
                    <span>Statistics Cookies</span>
                  </label>
                  <label className="check-label" htmlFor="marketing-cookies">
                    <input
                      className="cookies-check-box"
                      type="checkbox"
                      id="marketing-cookies"
                      name="cookieCheckbox"
                      value={"marketing"}
                      onChange={(e) => {
                        handleCookieBox(e.target.checked, e.target.value);
                      }}
                      checked={cookieBox.includes("marketing")}
                    />
                    <span>Marketing Cookies</span>
                  </label>
                </div>
                <div
                  className="details-btn-main"
                  onClick={() => setShowCookieDetails(!showCookieDetails)}
                >
                  <div className="details-btn">
                    {showCookieDetails ? "Hide Details" : "Show Details"}{" "}
                    <span>&#x25BC;</span>
                  </div>
                </div>
              </div>
              <div className={`${!showCookieDetails && "cookies-tab-main"}`}>
                <div className="tab-container">
                  <ul className="tabs-cookie">
                    <li
                      className={`tab-link ${cookieTab === 1 && "current"}`}
                      data-tab="tab-1"
                      onClick={() => setCookieTab(1)}
                    >
                      Strictly Necessary Cookies
                    </li>
                    <li
                      className={`tab-link ${cookieTab === 2 && "current"}`}
                      data-tab="tab-2"
                      onClick={() => setCookieTab(2)}
                    >
                      Preferences Cookies
                    </li>
                    <li
                      className={`tab-link ${cookieTab === 3 && "current"}`}
                      data-tab="tab-3"
                      onClick={() => setCookieTab(3)}
                    >
                      Statistics Cookies
                    </li>
                    <li
                      className={`tab-link ${cookieTab === 4 && "current"}`}
                      data-tab="tab-4"
                      onClick={() => setCookieTab(4)}
                    >
                      Marketing Cookies
                    </li>
                  </ul>

                  <div
                    id="tab-1"
                    className={`tab-content ${cookieTab === 1 && "current"}`}
                  >
                    {/* <h5 className="tab-content-title">Strictly Necessary Cookies</h5> */}
                    These Cookies are necessary for the correct functioning of
                    the Website. This category only includes cookies that
                    ensures basic functionalities and security features of the
                    website. These Cookies are always active, and, according to
                    the applicable law, we do not need your consent to use them.
                  </div>
                  <div
                    id="tab-2"
                    className={`tab-content ${cookieTab === 2 && "current"}`}
                  >
                    These cookies enhance the functionality of the Website. They
                    allow us to remember preferences and choices you make, such
                    as your username or the selected language. They do not track
                    browsing activity across other websites.
                  </div>
                  <div
                    id="tab-3"
                    className={`tab-content ${cookieTab === 3 && "current"}`}
                  >
                    These Cookies allow us to count visits and traffic sources
                    so we can measure and improve the performance of our
                    Website. They help us to know which pages are the most and
                    least popular and how users move around the Website.
                  </div>
                  <div
                    id="tab-4"
                    className={`tab-content ${cookieTab === 4 && "current"}`}
                  >
                    Marketing cookies are used to track visitors across
                    websites. The intention is to display ads that are relevant
                    and engaging for the individual user and thereby more
                    valuable for publishers and third party advertisers.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* cookies */}
    </div>
  );
}

export default App;
