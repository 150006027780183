import React, { useEffect, useState } from "react";
import iconCal from "../../../assets/img/icon-cal.svg";
import iconWatch from "../../../assets/img/icon-watch.svg";
import iconCard from "../../../assets/img/icon-card.svg";
// import iconMute from "../../../assets/img/icon-mute.png";
// import iconUnMute from "../../../assets/img/icon-unmute.png";
import iconPlay from "../../../assets/img/play-button.png";
import { AddViewer, GetEventList } from "../../../redux/actions/events";
import dpPhoto from "../../../assets/img/img_avatar.png";
import IconLike from "../../../assets/img/icon-like.svg";
import ViewAll from "./viewAll";
import { useHistory } from "react-router-dom";
import { Notify } from "../../../helpers/notifications";
import { getEventLocalDate } from "../../../helpers/constants";
import { connect } from "react-redux";
import Store from "../../../redux/store/store";
import Logo from "../../loader/Loader";

function Home({ setSelectedTab, setEventID, role, setViewAllClicked }) {
  const history = useHistory();

  const [eventsData, setEventsData] = useState([]);
  const [showViewAll, setShowViewAll] = useState(false);
  const [viewAllText, setViewAllText] = useState("");
  const [defaultEvent, setDefaultEvent] = useState({});
  const [videoMute, setVideoMute] = useState(true);
  // const [defaultEventDate, setDefaultEventDate] = useState("");
  const [isFetched, setIsFetched] = useState(false);

  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Home";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    console.log("Event List API in HOME.JS");
    GetEventList()
      .then((response) => {
        // console.log(response.data.data);
        setEventsData([...response.data.data]);
        setDefaultEvent({ ...response.data.default });
        // setDefaultEventDate(
        //   new Date(response.data.default.eventDate).toDateString().split(" ")
        // );
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.default.eventImage,
            videoStatus: response.data.default.videoStatus,
          },
        });
        setIsFetched(true);
      })
      .catch((error) => {
        console.log(error);
        setIsFetched(false);
      });
  }, []);

  const handleViewAll = (catText) => {
    // View All Component
    // console.log("View All", catText)
    setViewAllClicked(true);

    if (catText === "allEvents") {
      setSelectedTab(2);
    } else {
      setViewAllText(catText);
      setShowViewAll(true);
    }
  };

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  const handleBookEvent = () => {
    console.log("in BOOK EVENT");
    setEventID(defaultEvent._id);
    setSelectedTab(8);
  };

  const handleViewer = async () => {
    let match = localStorage.getItem("petros-unique-id-view");

    let payload = {
      fcmToken: localStorage.getItem("fcmToken"),
      // fcmToken: localStorage.getItem("uniqueTokenforViewing"),
      uniqueId: match,
      eventId: defaultEvent._id,
    };

    try {
      let response = await AddViewer(payload);

      if (response.status === 200) {
        console.log("===============>", response.data);
        console.log(response.data.data.fcmToken, response.data.data.uniqueId);
        localStorage.setItem(
          "uniqueTokenforViewing",
          `${response.data.data.fcmToken}`
        );
        // document.cookie=`petros-unique-id-view=${String(response.data.data.uniqueId)}`;
        localStorage.setItem(
          "petros-unique-id-view",
          response.data.data.uniqueId
        );
        history.push(`/streamEvent`, {
          eventId: defaultEvent._id, shareEventURL: defaultEvent.share_url
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  }

  return (
    <div>
      <section
        className="events-section enduser-section"
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="flex-container">
          {isFetched ? (
          showViewAll ? (
            <ViewAll
              text={viewAllText}
              setShowViewAll={setShowViewAll}
              setEventID={setEventID}
              setSelectedTab={setSelectedTab}
              setViewAllClicked={setViewAllClicked}
            />
          ) : (
            <>
              {Object.keys(defaultEvent).length !== 0 ? 
              <div className="event-details">
                <h1>{defaultEvent.eventTitle}</h1>
                <div className="content">
                  <div className="date-time">
                    <div className="flex-row">
                      <div>
                        <img src={iconCal} alt="User" />
                        {
                          getEventLocalDate(
                            defaultEvent.eventDate,
                            defaultEvent.eventTime,
                            defaultEvent.setTimeZone
                          ).split(",")[0]
                        }
                      </div>
                      <div>
                        <img src={iconWatch} alt="User" />
                        {
                          getEventLocalDate(
                            defaultEvent.eventDate,
                            defaultEvent.eventTime,
                            defaultEvent.setTimeZone
                          ).split(",")[1]
                        }
                      </div>
                    </div>
                    <div className="flex-row">
                      <div>Event ID: #{defaultEvent.randomEventId}</div>
                      <div>
                        <img src={iconCard} alt="User" /> €{" "}
                        {defaultEvent.earlyBirdTicket > 0
                          ? parseFloat(
                              // defaultEvent.earlyBirdTicketPrice
                              defaultEvent.newEarlyBirdPrice
                            ).toFixed(2)
                          : parseFloat(
                            // defaultEvent.price
                            defaultEvent.newPrice
                            ).toFixed(2)}
                      </div>
                    </div>

                    <div className="flex-row">
                      {defaultEvent?.earlyBirdTicket > 0 && <div>
                        EarlyBird Tickets Available:{" "}
                        {defaultEvent?.earlyBirdTicket}
                      </div>}
                      <div>
                        Tickets Available :{" "}
                        {defaultEvent.ticketType === "Unlimited" ||
                        defaultEvent.audienceType === "Corporate"
                          ? "Available"
                          : defaultEvent?.availableTickets}
                      </div>
                    </div>
                  </div>

                  <p className="cat">Category: {defaultEvent.eventCategory}</p>
                  <p className="cat">
                    Duration:{" "}
                    {parseInt(parseInt(defaultEvent?.eventDuration) / 60) >=
                      1 &&
                      `${parseInt(
                        parseInt(defaultEvent?.eventDuration) / 60
                      )} hours`}{" "}
                    {parseInt(parseInt(defaultEvent?.eventDuration) % 60) > 0 &&
                      `${parseInt(
                        parseInt(defaultEvent?.eventDuration) % 60
                      )} minutes`}
                  </p>
                  <p className="desc">
                    <strong>Event Description:</strong>
                  </p>
                  <div className="description-scroll">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {defaultEvent?.description}
                    </p>
                  </div>
                </div>
                {defaultEvent.alreadyBooked && role === "Corporate" ? (
                  defaultEvent.isSessionStart &&
                    <button
                    className="primary-button mLeft"
                    type="button"
                    onClick={() => handleViewer()}
                  >
                    Watch Now
                  </button>
                ) : (
                  <>
                  {defaultEvent.availableTickets > 0 ? <button
                    className="primary-button"
                    type="button"
                    onClick={() => {
                      // defaultEvent.isSessionStart &&
                      // defaultEvent.alreadyBooked
                      //   ? history.push(`/streamEvent`, {
                      //       eventId: defaultEvent._id,
                      //     })
                      //   : defaultEvent.alreadyBooked
                      //   ? Notify("warning", "Event is already booked.")
                      //   :
                      handleBookEvent();
                    }}
                  >
                    Book Now
                  </button> : "No Tickets Available"}
                  {defaultEvent.alreadyBooked && defaultEvent.isSessionStart &&
                    <button
                    className="primary-button mLeft"
                    type="button"
                    onClick={() => handleViewer()}
                  >
                    Watch Now
                  </button>
                  }
                  </>
                )}

                <div className="banner-wrap">
                  <div
                    className="content banner-content-box"
                    style={{ width: "100%" }}
                  >
                    <div className="enduser-artist">
                      <h4>Artist</h4>
                      <div className="row-content">
                        <div
                          style={{
                            height: "200px",
                            width: "65%",
                            backgroundImage: `url(${
                              defaultEvent?.userId?.profilePhoto
                                ? defaultEvent.userId?.profilePhoto
                                : dpPhoto
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></div>
                        <div className="content">
                          <h5>
                            {defaultEvent?.userId?.firstName}{" "}
                            {defaultEvent?.userId?.lastName}
                          </h5>
                          <h6>{defaultEvent?.userId?.category}</h6>
                          {defaultEvent?.userId?.rateCount > 0 && <div className="rating">
                            <span
                              className="like-con-book"
                              style={{ marginBottom: "0px", cursor: "auto" }}
                            >
                              <img src={IconLike} alt="" />
                              {defaultEvent.artistRating} %
                            </span>
                          </div>}
                          <div className="description-scroll">
                            <p
                              className="p-box"
                              style={{
                                maxWidth: "500px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {defaultEvent?.userId?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="banner-wrap">
                  <div className="content banner-content-box">
                    <h1>{defaultEvent.eventTitle}</h1>
                    <div className="date-time">
                      <div className="flex-row">
                        <div>
                          <img src={iconCal} alt="User" />{" "}
                          {
                            getEventLocalDate(
                              defaultEvent.eventDate,
                              defaultEvent.eventTime,
                              defaultEvent.setTimeZone
                            ).split(",")[0]
                          }
                        </div>
                        <div>
                          <img src={iconWatch} alt="User" />{" "} */}
                {/* {defaultEvent.eventTime} */}
                {/* {
                            getEventLocalDate(
                              defaultEvent.eventDate,
                              defaultEvent.eventTime,
                              defaultEvent.setTimeZone
                            ).split(",")[1]
                          }
                        </div>
                      </div>
                      <div className="flex-row">
                        <div> */}
                {/* {defaultEvent.alreadyBooked ? "Booking" : "Event"} ID:
              #{defaultEvent._id} */}
                {/* Event ID: #{defaultEvent.randomEventId}
                        </div>
                        <div>
                          <img src={iconCard} alt="User" /> €{" "}
                          {defaultEvent.earlyBirdTicket > 0
                            ? parseFloat(
                                defaultEvent.earlyBirdTicketPrice
                              ).toFixed(2)
                            : parseFloat(defaultEvent.price).toFixed(2)}
                        </div>
                      </div>

                      <div className="flex-row">
                        <div>
                          EarlyBird Tickets Available:{" "}
                          {defaultEvent?.earlyBirdTicket}
                        </div>
                        <div>
                          Tickets Available :{" "} */}
                {/* {defaultEvent?.availableTickets} */}
                {/* {defaultEvent.ticketType === "Unlimited" ||
                          defaultEvent.audienceType === "Corporate"
                            ? "Available"
                            : defaultEvent?.availableTickets}
                        </div>
                      </div>
                    </div>
                    <p className="cat">
                      Category: {defaultEvent.eventCategory}{" "}
                    </p>
                    <p className="cat">
                      Duration:{" "}
                      {parseInt(parseInt(defaultEvent?.eventDuration) / 60) >=
                        1 &&
                        `${parseInt(
                          parseInt(defaultEvent?.eventDuration) / 60
                        )} hours`}{" "}
                      {parseInt(parseInt(defaultEvent?.eventDuration) % 60) >
                        0 &&
                        `${parseInt(
                          parseInt(defaultEvent?.eventDuration) % 60
                        )} minutes`}
                    </p>
                    <p className="desc">
                      <strong>Event Description:</strong>
                    </p>
                    <div className="description-scroll">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {defaultEvent?.description}
                      </p>
                    </div>
                    {defaultEvent.alreadyBooked && role === "Corporate" ? (
                      ""
                    ) : (
                      <button
                        className="primary-button"
                        type="button"
                        onClick={() => {
                          // defaultEvent.isSessionStart &&
                          // defaultEvent.alreadyBooked
                          //   ? history.push(`/streamEvent`, {
                          //       eventId: defaultEvent._id,
                          //     })
                          //   : defaultEvent.alreadyBooked
                          //   ? Notify("warning", "Event is already booked.")
                          //   :
                          handleBookEvent();
                        }}
                      > */}
                {/* {defaultEvent.isSessionStart && defaultEvent.alreadyBooked
            ? "Watch Now"
            : defaultEvent.alreadyBooked
            ? "Already Booked"
            : "Book Now"} */}
                {/* Book Now
                      </button>
                    )} */}

                {/* <div className="enduser-artist">
          <h4>Artist</h4>
          <div className="row-content">
            <div className="img">
              <img
                src={
                  defaultEvent?.userId?.profilePhoto
                    ? defaultEvent.userId?.profilePhoto
                    : dpPhoto
                }
                alt="User"
              />
            </div>
            <div className="content">
              <h5>
                {defaultEvent?.userId?.firstName}{" "}
                {defaultEvent?.userId?.lastName}
              </h5>
              <h6>{defaultEvent?.userId?.category}</h6>
              <div className="rating">
                <span className="like-con-book">
                  <img src={IconLike} alt="" />
                  {defaultEvent.artistRating} %
                </span>
              </div>
              <p className="p-box">
                {defaultEvent?.userId?.description}
              </p>
            </div>
          </div>
        </div> */}
                {/* </div> */}
                {/* <div className="outer-wrappper-1">
                    <div className="preview-img-wrap">
                      <div
                        className="preview-con"
                        style={{ position: "relative" }}
                      >
                        {defaultEvent.videoStatus ? (
                          <>
                            <div
                              className="cursorPointer video-mute-div"
                              onClick={() => handleVideoMute()}
                            >
                              {videoMute ? (
                                <img src={iconMute} />
                              ) : (
                                <img src={iconUnMute} />
                              )}
                            </div>
                            <video
                              // minHeight="480"
                              height="480"
                              width="100%"
                              // controls
                              autoPlay={true}
                              loop={true}
                              muted={videoMute}
                            >
                              <source src={defaultEvent?.eventImage} />
                            </video>
                          </>
                        ) : (
                          <div
                            className="eventImage"
                            style={{
                              // paddingTop: "0px",
                              minHeight: "480px",
                              //   width: "100%",
                              backgroundImage: `url(${
                                defaultEvent?.videoStatus
                                  ? defaultEvent?.thumbnailImage
                                  : defaultEvent?.eventImage
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-wrap">
                  <div
                    className="content banner-content-box"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="enduser-artist"
                      style={{ marginTop: "0px" }}
                    >
                      <h4>Artist</h4>
                      <div className="row-content">
                        <div
                          // className="eventImage"
                          style={{
                            // paddingTop: "0px",
                            height: "200px",
                            width: "65%",
                            backgroundImage: `url(${
                              defaultEvent?.userId?.profilePhoto
                                ? defaultEvent.userId?.profilePhoto
                                : dpPhoto
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></div>
                        <div className="content">
                          <h5>
                            {defaultEvent?.userId?.firstName}{" "}
                            {defaultEvent?.userId?.lastName}
                          </h5>
                          <h6>{defaultEvent?.userId?.category}</h6>
                          <div className="rating">
                            <span
                              className="like-con-book"
                              style={{ marginBottom: "0px", cursor: "auto" }}
                            >
                              <img src={IconLike} alt="" />
                              {defaultEvent.artistRating} %
                            </span>
                          </div>
                          <div className="description-scroll">
                            <p
                              className="p-box"
                              style={{
                                maxWidth: "500px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {defaultEvent?.userId?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

               : <div className="event-details" style={{ textAlign: "center" }}><h2>There are no events for now</h2></div> }
              {eventsData?.map((catData, index) => (
                <div className="events-listing enduser-events" key={index}>
                  <div className="title">
                    <h2>
                      {catData.category === "liveData"
                        ? "Events Live Now"
                        : catData.category === "trendingData"
                        ? "Trending Events"
                        : catData.category === "allEvents"
                        ? "All Events"
                        : catData.category}
                    </h2>
                    <a
                      className="viewall cursorPointer"
                      onClick={() => handleViewAll(catData.category)}
                    >
                      View All
                    </a>
                  </div>

                  <div className="flex-row gutter-space">
                    {catData.data.length > 0 ? (
                      catData.data.map((eventItem, i) => (
                        <div className="loop flex-col-4" key={i}>
                          <div
                            className="grey-wrap item cursorPointer"
                            onClick={() => {
                              // history.push(`/home/user/eventDetails/${eventItem._id}`)
                              setEventID(eventItem._id);
                              setSelectedTab(7);
                            }}
                          >
                            <div className="img">
                              <div
                              onMouseOver={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus
                                ? eventItem.thumbnailImage
                                : eventItem.eventImage})`
                              }}
                                style={{
                                  minHeight: "150px",
                                  width: "100%",
                                  backgroundImage: `url(${
                                    eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                              </div>
                                {eventItem.videoStatus && <div style={{
                                  position: "absolute",
                                  top: "155px"
                                }}><img src={iconPlay} style={{width:"40px"}} /></div>}
                            </div>
                            <div className="details">
                              <div className="name">
                                {/* <h3>{eventItem.eventTitle}</h3> */}
                                <h3 title={eventItem.eventTitle}>
                                  {eventItem.eventTitle.length > 19
                                    ? eventItem.eventTitle.slice(0, 19) + "..."
                                    : eventItem.eventTitle}
                                </h3>
                                <span>
                                  €
                                  {eventItem.earlyBirdTicket > 0
                                    ? parseFloat(
                                        // eventItem.earlyBirdTicketPrice
                                        eventItem.newEarlyBirdPrice
                                      ).toFixed(2)
                                    : parseFloat(
                                      // eventItem.price
                                      eventItem.newPrice
                                      ).toFixed(2)}
                                </span>
                              </div>
                              <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ? 
                              (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                              {eventItem.earlyBirdTicket > 0 && (
                                <div className="ticket">
                                  Earlybird Tickets Available
                                  <span>{eventItem.earlyBirdTicket}</span>
                                </div>
                              )}
                              <div className="ticket">
                                Tickets Available
                                <span>
                                  {/* {eventItem.availableTickets} */}
                                  {eventItem.ticketType === "Unlimited" ||
                                  eventItem.audienceType === "Corporate"
                                    ? "Available"
                                    : eventItem?.availableTickets}
                                </span>
                              </div>
                              <div className="date-time">
                                {getEventLocalDate(
                                  eventItem.eventDate,
                                  eventItem.eventTime,
                                  eventItem.setTimeZone
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        {catData.category === "liveData"
                          ? "There are no events live now"
                          : "No Events Present"}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )) : <Logo />}
        </div>
      </section>
      {/* <section className="newsletter enduser-newsletter">
        <div className="flex-container">
          <h4>Stay up to date for any upcoming events</h4>
          <p>
            Lorem Ipsum is simply dummy text of the sdssprinting and sdss ss
            ttext of the sdssprintingypesetting industry. Lorem Ipsum has been.
          </p>
          <div className="form-newsletter">
            <form className="form">
              <input type="email" placeholder="Enter Your Email Here" />
              <button className="primary-button subscribe">Subscribe</button>
            </form>
          </div>
        </div>
      </section> */}
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData.role,
  };
};

export default connect(mapStateToProps, {})(Home);
