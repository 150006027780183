import React from "react";
import { Route } from "react-router-dom";

const isEqual = (prevProps, nextProps) => {
  // console.log("==========>",nextProps.path);
  return prevProps.path == nextProps.path 
  // || prevProps.Component.type.WrappedComponent.name == nextProps.Component.type.WrappedComponent.name
}

const Wrapper = React.memo(({ path, Component }) => {
// const Wrapper = ({ path, Component }) => {
  // const history = useHistory();

//   useEffect(() => {
//     history.push(path);
//   }, []);
//   console.log(path);
  return (
    <>
      {console.log("=========>",path)}
      <Route exact path={path} component={() => Component} />
    </>
  );
}, isEqual);
// };

export default Wrapper;
