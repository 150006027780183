import { combineReducers } from "redux";
import USER_REDUCER from "./user";
import EVENTS_REDUCER from "./events";
import EVENT_FILE from "./eventImage";
import CHAT_FILE from "./chat";
import NOTIF_REDUCER from "./notifEvents";

const appReducer = combineReducers({
  USER_REDUCER,
  EVENTS_REDUCER,
  EVENT_FILE,
  CHAT_FILE, 
  NOTIF_REDUCER
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
