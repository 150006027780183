import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import twitterPNG from "../../assets/img/twitter.png";
import facebookPNG from "../../assets/img/facebook.png";
import EU_POS from "../../assets/img/EU_POS.jpg";
import CYPRUS_REPUBLIC_EG from "../../assets/img/CYPRUS_REPUBLIC_EG.jpg";
import instagramPNG from "../../assets/img/instagram.png";
import performerSVG from "../../assets/img/performer.svg";
import fansSVG from "../../assets/img/fans.svg";
import heroSVG from "../../assets/img/hero-img.svg";
import visionSVG from "../../assets/img/vision.svg";
import aimSVG from "../../assets/img/aim.svg";
import nextArrow from "../../assets/img/next-arrow-slider.svg";
import prevArrow from "../../assets/img/prev-arrow-slider.svg";
import iconPlay from "../../assets/img/play-button.png";
import { GetUpcommingEventsLandingPage } from "../../redux/actions/events";
// import stayupSVG from "../../assets/img/stayup.svg";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import { getEventLocalDate } from "../../helpers/constants";

function LandingPage({ history }) {
  const [eventList, setEventList] = useState([]);
  // const [showMaintenance, setShowMaintenance] = useState(false);
  useEffect(() => {
    document.title = "LiveStage | Your Online Stage";
    document.head.querySelector('meta[name="description"]').content =
      "Reach your fans globally and stream your live event with a single click - no extra software needed for streaming. Make a living online while showcasing your talent! On Web, Mobile and TVs. Interactive, real-time and the best quality streaming.";
    document.head.querySelector('meta[name="keywords"]').content =
      "livestage , live stage, live streaming, artists, make a living online, simple live streaming, stream simple, live streaming platform, live stream, showcase talent, innovation live stream, ticketed live stream, ticketed live streaming, support art, stream live events, live stream , art streaming app, free art streaming, digital art streaming, streaming art";
    document.head.querySelector(
      'meta[property="og:title"]'
    ).content = `LiveStage | Your Online Stage`;
    document.head.querySelector(
      'meta[property="og:description"]'
    ).content = `Reach your fans globally and stream your live event with a single click - no extra software needed for streaming. Make a living online while showcasing your talent! On Web, Mobile and TVs. Interactive, real-time and the best quality streaming.`;
    document.head.querySelector(
      'meta[property="og:image"]'
    ).content = `https://www.livestage.stream/favicon-325x325.png`;
    document.head.querySelector(
      'meta[name="twitter:title"]'
    ).content = `LiveStage | Your Online Stage`;
    document.head.querySelector(
      'meta[name="twitter:description"]'
    ).content = `Reach your fans globally and stream your live event with a single click - no extra software needed for streaming. Make a living online while showcasing your talent! On Web, Mobile and TVs. Interactive, real-time and the best quality streaming.`;
    document.head.querySelector(
      'meta[name="twitter:image"]'
    ).content = `https://www.livestage.stream/favicon-325x325.png`;
    document.head.querySelector(
      'meta[property="og:url"]'
    ).content = `https://www.livestage.stream/`;
    document.head.querySelector(
      'meta[property="twitter:url"]'
    ).content = `https://www.livestage.stream/`;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    GetUpcommingEventsLandingPage()
      .then((response) => {
        // console.log(response.data.data);
        setEventList([...response.data.data]);
      })
      .catch((error) => console.log(error));
  }, []);

  // useEffect(() => {
  //   let video = document.getElementById("ytVideoHomepage");

  //   console.log(video)
  // }, [])

  return (
    <div id="app" className="page-landing">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a>
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="account-links">
              <button
                className="primary-button signin"
                onClick={() => history.push("/login")}
              // onClick={() => setShowMaintenance(true)}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </header>

      <section className="seo-section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="content">
              <h1>LiveStage.stream - Your Online Stage!</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-section landing-hero-section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={heroSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="socialmedia">
                {/* <a href="#">
                  <img src={twitterPNG} alt="Hero" />
                </a> */}
                <a
                  href="https://www.facebook.com/livestage.stream/"
                  target="_blank"
                >
                  <img src={facebookPNG} alt="Hero" />
                </a>
                <a
                  href="https://instagram.com/livestage.stream?utm_medium=copy_link"
                  target="_blank"
                >
                  <img src={instagramPNG} alt="Hero" />
                </a>
                <span className="title">Follow</span>
              </div>
              <div className="content">
                <h1>
                  Unlimited <span>live concerts,</span> shows and more.
                </h1>
                <h2>
                  Enjoy an exclusive digital live show experience -{" "}
                  <span style={{ color: "#e50914" }}>from anywhere</span> in the
                  world.{" "}
                </h2>
                <p>
                  LiveStage is a{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    live streaming
                  </span>{" "}
                  and ticketing platform which hosts live & interactive
                  performances from artists. Fans and artists get to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    connect
                  </span>{" "}
                  through an exclusive digital live experience which promotes{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    interactivity
                  </span>{" "}
                  and allows fans to actively{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    support
                  </span>{" "}
                  their favorite artists.
                </p>
                <p>
                  Artists/Performers stream their live shows through their own
                  private virtual stage which is offered by LiveStage.
                </p>
                <button
                  type="button"
                  className="primary-button"
                  onClick={() => history.push("/login")}
                //onClick={() => setShowMaintenance(true)}
                >
                  Login to Live Stage
                </button>
              </div>
            </div>
          </div>
        </div>

        {eventList.length > 0 && (
          <div className="home-item benefits-section upcomoing-events-section">
            <div className="flex-container">
              <div className="title-main">
                <h3>Upcoming Events</h3>
              </div>
            </div>
            <div className="fluid-container">
              <div className="swiper mySwiper">
                <div className="flex-row slider-row-main gutter-space swiper-wrapper">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    spaceBetween={5}
                    loop={true}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    breakpoints={{
                      270: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      360: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      1024: {
                        slidesPerView: eventList.length > 1 ? 2 : 1,
                        spaceBetween: 5,
                      },
                      1280: {
                        slidesPerView: eventList.length > 1 ? 2 : 1,
                        spaceBetween: 5,
                      },
                      1600: {
                        slidesPerView: eventList.length > 2 ? 3 : eventList.length > 1 ? 2 : 1,
                        spaceBetween: 5,
                      },
                    }}
                  >
                    {eventList.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="cursorPointer"
                          onClick={() =>
                            window.open(`${eventList[index].shareLink}`)
                          }
                        >
                          <div className="slide-column">
                            <div className="img-col columns">
                              <div
                                onMouseOver={(e) => {
                                  e.target.style.backgroundImage = `url(${item.videoStatus
                                    ? item.gifURL
                                    : item.eventImage
                                    })`;
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.backgroundImage = `url(${item.videoStatus
                                    ? item.thumbnailImage
                                    : item.eventImage
                                    })`;
                                }}
                                style={{
                                  minHeight: "150px",
                                  width: "100%",
                                  backgroundImage: `url(${item.videoStatus
                                    ? item.thumbnailImage
                                    : item.eventImage
                                    })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              {item.videoStatus && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "140px",
                                  }}
                                >
                                  <img
                                    src={iconPlay}
                                    style={{ width: "40px" }}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="columns content-col">
                              <div className="col-title-main">
                                <h5
                                  className="col-title"
                                  title={item.eventTitle}
                                >
                                  {item.eventTitle.length > 14
                                    ? item.eventTitle.slice(0, 14) + "..."
                                    : item.eventTitle}
                                </h5>
                              </div>
                              <ul className="event-details">
                                <li className="details-items">
                                  {getEventLocalDate(
                                    item.eventDate,
                                    item.eventTime,
                                    item.setTimeZone
                                  )}
                                </li>
                                <li
                                  className="details-items"
                                  title={item.creatorName}
                                >
                                  {item.creatorName.length > 14
                                    ? item.creatorName.slice(0, 14) + "..."
                                    : item.creatorName}
                                </li>
                                <li className="details-items">
                                  Tickets Available :{" "}
                                  {item.isUnlimited
                                    ? "Available"
                                    : item.availableTickets}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>

              <div class="swiper-scroll-wrap">
                <div class="swiper-pagination ctrls-mobile"></div>
              </div>
              <div className="controls-wrapper">
                <div className="swiper-button-prev">
                  <img src={prevArrow} alt="" />
                </div>
                <div className="swiper-button-next">
                  <img src={nextArrow} alt="" />
                </div>
              </div>

              <div className="view-all-link">
                <a href={eventList[0].shareLink}>View All Upcoming Events</a>
              </div>
            </div>
          </div>
        )}

        <div className="home-item benefits-section video-section upcomoing-events-section">
          <div className="flex-container">
            <div className="title-main">
              <h3>Watch Our Video</h3>
            </div>
          </div>

          <div className="fluid-container">
            <div className="vid-area">
              <iframe
                id="ytVideoHomepage"
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/9fySK4jezzY"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* <div className="player"></div> */}
            </div>
          </div>
        </div>

        <div className="home-item benefits-section section">
          <div className="flex-container">
            <h3>
              Artist <span style={{ color: "#e30914" }}>Benefits</span> - Stream
              your shows on your virtual stage and make a living online!
            </h3>
            <p>
              LiveStage is{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>free</span>{" "}
              for all artists to use. There is no cost to register as an
              artist/performer, to create an event or to stream your live event
              to your fans. All artists can showcase their live shows as they
              would on a real stage. You choose the date, time, and ticket
              price.{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>
                This is your online stage.
              </span>
            </p>
            <p>
              Start streaming your event with just a single click! No extra
              software to download, no hassle.{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>
                Luxurious simplicity.
              </span>
            </p>
            <button
              type="button"
              className="primary-button"
              onClick={() => history.push("/signup/actor")}
            //   onClick={() => setShowMaintenance(true)}
            >
              Sign up as a Performer!
            </button>
          </div>
        </div>
      </section>

      <section className="home-item section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={performerSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>Become a live Performer</h3>
                <p>
                  We appreciate the arts and honor those who offer it
                  generously. Live Stage is dedicated in providing artists with
                  the greatest amount of the sales proceeds (ticket sales +
                  tips). At the end of each event, your earnings are paid out
                  directly to your bank account.
                </p>
                <p>
                  You have the potential to earn an income online. Interested to
                  become a performer on LiveStage?
                </p>
                <button
                  type="button"
                  className="primary-button"
                  onClick={() => history.push("/signup/actor")}
                // onClick={() => setShowMaintenance(true)}
                >
                  Click here
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={fansSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  Fans - Your <span style={{ color: "#e50914" }}>Gateway</span>{" "}
                  to Art
                </h3>
                <p>
                  Fans of art and culture can purchase tickets to live shows
                  prior to or during any show.
                </p>
                <p>
                  Express yourself on the live chat and communicate directly
                  with your favorite artists. Show your love by pressing the
                  ‘heart’ button and show your support them by giving to artists
                  tips. Feel free to say hello by sending your selfie in the
                  chat and prepare yourself for an interactive experience!
                </p>
                <p>
                  LiveStage expects fans to use the platform as a form of{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    entertainment
                  </span>{" "}
                  and a{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    gateway to art
                  </span>
                  , and to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    respect
                  </span>{" "}
                  all artists who are performing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={visionSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  An <span style={{ color: "#e50914" }}>Artistic</span>{" "}
                  Community
                </h3>
                <p>
                  We could not come up with regulations that define what is
                  right and what is wrong as we believe that people like us who
                  use art as an escape to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    creativity
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    imagination
                  </span>{" "}
                  cannot keep up with 'a set of rules'.
                </p>
                <p>
                  In most cases,{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    art is made to break the rules!
                  </span>
                </p>
                <p>We truly encourage you to:</p>
                <p>
                  • Be excellent to each another - be positive -{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    be passionate
                  </span>{" "}
                  about art!
                </p>
                <p>
                  • Set the 'blind rules' of this community if you wish. This is
                  a virtual neighborhood of art - and art is all about{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    mutual respect
                  </span>{" "}
                  and contradictory opinions. To help you with this we added a
                  'report' button in the comments section of the live events. If
                  you see anything that you find offensive or abusive to you or
                  anyone else, please hit the report button.
                </p>
                <p>
                  Enjoy your{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    experience
                  </span>{" "}
                  at LiveStage!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={aimSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  Our <span style={{ color: "#e50914" }}>Aim</span> & Vision
                </h3>
                <p>
                  Our aim is to support artists actively and practically by
                  providing them the opportunity to showcase their passion to a
                  greater community formed by fans of art and culture.
                </p>
                <p>
                  Our vision is to adapt in a constantly changing world so that
                  we can keep honoring art.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="newsletter">
        <div className="flex-container">
          <h4>Stay up to date for any upcoming events</h4>
          <p>
            Lorem Ipsum is simply dummy text of the sdssprinting and sdss ss
            ttext of the sdssprintingypesetting industry. Lorem Ipsum has been.
          </p>
          <div className="form-newsletter">
            <form className="form">
              <input type="email" placeholder="Enter Your Email Here" />
              <button className="primary-button subscribe">Subscribe</button>
            </form>
          </div>
          <div className="img">
            <img src={stayupSVG} alt="Hero" />
          </div>
        </div>
      </section> */}

      {/* {showMaintenance ? (
        <div
          className="overlay-box video-confirm-pop"
          style={{ maxWidth: "800px" }}
        >
          <h3>
            LiveStage is improving. We will be back on the 21/07/2022.
            <br />
            For any inquiries please contact us at{" "}
            <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
          </h3>
          <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
            <button
              type="button"
              className="primary-button"
              onClick={() => setShowMaintenance(false)}
            >
              Back
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`overlay fix-opaicty ${showMaintenance && "show"}`}></div> */}

      <footer className="app-footer">
        <div className="flex-container">
          <div className="footer-content">
            {/* <div className="question">Questions? Call 415-784-458</div> */}
            <div className="footer-menu">
              <ul>
                <li>
                  <a href="/faq">FAQ</a>
                </li>
                <li>
                  <a href="/termsOfUse">Terms of Use</a>
                </li>
                <li>
                  <a href="/cookiePreference">Cookie Policy</a>
                </li>
                {/* <li>
                  <a href="/helpCentre">Help Centre</a>
                </li> */}
                <li>
                  <a href="/privacy">Privacy</a>
                </li>
                {/* <li>
                  <a href="/eventInformation">Event Information</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", flexWrap: "nowrap" }}>
            <a style={{ marginRight: "2px" }}>
              <img src={EU_POS} alt="EU logo" style={{ width: "100px", height: "90px" }} />
            </a>
            <a style={{ marginRight: "8px" }}>
              <img src={CYPRUS_REPUBLIC_EG} alt="Cyprus logo" style={{ width: "100px", height: "90px" }} />
            </a>
            <p style={{ color: "white", flex: 1, marginTop: "70px" }}>
              The project is co-funded by the EU within the framework of the Cohesion Policy Programme “THALIA 2021-2027”.
            </p>
          </div>
          <div className="copyright">
            Copyright 2020. All Rights Reserved Live Stage
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
