import React, { useEffect } from "react";
import twitterPNG from "../../assets/img/twitter.png";
import facebookPNG from "../../assets/img/facebook.png";
import instagramPNG from "../../assets/img/instagram.png";
import performerSVG from "../../assets/img/performer.svg";
import fansSVG from "../../assets/img/fans.svg";
import heroSVG from "../../assets/img/hero-img.svg";
import visionSVG from "../../assets/img/vision.svg";
import aimSVG from "../../assets/img/aim.svg";

function AboutUs({ setSelectedTab }) {
  useEffect(() => {
    document.title = "LiveStage | About Us";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="app" className="page-myaccount">
      <section className="page-section">
        <div className="flex-container ">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a className="cursorPointer" onClick={() => setSelectedTab(1)}>
                  Home
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>About us</li>
            </ul>
          </div>

          <div className="title">
            <h1>About us</h1>
          </div>
        </div>
      </section>

      {/* <div className="content"> */}
      <section className="seo-section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="content">
            <h1>LiveStage.stream - Your Online Stage!</h1>
            </div>
          </div>
        </div>
      </section>
      
      <section className="hero-section aboutusback">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={heroSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="socialmedia">
                {/* <a href="#">
                  <img src={twitterPNG} alt="Hero" />
                </a> */}
                <a
                  href="https://www.facebook.com/livestage.stream/"
                  target="_blank"
                >
                  <img src={facebookPNG} alt="Hero" />
                </a>
                <a
                  href="https://instagram.com/livestage.stream?utm_medium=copy_link"
                  target="_blank"
                >
                  <img src={instagramPNG} alt="Hero" />
                </a>
                <span className="title">Follow</span>
              </div>
              <div className="content">
                <h1>
                  Unlimited <span>live concerts,</span> shows and more.
                </h1>
                <h2>
                  Enjoy an exclusive digital live show experience -{" "}
                  <span style={{ color: "#e50914" }}>from anywhere</span> in the
                  world.{" "}
                </h2>
                <p>
                  LiveStage is a{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    live streaming
                  </span>{" "}
                  and ticketing platform which hosts live & interactive
                  performances from artists. Fans and artists get to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    connect
                  </span>{" "}
                  through an exclusive digital live experience which promotes{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    interactivity
                  </span>{" "}
                  and allows fans to actively{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    support
                  </span>{" "}
                  their favorite artists.
                </p>
                <p>
                  Artists/Performers stream their live shows through their own
                  private virtual stage which is offered by LiveStage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="home-item benefits-section">
          <div className="flex-container">
            <h3>
              Artist <span style={{ color: "#e30914" }}>Benefits</span> - Stream
              your shows on your virtual stage and make a living online!
            </h3>
            <p>
              LiveStage is{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>free</span>{" "}
              for all artists to use. There is no cost to register as an
              artist/performer, to create an event or to stream your live event
              to your fans. All artists can showcase their live shows as they
              would on a real stage. You choose the date, time, and ticket
              price.{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>
                This is your online stage.
              </span>
            </p>
            <p>
              Start streaming your event with just a single click! No extra
              software to download, no hassle.{" "}
              <span style={{ color: "#e30914", fontWeight: "bold" }}>
                Luxurious simplicity.
              </span>
            </p>
          </div>

          {/* </div> */}
        </div>
      </section>

      <section className="home-item aboutusback">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={performerSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>Become a live Performer</h3>
                <p>
                  We appreciate the arts and honor those who offer it
                  generously. Live Stage is dedicated in providing artists with
                  the greatest amount of the sales proceeds (ticket sales +
                  tips). At the end of each event, your earnings are paid out
                  directly to your bank account.
                </p>
                <p>
                  You have the potential to earn an income online. Interested to
                  become a performer on LiveStage?
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item aboutusback">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={fansSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  Fans - Your <span style={{ color: "#e50914" }}>Gateway</span>{" "}
                  to Art
                </h3>
                <p>
                  Fans of art and culture can purchase tickets to live shows
                  prior to or during any show.
                </p>
                <p>
                  Express yourself on the live chat and communicate directly
                  with your favorite artists. Show your love by pressing the
                  ‘heart’ button and show your support them by giving to artists
                  tips. Feel free to say hello by sending your selfie in the
                  chat and prepare yourself for an interactive experience!
                </p>
                <p>
                  LiveStage expects fans to use the platform as a form of{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    entertainment
                  </span>{" "}
                  and a{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    gateway to art
                  </span>
                  , and to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    respect
                  </span>{" "}
                  all artists who are performing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item aboutusback">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={visionSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  An <span style={{ color: "#e50914" }}>Artistic</span>{" "}
                  Community
                </h3>
                <p>
                  We could not come up with regulations that define what is
                  right and what is wrong as we believe that people like us who
                  use art as an escape to{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    creativity
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    imagination
                  </span>{" "}
                  cannot keep up with 'a set of rules'.
                </p>
                <p>
                  In most cases,{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    art is made to break the rules!
                  </span>
                </p>
                <p>We truly encourage you to:</p>
                <p>
                  • Be excellent to each another - be positive -{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    be passionate
                  </span>{" "}
                  about art!
                </p>
                <p>
                  • Set the 'blind rules' of this community if you wish. This is
                  a virtual neighborhood of art - and art is all about{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    mutual respect
                  </span>{" "}
                  and contradictory opinions. To help you with this we added a
                  'report' button in the comments section of the live events. If
                  you see anything that you find offensive or abusive to you or
                  anyone else, please hit the report button.
                </p>
                <p>
                  Enjoy your{" "}
                  <span style={{ color: "#e50914", fontWeight: "bold" }}>
                    experience
                  </span>{" "}
                  at LiveStage!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-item aboutusback">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={aimSVG} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content">
                <h3>
                  Our <span style={{ color: "#e50914" }}>Aim</span> & Vision
                </h3>
                <p>
                  Our aim is to support artists actively and practically by
                  providing them the opportunity to showcase their passion to a
                  greater community formed by fans of art and culture.
                </p>
                <p>
                  Our vision is to adapt in a constantly changing world so that
                  we can keep honoring art.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
