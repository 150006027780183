import React, { useCallback, useEffect, useRef, useState } from "react";
import { GetEventList } from "../../../redux/actions/events";
import Loader from "../../loader/Loader";
import { getEventLocalDate } from "../../../helpers/constants";
import iconPlay from "../../../assets/img/play-button.png";

function ViewAll({ text, setShowViewAll, setEventID, setSelectedTab, setViewAllClicked }) {
  const [paramsPayload, setParamsPayload] = useState({
    skip: 0, // Integer
    limit: 12, // Integer
    category: text, // String, Array
    searchString: "", // String
  });

  const [viewAllData, setViewAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [flag, setFlag] = useState(false);

  const observer = useRef();

  const loadMore = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      if (!hasMore || loading) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setParamsPayload({ ...paramsPayload, skip: paramsPayload.skip + 12 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    console.log("EventList API in ViewAll(User)", paramsPayload);
    setLoading(true);
    GetEventList(paramsPayload)
      .then((response) => {
        setViewAllData([...viewAllData, ...response?.data?.data]);
        setHasMore(
          response.data.count -
            (viewAllData.length + response?.data?.data.length) >
            0
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // hasMore --> false , skip ---> skip - 12
        setLoading(false);
      });
  }, [paramsPayload]);

  // Search Events
  useEffect(() => {
    console.log("SEARCH");
    const timeoutId = setTimeout(() => {
      if (flag) {
        setHasMore(false);
        setViewAllData([]);
        setParamsPayload({
          ...paramsPayload,
          skip: 0,
          searchString: searchKeyword,
        });
      }
    }, 750);
    setFlag(true);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchKeyword]);

  return (
    <>
      <div className="breadcrumbs">
        <ul>
          <li>
            <a className="cursorPointer" onClick={() => {
              setViewAllClicked(false);
              setShowViewAll(false);
              }}>
              Home
            </a>{" "}
            <span>&#62;</span>{" "}
          </li>
          <li>
            {text === "liveData"
              ? "Events Live Now"
              : text === "trendingData"
              ? "Trending Events"
              : text === "allEvents"
              ? "All Events"
              : text}
          </li>
        </ul>
      </div>

      <div className="events-listing enduser-events">
        <div className="title">
          <h2>
            {text === "liveData"
              ? "Events Live Now"
              : text === "trendingData"
              ? "Trending Events"
              : text === "allEvents"
              ? "All Events"
              : text}
          </h2>
        </div>

        <div className="events-search">
          <input
            type="text"
            placeholder="Search"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          {/* <div>
                <img src={iconClose} alt="clear" />
              </div> */}
        </div>

        <div className="flex-row gutter-space">
          {viewAllData.length > 0 ? (
            viewAllData.map((eventItem, index) => {
              if (viewAllData.length === index + 1) {
                return (
                  <div className="loop flex-col-4" key={index} ref={loadMore}>
                    <div
                      className="grey-wrap item cursorPointer"
                      onClick={() => {
                        setEventID(eventItem._id);
                        setSelectedTab(7);
                      }}
                    >
                      <div className="img">
                        <div
                        onMouseOver={(e) => {
                          e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundImage = `url(${eventItem.videoStatus
                          ? eventItem.thumbnailImage
                          : eventItem.eventImage})`
                        }}
                          style={{
                            minHeight: "150px",
                            width: "100%",
                            backgroundImage: `url(${
                              eventItem.videoStatus
                                ? eventItem.thumbnailImage
                                : eventItem.eventImage
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        {eventItem.videoStatus && <div style={{
                                  position: "absolute",
                                  top: "155px"
                                }}><img src={iconPlay} style={{width:"40px"}} /></div>}
                      </div>
                      <div className="details">
                        <div className="name">
                          <h3 title={eventItem.eventTitle}>
                            {eventItem.eventTitle.length > 19
                              ? eventItem.eventTitle.slice(0, 19) + "..."
                              : eventItem.eventTitle}
                          </h3>
                          <span>
                            €
                            {eventItem.earlyBirdTicket > 0
                              ? parseFloat(
                                  // eventItem.earlyBirdTicketPrice
                                  eventItem.newEarlyBirdPrice
                                ).toFixed(2)
                              : parseFloat(
                                // eventItem.price
                                eventItem.newPrice
                                ).toFixed(2)}
                          </span>
                        </div>
                        <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ? 
                              (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                        {eventItem.earlyBirdTicket > 0 && (
                          <div className="ticket">
                            Earlybird Tickets Available
                            <span>{eventItem.earlyBirdTicket}</span>
                          </div>
                        )}
                        <div className="ticket">
                          Tickets Available
                          <span>{eventItem.availableTickets}</span>
                        </div>
                        <div className="date-time">
                          {getEventLocalDate(
                            eventItem.eventDate,
                            eventItem.eventTime,
                            eventItem.setTimeZone
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="loop flex-col-4" key={index}>
                    <div
                      className="grey-wrap item cursorPointer"
                      onClick={() => {
                        setEventID(eventItem._id);
                        setSelectedTab(7);
                      }}
                    >
                      <div className="img">
                        <div
                        onMouseOver={(e) => {
                          e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundImage = `url(${eventItem.videoStatus
                          ? eventItem.thumbnailImage
                          : eventItem.eventImage})`
                        }}
                          style={{
                            minHeight: "150px",
                            width: "100%",
                            backgroundImage: `url(${
                              eventItem.videoStatus
                                ? eventItem.thumbnailImage
                                : eventItem.eventImage
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        {eventItem.videoStatus && <div style={{
                                  position: "absolute",
                                  top: "155px"
                                }}><img src={iconPlay} style={{width:"40px"}} /></div>}
                      </div>
                      <div className="details">
                        <div className="name">
                          <h3 title={eventItem.eventTitle}>
                            {eventItem.eventTitle.length > 19
                              ? eventItem.eventTitle.slice(0, 19) + "..."
                              : eventItem.eventTitle}
                          </h3>
                          <span>
                            €
                            {eventItem.earlyBirdTicket > 0
                              ? parseFloat(
                                  // eventItem.earlyBirdTicketPrice
                                  eventItem.newEarlyBirdPrice
                                ).toFixed(2)
                              : parseFloat(
                                // eventItem.price
                                eventItem.newPrice
                                ).toFixed(2)}
                          </span>
                        </div>
                        <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ? 
                              (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                        {eventItem.earlyBirdTicket > 0 && (
                          <div className="ticket">
                            Earlybird Tickets Available
                            <span>{eventItem.earlyBirdTicket}</span>
                          </div>
                        )}
                        <div className="ticket">
                          Tickets Available
                          <span>{eventItem.availableTickets}</span>
                        </div>
                        <div className="date-time">
                          {getEventLocalDate(
                            eventItem.eventDate,
                            eventItem.eventTime,
                            eventItem.setTimeZone
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div>No Events Present</div>
          )}
        </div>
        {loading && <Loader />}
      </div>
    </>
  );
}

export default ViewAll;
