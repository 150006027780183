import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
// import IconCall from "../../assets/img/icon-call.svg";
import IconAddress from "../../assets/img/icon-adderss.svg";
import IconEmail from "../../assets/img/icon-email.svg";
import { Notify } from "../../helpers/notifications";
import { Validation } from "../../helpers/validations";
import { ContactUsApi } from "../../redux/actions/user";

function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    document.title = "LiveStage | Contact Us";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const newValidation = () => {
    let error = {};

    // firstname
    if (Validation.empty(firstName)) {
      error = { ...error, firstName: "First Name is required" };
    } else if (!Validation.name(firstName)) {
      error = { ...error, firstName: "Min 2 and Max 50 characters allowed" };
    }

    // lastname
    if (Validation.empty(lastName)) {
      error = { ...error, lastName: "Last Name is required" };
    } else if (!Validation.name(lastName)) {
      error = { ...error, lastName: "Min 2 and Max 50 characters allowed" };
    }

    // email
    if (Validation.empty(email)) {
      error = { ...error, email: "Email is required" };
    } else if (!Validation.email(email)) {
      error = {
        ...error,
        email: "Please enter the email in a valid format",
      };
    } else if (!Validation.emailLength(email)) {
      error = { ...error, email: "Max 100 characters allowed" };
    }

    // phone
    if (Validation.empty(phone)) {
      error = { ...error, phone: "Phone number is required" };
    } else if (phone.length < 7) {
      error = {
        ...error,
        phone: "Please enter a valid phone number",
      };
    }

    if (Validation.empty(description)) {
      error = { ...error, description: "Message Description is required" };
    }

    console.log(error);
    if (Object.keys(error).length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  // Validator function
  const handleValidation = (type) => {
    if (type === "firstName") {
      if (Validation.empty(firstName)) {
        setError({ ...error, firstName: "First Name is required" });
        return false;
      } else if (!Validation.name(firstName)) {
        setError({
          ...error,
          firstName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "lastName") {
      if (Validation.empty(lastName)) {
        setError({ ...error, lastName: "Last Name is required" });
        return false;
      } else if (!Validation.name(lastName)) {
        setError({ ...error, lastName: "Min 2 and Max 50 characters allowed" });
        return false;
      }
    } else if (type === "email") {
      if (Validation.empty(email)) {
        setError({ ...error, email: "Email is required" });
        return false;
      } else if (!Validation.email(email)) {
        setError({
          ...error,
          email: "Please enter the email in a valid format",
        });
        return false;
      } else if (!Validation.emailLength(email)) {
        setError({ ...error, email: "Max 100 characters allowed" });
        return false;
      }
    } else if (type === "phone") {
      if (Validation.empty(phone)) {
        setError({ ...error, phone: "Phone number is required" });
        return false;
      } else if (phone.length < 7) {
        setError({
          ...error,
          phone: "Please enter a valid phone number",
        });
        return false;
      }
    } else if (type === "description") {
      if (Validation.empty(description)) {
        setError({ ...error, description: "Message Description is required" });
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newValidation()) {
      let payload = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phone: "+" + phone,
        content: description,
      };

      console.log(payload);

      try {
        let response = await ContactUsApi(payload);
        if (response && response.status === 200) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setDescription("");
          Notify("success", "Information sent successfully!!");

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      } catch (error) {
        console.log(error);
        Notify("danger", `Error: ${error.response.data.message}`);
      }
    }
  };

  return (
    <div>
      <section className="user-section">
        <div className="flex-container ">
          <div className="grey-wrap register-wrap">
            <div className="center-box">
              <div className="title">
                <h1>Contact Us Now</h1>
              </div>

              <form className="form-signup">
                <div className="flex-row gutter-space">
                  <div className="flex-col-2 change-space">
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        onFocus={(e) => setError({ ...error, firstName: "" })}
                        onBlur={(e) => handleValidation("firstName")}
                      />
                      {error && (
                        <span style={{ color: "red" }}>{error?.firstName}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex-col-2 change-space">
                    <div className="field-wrap">
                      <input
                        type="text"
                        name=""
                        required=""
                        value={lastName}
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        onFocus={(e) => setError({ ...error, lastName: "" })}
                        onBlur={(e) => handleValidation("lastName")}
                      />
                      {error && (
                        <span style={{ color: "red" }}>{error?.lastName}</span>
                      )}
                    </div>
                  </div>

                  <div className="flex-col-2 change-space">
                    <div className="field-wrap">
                      <input
                        type="email"
                        name=""
                        required=""
                        value={email}
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={(e) => setError({ ...error, email: "" })}
                        onBlur={(e) => handleValidation("email")}
                      />
                      {error && (
                        <span style={{ color: "red" }}>{error?.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex-col-2 change-space">
                    <div className="field-wrap">
                      <div className="phonenumber">
                        <PhoneInput
                          placeholder="Phone Number"
                          value={phone}
                          country={"us"}
                          onChange={(
                            phonenumber,
                            value,
                            country,
                            event,
                            formattedValue
                          ) => {
                            console.log(value, event, phonenumber);

                            if (value.dialCode) {
                              if (phone) {
                                setPhone(phonenumber);
                              } else {
                                setPhone(`${value.dialCode}${phone}`);
                              }
                            } else {
                              setError({
                                ...error,
                                phone: "Please select dial code first",
                              });
                            }
                          }}
                          onFocus={(e) => setError({ ...error, phone: "" })}
                          onBlur={(e) => handleValidation("phone")}
                        />
                      </div>
                      {error && (
                        <span style={{ color: "red" }}>{error?.phone}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field-wrap border-change change-space">
                  <textarea
                    className="reason-deactivate fullWidth"
                    rows="7"
                    cols="50"
                    value={description}
                    placeholder="Message Description"
                    onChange={(e) => setDescription(e.target.value)}
                    onFocus={(e) => setError({ ...error, description: "" })}
                    onBlur={(e) => handleValidation("description")}
                  ></textarea>
                  {error && (
                    <span style={{ color: "red" }}>{error?.description}</span>
                  )}
                </div>

                <div className="btn-wrap text-center">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div className="contact-icons-wrapper">
                <div className="contact-icon-row">
                  {/* <div className="contact-icon-col">
                    <img
                      src={IconCall}
                      style={{ width: "20px" }}
                    />
                    <span>Company Number</span>
                  </div> */}
                  <div className="contact-icon-col">
                    {" "}
                    <img src={IconEmail} style={{ width: "25px" }} />
                    <span>desk@livestage.stream</span>
                  </div>
                  <div className="contact-icon-col">
                    <img src={IconAddress} style={{ width: "25px" }} />
                    <span>19A Nafpaktou street, 3051, Limassol, Cyprus</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
