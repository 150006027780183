import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import { GetPaymentMethods } from "../../redux/actions/user";

function Payments() {
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    GetPaymentMethods()
      .then((response) => {
        // console.log(response.data.data);
        setPaymentMethods([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div id="app" className="page-home">
        <header className="app-header">
          <div className="flex-container">
            <div className="flex-header">
              <div className="logo">
                <a>
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <section className="myaccount-section">
          <div className="flex-container ">
            <div className="myaccount enuser-myaccount">
              <div className="top-heading">
                <h1>Complete Payment</h1>
              </div>
            </div>
            {paymentMethods.map((method, index) => (
              <div className="form-wrap" key={index}>
                <div className="form-bankingInfo">
                  <div className="field-wrap">
                    <div className="input-pay-wrapper">
                      <div className="forgotlink">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="defaultCard"
                            value={index}
                            id={index}
                            // onChange={(e) =>
                            //   handleSetDefaultCard(e.target.value, method._id)
                            // }
                            checked={method.isDefault}
                          />
                          <span className="checkmark checkmark-pay"></span>
                          <span className="space-lhs">
                            <span> <b>{method.brand}</b></span> <span style={{fontSize: "16px", marginLeft: "20px"}}>{` XXXX XXXX XXXX ${method.lastDigit}`}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Payments;
