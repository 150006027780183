const initialState = {
  eventFile: "",
  videoStatus: false,
  showActorFile: false
};

const EVENT_FILE = (state = initialState, { type, payload }) => {
  switch (type) {
    case "EVENTPREVIEWFILE": {
      return {
        ...state,
        eventFile: payload.eventFile,
        videoStatus: payload.videoStatus,
      };
    }
    case "ACTOREVENTFILE": {
      return {
        ...state,
        showActorFile: payload.showActorFile ? payload.showActorFile : false
      }
    }
    case "EMPTY_FILE": {
      return initialState;
    }
    default:
      return state;
  }
};

export default EVENT_FILE;