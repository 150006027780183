import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import iconMute from "../../assets/img/icon-mute.png";
import iconUnMute from "../../assets/img/icon-unmute.png";
import IconLike from "../../assets/img/icon-like.svg";
import iconPlay from "../../assets/img/play-button.png";
import dpPhoto from "../../assets/img/img_avatar.png";
import iconCal from "../../assets/img/icon-cal.svg";
import iconWatch from "../../assets/img/icon-watch.svg";
import iconCard from "../../assets/img/icon-card.svg";
import {
  GetGuestEventData,
  GetGuestEventDataActorName,
} from "../../redux/actions/events";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { getEventLocalDate } from "../../helpers/constants";
import Logo from "../loader/Loader";

function ShowEvent() {
  const history = useHistory();

  const [videoMute, setVideoMute] = useState(true);
  const [defaultData, setDefaultData] = useState({});
  const [eventListData, setEventListData] = useState([]);
  const [eventIdChange, setEventIdChange] = useState("");
  const [eventNameChange, setEventNameChange] = useState("");
  const [actorNameChange, setActorNameChange] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [dataStatus, setDataStatus] = useState("");

  const params = useParams();
  console.log(params);

  useEffect(() => {
    setShowLoader(true);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (params.eventID) {
      GetGuestEventData(params.eventID)
        .then((response) => {
          // console.log(response.data);
          document.title = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[name="description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector(
            'meta[property="og:title"]'
          ).content = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[property="og:description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector('meta[property="og:image"]').content = `${
            response.data.default.videoStatus
              ? response.data.default.thumbnailImage
              : response.data.default.eventImage
          }`;
          document.head.querySelector(
            'meta[name="twitter:title"]'
          ).content = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[name="twitter:description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector(
            'meta[name="twitter:image"]'
          ).content = `${
            response.data.default.videoStatus
              ? response.data.default.thumbnailImage
              : response.data.default.eventImage
          }`;

          setDataStatus(response.data.status);
          setDefaultData(response.data.default);
          setEventListData(response.data.data);
          setShowLoader(false);
        })
        .catch((error) => {
          // console.log(error);
          setDataStatus(error.response.status);
          setShowLoader(false);
        });
    } else {
      GetGuestEventDataActorName({
        actorName: params.actorName,
        eventName: params.eventName,
      })
        .then((response) => {
          // console.log(response);
          document.title = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[name="description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector(
            'meta[property="og:title"]'
          ).content = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[property="og:description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector('meta[property="og:image"]').content = `${
            response.data.default.videoStatus
              ? response.data.default.thumbnailImage
              : response.data.default.eventImage
          }`;
          document.head.querySelector(
            'meta[name="twitter:title"]'
          ).content = `LiveStage | ${response.data.default.eventTitle}`;
          document.head.querySelector(
            'meta[name="twitter:description"]'
          ).content = `${response.data.default.description}`;
          document.head.querySelector(
            'meta[name="twitter:image"]'
          ).content = `${
            response.data.default.videoStatus
              ? response.data.default.thumbnailImage
              : response.data.default.eventImage
          }`;

          setDataStatus(response.data.status);
          setDefaultData(response.data.default);
          setEventListData(response.data.data);
          setShowLoader(false);
        })
        .catch((error) => {
          // console.log(error.response);
          setDataStatus(error.response.status);
          setShowLoader(false);
        });
    }
  }, [eventNameChange, eventIdChange]);

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  return (
    <div>
      {showLoader ? (
        <div>
          <Logo />
        </div>
      ) : dataStatus === 200 ? (
        <>
          <div
            id="app"
            className="for-overlay page-home event-back"
            style={{
              backgroundImage: `url(${
                !defaultData?.videoStatus && defaultData?.eventImage
              })`,
              // backgroundSize: "100% 100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // height: "100vh",
              height: "750px",
              width: "100%",
              backgroundPosition: "center",
            }}
          >
            {defaultData.videoStatus && (
              <div className="bg_video">
                <div
                  className="cursorPointer video-mute-div mIcon"
                  onClick={() => handleVideoMute()}
                >
                  {videoMute ? (
                    <img src={iconMute} />
                  ) : (
                    <img src={iconUnMute} />
                  )}
                </div>
                <video
                  // minHeight="480"
                  // height="480"
                  width="100%"
                  // controls
                  autoPlay={true}
                  loop={true}
                  muted={videoMute}
                >
                  <source src={defaultData?.eventImage} />
                </video>
              </div>
            )}
            <div className="layer"></div>
            <header
              className="app-header"
              style={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <div className="flex-container">
                <div className="flex-header">
                  <div className="logo">
                    <a className="cursorPointer" href="/login">
                      <img src={logo} alt="logo" />
                    </a>
                  </div>

                  <div className="account-links">
                    <button
                      className="primary-button signin"
                      onClick={() => history.push("/login")}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </header>

            <section
              className="events-section enduser-section"
              style={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <div className="flex-container">
                <div className="event-details">
                  <h1>{defaultData.eventTitle}</h1>
                  <div className="content">
                    <div className="date-time">
                      <div className="flex-row">
                        <div>
                          <img src={iconCal} alt="User" />
                          {
                            getEventLocalDate(
                              defaultData.eventDate,
                              defaultData.eventTime,
                              defaultData.setTimeZone
                            ).split(",")[0]
                          }
                        </div>
                        <div>
                          <img src={iconWatch} alt="User" />
                          {
                            getEventLocalDate(
                              defaultData.eventDate,
                              defaultData.eventTime,
                              defaultData.setTimeZone
                            ).split(",")[1]
                          }
                        </div>
                      </div>
                      <div className="flex-row">
                        <div>Event ID: #{defaultData.randomEventId}</div>
                        <div>
                          <img src={iconCard} alt="User" /> €{" "}
                          {defaultData.earlyBirdTicket > 0
                            ? parseFloat(
                                // defaultData.earlyBirdTicketPrice
                                defaultData.newEarlyBirdPrice
                              ).toFixed(2)
                            : parseFloat(
                                // defaultData.price
                                defaultData.newPrice
                              ).toFixed(2)}
                        </div>
                      </div>

                      <div className="flex-row">
                        {defaultData?.earlyBirdTicket > 0 && (
                          <div>
                            EarlyBird Tickets Available:{" "}
                            {defaultData?.earlyBirdTicket}
                          </div>
                        )}
                        <div>
                          Tickets Available :{" "}
                          {defaultData.ticketType === "Unlimited" ||
                          defaultData.audienceType === "Corporate"
                            ? "Available"
                            : defaultData?.availableTickets}
                        </div>
                      </div>
                    </div>

                    <p className="cat">Category: {defaultData.eventCategory}</p>
                    <p className="desc">
                      <strong>Event Description:</strong>
                    </p>
                    <div className="description-scroll">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {defaultData?.description}
                      </p>
                    </div>
                  </div>
                  <button
                    className="primary-button"
                    type="button"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Book Now
                  </button>

                  <div className="banner-wrap">
                    <div
                      className="content banner-content-box"
                      style={{ width: "100%" }}
                    >
                      <div className="enduser-artist">
                        <h4>Artist</h4>
                        <div className="row-content">
                          <div
                            style={{
                              height: "200px",
                              width: "65%",
                              backgroundImage: `url(${
                                defaultData?.userId?.profilePhoto
                                  ? defaultData.userId?.profilePhoto
                                  : dpPhoto
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          ></div>
                          <div className="content">
                            <h5>
                              {defaultData?.userId?.firstName}{" "}
                              {defaultData?.userId?.lastName}
                            </h5>
                            <h6>{defaultData?.userId?.category}</h6>
                            {defaultData?.userId?.rateCount > 0 && (
                              <div className="rating">
                                <span
                                  className="like-con-book"
                                  style={{
                                    marginBottom: "0px",
                                    cursor: "auto",
                                  }}
                                >
                                  <img src={IconLike} alt="" />
                                  {defaultData.artistRating} %
                                </span>
                              </div>
                            )}
                            <div className="description-scroll">
                              <p
                                className="p-box"
                                style={{
                                  maxWidth: "500px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {defaultData?.userId?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="banner-wrap">
                <div className="content banner-content-box">
                  <h1>{defaultData.eventTitle}</h1>
                  <div className="date-time">
                    <div className="flex-row">
                      <div>
                        <img src={iconCal} alt="User" />{" "}
                        {
                          getEventLocalDate(
                            defaultData.eventDate,
                            defaultData.eventTime,
                            defaultData.setTimeZone
                          ).split(",")[0]
                        }
                      </div>
                      <div>
                        <img src={iconWatch} alt="User" />{" "}
                        {
                          getEventLocalDate(
                            defaultData.eventDate,
                            defaultData.eventTime,
                            defaultData.setTimeZone
                          ).split(",")[1]
                        }
                      </div>
                    </div>
                    <div className="flex-row">
                      <div>Event ID: #{defaultData.randomEventId}</div>
                      <div>
                        <img src={iconCard} alt="User" /> €{" "}
                        {defaultData.earlyBirdTicket > 0
                          ? parseFloat(
                              defaultData.earlyBirdTicketPrice
                            ).toFixed(2)
                          : parseFloat(defaultData.price).toFixed(2)}
                      </div>
                    </div>

                    <div className="flex-row">
                      <div>
                        EarlyBird Tickets Available:{" "}
                        {defaultData?.earlyBirdTicket}
                      </div>
                      <div>
                        Tickets Available :{" "}
                        {defaultData.ticketType === "Unlimited" ||
                        defaultData.audienceType === "Corporate"
                          ? "Available"
                          : defaultData?.availableTickets}
                      </div>
                    </div>
                  </div>
                  <p className="cat">Category: {defaultData.eventCategory}</p>
                  <p className="desc">
                    <strong>Event Description:</strong>
                  </p>
                  <div className="description-scroll">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {defaultData?.description}
                    </p>
                  </div>
                  <button
                    className="primary-button"
                    type="button"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Book Now
                  </button>
                </div>
                <div className="outer-wrappper-1">
                  <div className="preview-img-wrap">
                    <div
                      className="preview-con"
                      style={{ position: "relative" }}
                    >
                      {defaultData.videoStatus ? (
                        <>
                          <div
                            className="cursorPointer video-mute-div"
                            onClick={() => handleVideoMute()}
                          >
                            {videoMute ? (
                              <img src={iconMute} />
                            ) : (
                              <img src={iconUnMute} />
                            )}
                          </div>
                          <video
                            // minHeight="480"
                            height="480"
                            width="100%"
                            // controls
                            autoPlay={true}
                            loop={true}
                            muted={videoMute}
                          >
                            <source src={defaultData?.eventImage} />
                          </video>
                        </>
                      ) : (
                        <div
                          className="eventImage"
                          style={{
                            // paddingTop: "0px",
                            minHeight: "480px",
                            //   width: "100%",
                            backgroundImage: `url(${
                              defaultData?.videoStatus
                                ? defaultData?.thumbnailImage
                                : defaultData?.eventImage
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-wrap">
                <div
                  className="content banner-content-box"
                  style={{ width: "100%" }}
                >
                  <div className="enduser-artist" style={{ marginTop: "0px" }}>
                    <h4>Artist</h4>
                    <div className="row-content">
                      <div
                        // className="eventImage"
                        style={{
                          // paddingTop: "0px",
                          height: "200px",
                          width: "65%",
                          backgroundImage: `url(${
                            defaultData?.userId?.profilePhoto
                              ? defaultData.userId?.profilePhoto
                              : dpPhoto
                          })`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div className="content">
                        <h5>
                          {defaultData?.userId?.firstName}{" "}
                          {defaultData?.userId?.lastName}
                        </h5>
                        <h6>{defaultData?.userId?.category}</h6>
                        <div className="rating">
                          <span
                            className="like-con-book"
                            style={{ marginBottom: "0px", cursor: "auto" }}
                          >
                            <img src={IconLike} alt="" />
                            {defaultData.artistRating} %
                          </span>
                        </div>
                        <div className="description-scroll">
                          <p
                            className="p-box"
                            style={{
                              maxWidth: "500px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {defaultData?.userId?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>

                <div className="events-listing enduser-events">
                  <div className="title">
                    <h2>All Events</h2>
                  </div>
                  <div className="flex-row gutter-space">
                    {eventListData.length > 0 ? (
                      eventListData.map((eventItem, index) => (
                        <div
                          className="loop flex-col-4"
                          key={index}
                          // ref={loadMore}
                        >
                          <div
                            className="grey-wrap item cursorPointer"
                            onClick={() => {
                              setEventIdChange(eventItem._id);
                              setEventNameChange(eventItem.newEventName);
                              setActorNameChange(eventItem.creatorNewName);
                              if (params.eventID) {
                                history.push(
                                  `/public/showEvent/${eventItem._id}`
                                );
                              } else {
                                history.push(
                                  `/public/showEvent/${eventItem.creatorNewName}/${eventItem.newEventName}`
                                );
                              }
                            }}
                          >
                            <div className="img">
                              <div
                                onMouseOver={(e) => {
                                  console.log("Print $$$$$###",eventItem);
                                  e.target.style.backgroundImage = `url(${
                                    eventItem.videoStatus
                                    ? eventItem.gifURL
                                      : eventItem.eventImage
                                  })`;
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.backgroundImage = `url(${
                                    eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                  })`;
                                }}
                                style={{
                                  minHeight: "150px",
                                  width: "100%",
                                  backgroundImage: `url(${
                                    eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              {eventItem.videoStatus && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "155px",
                                  }}
                                >
                                  <img
                                    src={iconPlay}
                                    style={{ width: "40px" }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* <div className="img">
                             <div
                                onMouseOver={(e) => {
                                  e.target.style.backgroundImage = `url(${
                                    eventItem.videoStatus
                                      ? eventItem.gifURL
                                      : eventItem.eventImage
                                  })`;
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.backgroundImage = `url(${
                                    eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                  })`;
                                }}
                                style={{
                                  minHeight: "150px",
                                  width: "100%",
                                  backgroundImage: `url(${
                                    eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              {eventItem.videoStatus && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "155px",
                                  }}
                                >
                                  <img
                                    src={iconPlay}
                                    style={{ width: "40px" }}
                                  />
                                </div>
                              )}
                            </div>
                            */}
                            <div className="details">
                              <div className="name">
                                <h3 title={eventItem.eventTitle}>
                                  {eventItem.eventTitle.length > 19
                                    ? eventItem.eventTitle.slice(0, 19) + "..."
                                    : eventItem.eventTitle}
                                </h3>
                                <span>
                                  €
                                  {eventItem.earlyBirdTicket > 0
                                    ? parseFloat(
                                        // eventItem.earlyBirdTicketPrice
                                        eventItem.newEarlyBirdPrice
                                      ).toFixed(2)
                                    : parseFloat(
                                        // eventItem.price
                                        eventItem.newPrice
                                      ).toFixed(2)}
                                </span>
                              </div>
                              <div className="ticket">
                                {`${eventItem.userId.firstName} ${eventItem.userId.lastName}`
                                  .length > 28
                                  ? `${eventItem.userId.firstName} ${eventItem.userId.lastName}`.slice(
                                      0,
                                      28
                                    ) + "..."
                                  : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}
                              </div>
                              {eventItem.earlyBirdTicket > 0 && (
                                <div className="ticket">
                                  Earlybird Tickets Available
                                  <span>{eventItem.earlyBirdTicket}</span>
                                </div>
                              )}
                              <div className="ticket">
                                Tickets Available
                                <span>
                                  {eventItem.ticketType === "Unlimited" ||
                                  eventItem.audienceType === "Corporate"
                                    ? "Available"
                                    : eventItem?.availableTickets}
                                </span>
                              </div>
                              <div className="date-time">
                                {getEventLocalDate(
                                  eventItem.eventDate,
                                  eventItem.eventTime,
                                  eventItem.setTimeZone
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No Events Found</div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <footer className="app-footer">
              <div className="flex-container">
                <div className="footer-content">
                  {/* <div className="question">Questions? Call 415-784-458</div> */}
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/termsOfUse">Terms of Use</a>
                      </li>
                      <li>
                        <a href="/cookiePreference">Cookie Policy</a>
                      </li>
                      {/* <li>
                        <a href="/helpCentre">Help Centre</a>
                      </li> */}
                      <li>
                        <a href="/privacy">Privacy</a>
                      </li>
                      {/* <li>
                        <a href="/eventInformation">Event Information</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="copyright">
                  Copyright 2020. All Rights Reserved Live Stage
                </div>
              </div>
            </footer>
          </div>
        </>
      ) : (
        <>
          <div id="app" className="for-overlay page-home event-back">
            <header
              className="app-header"
              style={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <div className="flex-container">
                <div className="flex-header">
                  <div className="logo">
                    <a className="cursorPointer" href="/login">
                      <img src={logo} alt="logo" />
                    </a>
                  </div>

                  <div className="account-links">
                    <button
                      className="primary-button signin"
                      onClick={() => history.push("/login")}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </header>
            <section
              className="events-section enduser-section"
              style={{
                position: "relative",
                zIndex: 2,
                height: "50vh",
              }}
            >
              <div className="flex-container">
                <div className="event-details"></div>
              </div>
              <h1 style={{ textAlign: "center" }}>No Data Found</h1>
            </section>

            <footer className="app-footer">
              <div className="flex-container">
                <div className="footer-content">
                  {/* <div className="question">Questions? Call 415-784-458</div> */}
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/termsOfUse">Terms of Use</a>
                      </li>
                      <li>
                        <a href="/cookiePreference">Cookie Policy</a>
                      </li>
                      {/* <li>
                        <a href="/helpCentre">Help Centre</a>
                      </li> */}
                      <li>
                        <a href="/privacy">Privacy</a>
                      </li>
                      {/* <li>
                        <a href="/eventInformation">Event Information</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="copyright">
                  Copyright 2020. All Rights Reserved Live Stage
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </div>
  );
}

export default ShowEvent;
