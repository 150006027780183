import React from "react";
import accounInfo from "../../assets/img/accoun-info.svg";
import { useHistory } from "react-router";

function ForgotPasswordSuccess() {
  const history = useHistory();
  return (
    <div className="grey-wrap bank-wrap">
      <div className="login-box">
        <img src={accounInfo} alt="img" />
        <div className="title">
          <h1>Thank You</h1>
          <p className="mt-15">
            A Reset Password link has been sent to your email. Please make sure you also check your spam/junk folder and proceed to change your password.
          </p>
        </div>
        <div className="btn-wrap">
          <button type="button" className="primary-button" onClick={() => history.push('/login')}>
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordSuccess;
