import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import resetSVG from "../../assets/img/reset.svg";
import eye from "../../assets/img/eye.svg";
import eyeopen from "../../assets/img/eyeopen.svg";
import ResetSuccess from "./resetSuccess";
import { Validation } from "../../helpers/validations";
import jwt_decode from "jwt-decode";
import { ResetPass } from "../../redux/actions/user";
import { useParams } from "react-router-dom";
import { Notify } from "../../helpers/notifications";

function ResetPassword({ history }) {
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState({});
  // const [showMaintenance ,setShowMaintenance] = useState(false);


  const params = useParams();

  var decoded = jwt_decode(params.token);
  // console.log(decoded);

  useEffect(() => {
    document.title = "LiveStage | ResetPassword"
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])

  // Submit New Password Handler
  const handleReset = async (e) => {
    let fields = ["newPassword", "confirmPassword"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    let payload = {
      email: decoded?.email.trim(), //from Params from decoded token
      newpassword: newPass,
    };
    // console.log(payload);

    try {
      const response = await ResetPass(payload);
      // console.log(response)

      if (response && response.status === 200) {
        setShowSuccess(true);

        Notify("success", "Your password has been reset successfully! Now login with your new password.");
      }
    } catch (error) {
      console.log(error.response.data.message);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Password Validator function
  const handleValidation = (type) => {
    if (type === "newPassword") {
      if (Validation.empty(newPass)) {
        setError({ ...error, newPass: "New Password is required" });
        return false;
      } else if (!Validation.password(newPass)) {
        setError({
          ...error,
          newPass:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        });
        return false;
      }
    } else if (type === "confirmPassword") {
      if (Validation.empty(confirmPass)) {
        setError({ ...error, confirmPass: "Confirm Password is required" });
        return false;
      } else if (newPass !== confirmPass) {
        setError({ ...error, confirmPass: "Passwords do not match" });
        return false;
      }
    }
    return true;
  };

  return (
    <div id="app" className="page-login">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="user-section">
        <div className="flex-container">
          {showSuccess ? (
            <ResetSuccess history={history} />
          ) : (
            <div className="grey-wrap reset-wrap">
              <div className="flex-row flex-centered">
                <div className="flex-col-2">
                  <div className="img side-line">
                    <img src={resetSVG} alt="Hero" />
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="login-box reset-box">
                    <div className="title">
                      <h1>Reset Password</h1>
                      <p>
                        We will send you a reset password link to the provided
                        email address
                      </p>
                    </div>
                    <form>
                      <div className="field-wrap">
                        <input
                          type={showPass ? "text" : "password"}
                          name=""
                          required=""
                          placeholder="New Password*"
                          value={newPass}
                          onChange={(e) => setNewPass(e.target.value)}
                          onFocus={(e) => setError({ ...error, newPass: "" })}
                          onBlur={(e) => handleValidation("newPassword")}
                        />
                        <a
                          className="icon-viewpswd"
                          onClick={() => setShowPass(!showPass)}
                        >
                          <img src={showPass ? eyeopen : eye} alt="img" />
                        </a>
                        {error && (
                          <span style={{ color: "red" }}>{error?.newPass}</span>
                        )}
                      </div>
                      <div className="field-wrap">
                        <input
                          type={showConfirmPass ? "text" : "password"}
                          name=""
                          required=""
                          placeholder="Confirm your password*"
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                          onFocus={(e) =>
                            setError({ ...error, confirmPass: "" })
                          }
                          onBlur={(e) => handleValidation("confirmPassword")}
                        />
                        <a
                          className="icon-viewpswd"
                          onClick={() => setShowConfirmPass(!showConfirmPass)}
                        >
                          <img
                            src={showConfirmPass ? eyeopen : eye}
                            alt="img"
                          />
                        </a>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.confirmPass}
                          </span>
                        )}
                      </div>
                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="primary-button"
                           onClick={(e) => handleReset(e)}
                          // onClick={()=>setShowMaintenance(true)}
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

 {/* POP UP Message */}
 {/* {showMaintenance ? (
          <div
            className="overlay-box video-confirm-pop"
            style={{ maxWidth: "800px" }}
          >
            <h3>
              LiveStage is improving. We will be back on the 21/07/2022.
              <br />
              For any inquiries please contact us at{" "}
              <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
            </h3>
            <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="primary-button"
                onClick={() => setShowMaintenance(false)}
              >
                Back
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`overlay fix-opaicty ${showMaintenance && "show"}`}
        ></div> */}
{/* ============== */}

    </div>
  );
}

export default ResetPassword;
