const initialState = {
  userData: {},
  myAccountData: {},
  isAuth: false
};

const USER_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN": {
      console.log(payload)
      return {
        ...state,
        userData: payload,
        isAuth: true
      };
    }
    case "MYACCOUNT": {
      return {
        ...state,
        myAccountData: payload
      }
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};

export default USER_REDUCER;
