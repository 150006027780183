import React, { useEffect, useRef, useState } from "react";
import { OTSession, OTPublisher, preloadScript } from "opentok-react";
import { PreCallTest } from "../../redux/actions/events";

function PreCallTestVid({ eventId, videoSource, videoDevices }) {
  const [audio, setAudio] = useState(false);
  const [video, setVideo] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState("");

  const sessionRef = useRef();
  const publisherRef = useRef();

  useEffect(() => {
    if (eventId === localStorage.getItem("testEventId")) {
      setApiKey(localStorage.getItem("testApiKey"));
      setSessionId(localStorage.getItem("testSessionId"));
      setToken(localStorage.getItem("testToken"));
    } else {
      PreCallTest()
        .then((response) => {
          console.log(response);
          setApiKey(response.data.data.apiKey);
          setSessionId(response.data.data.sessionId);
          setToken(response.data.data.sessionToken);
          localStorage.setItem("testEventId", eventId);
          localStorage.setItem("testApiKey", response.data.data.apiKey);
          localStorage.setItem("testSessionId", response.data.data.sessionId);
          localStorage.setItem("testToken", response.data.data.sessionToken);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  // useEffect(() => {
  //   console.log("pre-call", sessionRef);
  //   console.log("pre-call", publisherRef);
  // }, [sessionRef, publisherRef]);

  const SessionOnConnect = () => {
    console.log("SessionOnConnect ==>>  ", sessionRef, publisherRef);
  };

  const SessionOnError = (error) => {
    console.log("SessionOnError ==>>  ", error);
    localStorage.removeItem("testEventId");
    localStorage.removeItem("testApiKey");
    localStorage.removeItem("testSessionId");
    localStorage.removeItem("testToken");
  };

  const sessionEventHandlers = {
    signal: (event) => {
      console.log(event);
    },
    connectionCreated: (data) => {},
    connectionDestroyed: (data) => {},
  };

  const publisherEventHandlers = {
    streamCreated: (event) => {
      console.log("Publisher video test stream created!", event);
    },
    streamDestroyed: (event) => {
      console.log("Publisher video test stream destroyed!", event);
    },
  };

  return (
    <div className="precall-live-video">
      {apiKey && sessionId && token && (
        <OTSession
          apiKey={apiKey}
          sessionId={sessionId}
          token={token}
          onConnect={() => SessionOnConnect()}
          onError={(error) => SessionOnError(error)}
          eventHandlers={sessionEventHandlers}
          ref={sessionRef}
        >
          <OTPublisher
            eventHandlers={publisherEventHandlers}
            properties={{
              resolution: "1920x1080",
              frameRate: 30,
              maxResolution: { width: 1920, height: 1080 },
              publishAudio: audio,
              publishVideo: video,
              fitMode: "cover",
              style: {
                audioLevelDisplayMode: "off",
                buttonDisplayMode: "off",
              },
              audioSource: false,
              videoSource: videoSource
                ? videoSource
                : videoDevices[0]?.deviceId,
            }}
            ref={publisherRef}
          />
        </OTSession>
      )}
    </div>
  );
}

export default preloadScript(PreCallTestVid);
