import React, { useEffect, useState } from "react";
import { Notify } from "../../helpers/notifications";
import { Validation } from "../../helpers/validations";
import { GetBankDetails, UpdateBankDetails } from "../../redux/actions/user";

function BankingInfo({ setTab, setSelectedTab }) {
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [code, setCode] = useState();
  const [acHolderName, setAcHolderName] = useState();
  const [bankError, setBankError] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])

  useEffect(() => {
    // API to fetch actor Bank Details
    GetBankDetails()
      .then((response) => {
        // console.log(response.data.data);
        setBankName(response.data.data[0].bankName);
        setAccountNumber(response.data.data[0].accountNumber);
        setCode(response.data.data[0].code);
        setAcHolderName(response.data.data[0].accountHolderName);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Bank Details Registration for Actor
  const handleBankDetails = async (e) => {
    let fields = ["bankName", "accountNumber", "code", "acHolderName"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleBankValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    let payload = {
      bankName: bankName,
      accountNumber: accountNumber,
      code: code,
      // iban: iban
      accountHolderName: acHolderName
    };

    // console.log(payload);

    try {
      let response = await UpdateBankDetails(payload);
      if (response && response.status === 200) {
        Notify("success", "Your Bank Details have been successfully updated.");
        setTab(1);
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Validator function for Bank Details
  const handleBankValidation = (type) => {
    if (type === "bankName") {
      if (Validation.empty(bankName)) {
        setBankError({ ...bankError, bankName: "Bank Name is required" });
        return false;
      } else if (!Validation.name(bankName)) {
        setBankError({
          ...bankError,
          bankName: "Min 2 & Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "accountNumber") {
      if (Validation.empty(accountNumber)) {
        setBankError({ ...bankError, accountNumber: "IBAN / Account Number is required" });
        return false;
      }
      // else if (!Validation.number(accountNumber)) {
      //   setBankError({ ...bankError, accountNumber: "Should be a number" });
      //   return false;
      // }
      // else if (accountNumber.length < 9 || accountNumber.length > 18) {
      else if (accountNumber.length < 6 || accountNumber.length > 34) {
        setBankError({
          ...bankError,
          // accountNumber: "Min 9 & Max 18 digits allowed",
          accountNumber: "Min 6 & Max 34 aphanumeric characters allowed",
        });
        return false;
      }
    } else if (type === "code") {
      if (Validation.empty(code)) {
        setBankError({ ...bankError, code: "Swift code / sort code is required" });
        return false;
      } else if (code.length < 8 || code.length > 11) {
        setBankError({
          ...bankError,
          code: "Min 8 & Max 11 alphanumeric characters allowed",
        });
        return false;
      }
    } else if (type === "acHolderName") {
      if (Validation.empty(acHolderName)) {
        setBankError({ ...bankError, acHolderName: "Account Holder's Name is required" });
        return false;
      } else if (!Validation.name(acHolderName)) {
        setBankError({
          ...bankError,
          acHolderName: "Min 2 & Max 50 characters allowed",
        });
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <section className="myaccount-section">
        <div className="flex-container ">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a className="cursorPointer" onClick={() => setSelectedTab(1)}>
                  Home
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>
                <a className="cursorPointer" onClick={() => setTab(1)}>
                  My Account
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>My Banking Information</li>
            </ul>
          </div>
          <div className="myaccount banking-information">
            <div className="top-heading">
              <h1>My Banking Information</h1>
            </div>
            <div className="form-wrap">
              <form className="form-bankingInfo">
                <div className="field-wrap">
                  <label>Bank Account Information</label>
                  <input
                    type="text"
                    name=""
                    required=""
                    placeholder="Bank Name"
                    value={bankName}
                    onChange={(e) => {
                      // setBankName(e.target.value.replace(/[0-9]/g,""));
                      setBankName(e.target.value);
                    }}
                    onFocus={(e) =>
                      setBankError({ ...bankError, bankName: "" })
                    }
                    onBlur={(e) => handleBankValidation("bankName")}
                  />
                  {bankError && (
                    <span style={{ color: "red" }}>{bankError?.bankName}</span>
                  )}
                  <input
                    type="text"
                    name=""
                    required=""
                    placeholder="Account Holder's Name"
                    value={acHolderName}
                    // maxLength="50"
                    onChange={(e) =>{
                      // setIban(e.target.value.replace(/[^A-Z0-9]/g, ""))
                      // setAcHolderName(e.target.value.replace(/[0-9]/g,""));
                      setAcHolderName(e.target.value);
                    }
                    }
                    onFocus={(e) => setBankError({ ...bankError, acHolderName: "" })}
                    onBlur={(e) => handleBankValidation("acHolderName")}
                  />
                  {bankError && (
                    <span style={{ color: "red" }}>{bankError?.acHolderName}</span>
                  )}
                  <input
                    type="text"
                    name=""
                    required=""
                    placeholder="IBAN / Account Number*"
                    value={accountNumber}
                    // maxLength="31"
                    onChange={(e) =>
                      // setAccountNumber(e.target.value.replace(/[^0-9]/g, ""))
                      setAccountNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase())
                    }
                    onFocus={(e) =>
                      setBankError({ ...bankError, accountNumber: "" })
                    }
                    onBlur={(e) => handleBankValidation("accountNumber")}
                  />
                  {bankError && (
                    <span style={{ color: "red" }}>
                      {bankError?.accountNumber}
                    </span>
                  )}
                  <input
                    type="text"
                    name=""
                    required=""
                    placeholder="Swift code / sort code"
                    value={code}
                    // maxLength="11"
                    onChange={(e) =>
                      setCode(e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase())
                    }
                    onFocus={(e) => setBankError({ ...bankError, code: "" })}
                    onBlur={(e) => handleBankValidation("code")}
                  />
                  {bankError && (
                    <span style={{ color: "red" }}>{bankError?.code}</span>
                  )}
                </div>
                <div className="btn-wrap text-center">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={(e) => handleBankDetails(e)}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BankingInfo;
