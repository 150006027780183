import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function EventInfo({ role }) {

  useEffect(() => {
    document.title = "LiveStage | Event Information"

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[]);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
            <a className="cursorPointer" href={role == "User" ? "/home/user" : role == "Actor" ? "/home/actor" : "/"}>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Event Information</h1>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role
  }
}

export default connect(mapStateToProps, null)(EventInfo);
