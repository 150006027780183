import React, { useCallback, useEffect, useRef, useState } from "react";
import Home from "./home";
import AllEvents from "./allEvents";
import ContactUs from "../../contactus/ContactUs";
import MyBookings from "./myBookings";
import AboutUs from "../../aboutus/AboutUs";
import canvasmenu from "../../../assets/img/icon-canvasmenu.svg";
import dpPhoto from "../../../assets/img/img_avatar.png";
import logo from "../../../assets/img/logo.svg";
import homeMenu from "../../../assets/img/home-menu.svg";
import myEventMenu from "../../../assets/img/myevent-menu.svg";
import createEventMenu from "../../../assets/img/createevent-menu.svg";
import contactMenu from "../../../assets/img/contact-menu.svg";
import iconBookingM from "../../../assets/img/icon-booking-m.svg";
import iconBellM from "../../../assets/img/icon-bel-m.svg";
import iconAccountM from "../../../assets/img/icon-account-m.svg";
import iconAboutM from "../../../assets/img/icon-about-m.svg";
import iconContactM from "../../../assets/img/icon-contact-m.svg";
import iconLogoutM from "../../../assets/img/icon-logout-m.svg";
import likeOutline from "../../../assets/img/like-outline.svg";
import likeFilled from "../../../assets/img/like-filled.svg";
import dislikeOutline from "../../../assets/img/dislike-outline.svg";
import dislikeFilled from "../../../assets/img/dislike-filled.svg";
import iconMute from "../../../assets/img/icon-mute.png";
import iconUnMute from "../../../assets/img/icon-unmute.png";
import Store from "../../../redux/store/store";
import { connect } from "react-redux";
import {
  GetNotifications,
  LogOutAction,
  MyAccountInfo,
  MarkAsRead,
} from "../../../redux/actions/user";
import { RateEvent, SearchEvent } from "../../../redux/actions/events";
import MyAccount from "../../myAccount/MyAccount";
import Wrapper from "../../../config/Wrapper";
import EventDetails from "./events/eventDetails";
import BookEvent from "./events/bookEvent";
import Loader from "../../loader/Loader";
import moment from "moment-timezone";
import { onMessageListener } from "../../../helpers/firebase";
import { Notify } from "../../../helpers/notifications";
import BookingDetails from "./events/bookingDetails";
import { getEventLocalDate } from "../../../helpers/constants";
import platform from "platform";

function HomeTabs({
  history,
  profilePhoto,
  MyAccountInfo,
  userName,
  vidStatus,
  eventFile,
}) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [noti, setNoti] = useState(false);
  const [rating, setRating] = useState("");
  const [rateEvent, setRateEvent] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [eventID, setEventID] = useState("");
  const [flag, setFlag] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [paramsPayload, setParamsPayload] = useState({
    skip: 0, // Integer
    limit: 12, // Integer
  });
  const [bookingID, setBookingID] = useState("");
  const [rateEventID, setRateEventID] = useState("");
  const [rateEventActor, setRateEventActor] = useState("");
  const localTZone = moment.tz.guess();
  const [videoMute, setVideoMute] = useState(true);
  const [resetTab, setResetTab] = useState(false);
  // const [notiFlag, setNotiFlag] = useState(false);
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showSugg, setShowSugg] = useState(false);

  // if( platform.name !== "IE" && platform.name !== "Safari" ) {
  onMessageListener()
    .then((message) => {
      console.log("from HomeTabs.js", message);
      if (history.location.pathname !== "/home/user/bookEvent") {
        setNotificationList([]);
        setParamsPayload({ ...paramsPayload, skip: 0 });
        Notify(
          "default",
          `${message.notification.body}`,
          `${message.notification.title}`
        );
      }
    })
    .catch((error) => console.log(error));
  // }

  const observer = useRef();

  const loadMore = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      if (!hasMore || loading) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setParamsPayload({ ...paramsPayload, skip: paramsPayload.skip + 12 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  useEffect(() => {
    document.title = "LiveStage | Home";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Notifications
  useEffect(() => {
    console.log("Noti API", paramsPayload);
    setLoading(true);
    GetNotifications(paramsPayload)
      .then((response) => {
        console.log(response);
        setNotificationList([...notificationList, ...response.data.data]);
        setIsNewNotification(response.data.isNewNotification);
        setHasMore(
          response.data.count -
            (notificationList.length + response.data.data.length) >
            0
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setHasMore(false);
        setLoading(false);
      });
  }, [paramsPayload]);

  const toggleTab = (index) => {
    setSelectedTab(index);
  };
  const { dispatch } = Store.store;

  const handleNotification = () => {
    setNoti(!noti);
  };

  const handleRating = async () => {
    let payload = {
      eventId: rateEventID,
      rate: rating,
    };

    console.log(payload);

    try {
      const response = await RateEvent(payload);

      if (response && response.status === 200) {
        setNotificationList([]);
        setParamsPayload({ ...paramsPayload, skip: 0 });
        setRateEventID("");
        setRateEventActor("");
        setRating("");
        setRateEvent(false);
        Notify("success", `Event rated successfully!!`);
      }
    } catch (error) {
      console.log(error);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  useEffect(() => {
    MyAccountInfo();
  }, []);

  useEffect(() => {
    if (selectedTab !== 5) {
      localStorage.removeItem("setTab");
    } else if (selectedTab !== 9 || selectedTab !== 7) {
      // localStorage.removeItem("notiEventID");
      // localStorage.removeItem("notiBookingID");
      dispatch({ type: "EMPTY_EVENTID" });
    }
    setViewAllClicked(false);
  }, [selectedTab]);

  // Mark Notifications as read
  const handleMarkAsRead = async (type, id = "") => {
    console.log(type, id);

    let payload = {
      type: type,
      notificationId: id,
    };

    console.log(payload);

    try {
      const response = await MarkAsRead(payload);

      if (response.status === 200) {
        console.log(response);
        setNotificationList([]);
        setParamsPayload({ ...paramsPayload, skip: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  useEffect(() => {
    if (vidStatus) {
      const video = document.getElementById("videoElement");
      video?.load();
    }
  }, [eventFile]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText.length > 2) {
        SearchEvent({ searchString: searchText })
          .then((response) => {
            setShowSugg(true);
            setSearchSuggestions([...response?.data?.data]);
          })
          .catch((error) => console.log(error));
      } else {
        setShowSugg(false);
      }
    }, 750);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  return (
    <div>
      <div
        id="app"
        className={
          selectedTab === 1
            ? "for-overlay page-home page-enduserhome"
            : selectedTab === 2
            ? "for-overlay page-home page-allevents"
            : selectedTab === 3
            ? "for-overlay page-home page-allevents"
            : "for-overlay page-home"
        }
        style={{
          backgroundImage: `url(${
            !vidStatus &&
            ((history.location.pathname === "/home/user" && !viewAllClicked) ||
              history.location.pathname === "/home/user/eventDetails" ||
              history.location.pathname === "/home/user/bookingDetails") &&
            eventFile
          })`,
          // backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          // height: "100vh",
          height: "750px",
          width: "100%",
          backgroundSize: "cover",
          // minHeight: "700px",
          backgroundPosition: "center",
        }}
      >
        {vidStatus &&
          ((history.location.pathname === "/home/user" && !viewAllClicked) ||
            history.location.pathname === "/home/user/eventDetails" ||
            history.location.pathname === "/home/user/bookingDetails") && (
            <div className="bg_video">
              <div
                className="cursorPointer video-mute-div mIcon"
                onClick={() => handleVideoMute()}
              >
                {videoMute ? <img src={iconMute} /> : <img src={iconUnMute} />}
              </div>
              <video
                id="videoElement"
                // minHeight="480"
                // height="480"
                width="100%"
                // controls
                autoPlay={true}
                loop={true}
                muted={videoMute}
              >
                <source src={eventFile} />
              </video>
            </div>
          )}
        {((history.location.pathname === "/home/user" && !viewAllClicked) ||
          history.location.pathname === "/home/user/eventDetails" ||
          history.location.pathname === "/home/user/bookingDetails") && (
          <div className="layer"></div>
        )}

        <header
          className="app-header"
          style={{
            position: "relative",
            zIndex: 25,
          }}
        >
          <div className="flex-container">
            <div className="flex-header">
              <button
                className="btn-canvas-menu"
                type="button"
                onClick={() => setShowCanvas(true)}
              >
                <img src={canvasmenu} alt="menu" />
              </button>

              <div className="logo">
                <a className="cursorPointer" href="/home/user">
                  <img src={logo} alt="logo" />
                </a>
              </div>

              <nav className="app-menu">
                <ul>
                  <li className={selectedTab === 1 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(1);
                        // localStorage.removeItem("notiEventID");
                        // localStorage.removeItem("notiBookingID");
                        dispatch({ type: "EMPTY_EVENTID" });
                        console.log("home clicked", setFlag(!flag));
                      }}
                    >
                      <img src={homeMenu} alt="Home" /> Home
                    </a>
                  </li>
                  <li className={selectedTab === 2 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(2);
                        // localStorage.removeItem("notiEventID");
                        // localStorage.removeItem("notiBookingID");
                        dispatch({ type: "EMPTY_EVENTID" });
                        console.log("all events clicked", setFlag(!flag));
                      }}
                    >
                      <img src={myEventMenu} alt="My Events" /> All Events
                    </a>
                  </li>
                  <li className={selectedTab === 3 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(3);
                        // localStorage.removeItem("notiEventID");
                        // localStorage.removeItem("notiBookingID");
                        dispatch({ type: "EMPTY_EVENTID" });
                        console.log("my bookings clicked", setFlag(!flag));
                      }}
                    >
                      <img src={createEventMenu} alt="Create Events" /> My
                      Bookings
                    </a>
                  </li>
                  <li className={selectedTab === 5 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(5);
                        setResetTab(!resetTab);
                        // localStorage.removeItem("notiEventID");
                        // localStorage.removeItem("notiBookingID");
                        dispatch({ type: "EMPTY_EVENTID" });
                        console.log("contact us clicked", setFlag(!flag));
                      }}
                    >
                      <img src={iconAccountM} alt="My Account" /> My Account
                    </a>
                  </li>
                </ul>
              </nav>

              <div className="account-links">
                {/* {(history.location.pathname === "/home/user/allevents" || history.location.pathname === "/home/user/mybookings") && 
                <>
                {(showSearch && 
                  <div className="field-wrap">
                    <input
                      type="text"
                      value={searchKeyword}
                      onChange={(event) => setSearchKeyword(event.target.value)}
                    />
                  </div>
                )}
                <div className="search">
                    <a
                      className="icon cursorPointer"
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      Search
                    </a>
                </div></>} */}
                <div className="search-header-wrapper">
                  <div className={`searchBox ${showSearch && "input"}`}>
                    <div className="inputBox">
                      <input
                        className="srch-in-field"
                        type="searchInput"
                        placeholder="Search Event"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <button
                        className="srch-bnts"
                        onClick={() => {
                          setShowSearch(true)
                          if(searchSuggestions?.length > 20 && showSearch) { 
                            console.log("moving to all events");
                            setSelectedTab(2);
                            setShowSugg(false);
                            setSearchText("");
                            setShowSearch(false);
                          }
                        }}
                      >
                        <span></span>
                      </button>
                    </div>
                    <span
                      className={`closeBtn ${!showSearch && "cls-btn-hide"}`}
                      onClick={() => {
                        setShowSugg(false);
                        setSearchText("");
                        setShowSearch(false);
                      }}
                    ></span>
                    <div
                      className={`sugesst-box-main ${
                        !showSugg && "hide-suggestions"
                      }`}
                    >
                      <ul className="sugesst-box">
                        {searchSuggestions.length > 0
                          ? searchSuggestions.map((item, index) => (
                              <li
                                className="sugesstion cursorPointer"
                                key={index}
                                onClick={() => {
                                  console.log(item._id);
                                  setSelectedTab(7);
                                  // localStorage.setItem("notiEventID", item._id);
                                  dispatch({
                                    type: "SET_EVENTID",
                                    payload: {
                                      notiEventID: item._id,
                                      notiBookingID: "",
                                    },
                                  });
                                  setShowSugg(false);
                                  setSearchText("");
                                  setShowSearch(false);
                                }}
                              >
                                <a>{item.eventTitle}</a>
                                <span className="searchTime">
                                  {
                                    getEventLocalDate(
                                      item.eventDate,
                                      item.eventTime,
                                      item.setTimeZone
                                    ).split(",")[0]
                                  }
                                  ,
                                  {
                                    getEventLocalDate(
                                      item.eventDate,
                                      item.eventTime,
                                      item.setTimeZone
                                    ).split(",")[1]
                                  }
                                </span>
                              </li>
                            ))
                          : "No Events Found"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="notification-bell">
                  <a
                    className="icon cursorPointer"
                    onClick={(e) => handleNotification()}
                  >
                    Notification
                  </a>
                  {isNewNotification && <span className="count"></span>}
                </div>
                <div className="account-dropdown">
                  <a className="icon cursorPointer">
                    <img
                      src={profilePhoto ? profilePhoto : dpPhoto}
                      alt="User"
                    />
                  </a>
                  <ul>
                    {/* <li>
                      <a
                        className="cursorPointer"
                        onClick={() => {
                          setSelectedTab(5);
                          setResetTab(!resetTab);
                        }}
                      >
                        My Account
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="cursorPointer"
                        onClick={() => setSelectedTab(6)}
                      >
                        About Livestage
                      </a>
                    </li>
                    <li>
                      <a
                        className="cursorPointer"
                        onClick={(e) => dispatch(LogOutAction())}
                      >
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        {selectedTab === 1 ? (
          <>
            {/* <Home /> */}
            {(() => {
              history.location.pathname !== "/home/user" &&
                history.push("/home/user");
            })()}
            <Wrapper
              path="/home/user"
              Component={
                <Home
                  setSelectedTab={setSelectedTab}
                  setEventID={setEventID}
                  setViewAllClicked={setViewAllClicked}
                />
              }
            />
          </>
        ) : selectedTab === 2 ? (
          // <AllEvents />
          <>
            {(() => {
              history.location.pathname !== "/home/user/allevents" &&
                history.push("/home/user/allevents");
            })()}
            <Wrapper
              path="/home/user/allevents"
              Component={
                <AllEvents
                  setSelectedTab={setSelectedTab}
                  setEventID={setEventID}
                />
              }
            />
          </>
        ) : selectedTab === 3 ? (
          // <MyBookings />
          <>
            {(() => {
              history.location.pathname !== "/home/user/mybookings" &&
                history.push("/home/user/mybookings");
            })()}
            <Wrapper
              path="/home/user/mybookings"
              Component={
                <MyBookings
                  setSelectedTab={setSelectedTab}
                  setEventID={setEventID}
                  setBookingID={setBookingID}
                />
              }
            />
          </>
        ) : selectedTab === 4 ? (
          // <ContactUs />
          <>
            {(() => {
              history.location.pathname !== "/home/user/contactus" &&
                history.push("/home/user/contactus");
            })()}
            <Wrapper
              path="/home/user/contactus"
              Component={<ContactUs setSelectedTab={setSelectedTab} />}
            />
          </>
        ) : selectedTab === 5 ? (
          <>
            {(() => {
              history.location.pathname !== "/home/user/myaccount" &&
                history.push("/home/user/myaccount");
            })()}
            {/* <Wrapper
              path="/home/user/myaccount"
              Component={
                <MyAccount
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                />
              }
            /> */}
            <MyAccount setSelectedTab={setSelectedTab} resetTab={resetTab} />
          </>
        ) : selectedTab === 6 ? (
          // <AboutUs setSelectedTab={setSelectedTab} />
          <>
            {(() => {
              history.location.pathname !== "/home/user/aboutus" &&
                history.push("/home/user/aboutus");
            })()}
            <Wrapper
              path="/home/user/aboutus"
              Component={<AboutUs setSelectedTab={setSelectedTab} />}
            />
          </>
        ) : selectedTab === 7 ? (
          <>
            {(() => {
              history.location.pathname !== `/home/user/eventDetails` &&
                history.push(`/home/user/eventDetails`);
            })()}
            <Wrapper
              path={`/home/user/eventDetails`}
              Component={
                <EventDetails
                  setSelectedTab={setSelectedTab}
                  eventID={eventID}
                  setEventID={setEventID}
                />
              }
            />
          </>
        ) : selectedTab === 8 ? (
          <>
            {(() => {
              history.location.pathname !== `/home/user/bookEvent` &&
                history.push(`/home/user/bookEvent`);
            })()}
            <Wrapper
              path={`/home/user/bookEvent`}
              Component={
                <BookEvent setSelectedTab={setSelectedTab} eventID={eventID} />
              }
            />
          </>
        ) : (
          <>
            {(() => {
              history.location.pathname !== `/home/user/bookingDetails` &&
                history.push(`/home/user/bookingDetails`);
            })()}
            <Wrapper
              path={`/home/user/bookingDetails`}
              Component={
                <BookingDetails
                  setSelectedTab={setSelectedTab}
                  eventID={eventID}
                  bookingID={bookingID}
                />
              }
            />
          </>
        )}

        <footer className="app-footer">
          <div className="flex-container">
            <div className="footer-content">
              {/* <div className="question">Questions? Call 415-784-458</div> */}
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/termsOfUse">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/cookiePreference">Cookie Policy</a>
                  </li>
                  {/* <li>
                    <a href="/helpCentre">Help Centre</a>
                  </li> */}
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                  <li>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(4);
                        console.log("contact us clicked", setFlag(!flag));
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright">
              Copyright 2020. All Rights Reserved Live Stage
            </div>
          </div>
        </footer>
      </div>

      {/* Side Menu */}
      <div className={`canvas-menu ${showCanvas && "show"}`}>
        <div className="relative">
          <button
            className="btn-menu-hide"
            type="button"
            onClick={() => setShowCanvas(false)}
          >
            Close
          </button>
          <div className="canvas-menu-items">
            <div className="user">
              <img src={profilePhoto ? profilePhoto : dpPhoto} alt="user" />
              <h3>Hi {userName},</h3>
              <h4>Welcome</h4>
            </div>
            <ul>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(3);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconBookingM} alt="nav" /> My Bookings
                </a>
              </li>
              <li>
                <a className="cursorPointer"
                onClick={() => {
                  setShowCanvas(false);
                  handleNotification();
                }}
                >
                  <img src={iconBellM} alt="nav" /> Notifications
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(5);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconAccountM} alt="nav" /> My Account
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(6);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconAboutM} alt="nav" /> About Livestage
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(4);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconContactM} alt="nav" /> Contact Us
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => dispatch(LogOutAction())}
                >
                  <img src={iconLogoutM} alt="nav" /> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*Notifications sidebar model*/}
      <div
        className={`sidebar-model notification-sidebar-model ${noti && "show"}`}
        style={{ background: "#0e0e0e" }}
      >
        <div className="model-head">
          <h2>Notifications</h2>
          <a
            className="model-close cursorPointer"
            onClick={(e) => handleNotification()}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div style={{ textAlign: "end" }}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                if (isNewNotification) {
                  handleMarkAsRead("multiple");
                }
              }}
            >
              Mark all as read
            </span>
          </div>
          <div className="notification-items">
            {notificationList.length > 0 ? (
              notificationList?.map((notif, index) => {
                if (notificationList.length === index + 1) {
                  return (
                    <>
                      <div
                        className="loop item"
                        key={index}
                        style={{ background: notif.markRead && "#0e0e0e" }}
                        ref={loadMore}
                      >
                        <div
                          className="flex-item cursorPointer"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => {
                            if (!notif.markRead) {
                              handleMarkAsRead("single", notif._id);
                            }
                            console.log(notif.type);
                            if (
                              notif.type === "2" ||
                              notif.type === "3" ||
                              notif.type === "4"
                            ) {
                              dispatch({
                                type: "SET_EVENTID",
                                payload: {
                                  notiEventID: notif.eventId,
                                  notiBookingID: notif.bookingId,
                                },
                              });
                              setSelectedTab(9);
                              // localStorage.setItem(
                              //   "notiEventID",
                              //   notif.eventId
                              // );
                              // localStorage.setItem(
                              //   "notiBookingID",
                              //   notif.bookingId
                              // );
                            } else if (notif.type === "6") {
                              setSelectedTab(5);
                              localStorage.setItem("setTab", 4);
                            } else {
                              setSelectedTab(7);
                              // localStorage.setItem(
                              //   "notiEventID",
                              //   notif.eventId
                              // );
                              dispatch({
                                type: "SET_EVENTID",
                                payload: {
                                  notiEventID: notif.eventId,
                                  notiBookingID: notif.bookingId,
                                },
                              });
                            }
                            setNoti(!noti);
                          }}
                        >
                          <div className="img" style={{ width: "190px" }}>
                            <div
                              style={{
                                height: "130px",
                                borderRadius: "5px",
                                verticalAlign: "bottom",
                                backgroundImage: `url(${notif.eventImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          </div>
                          <div className="content" style={{ width: "100%" }}>
                            <div className="title">
                              <h4>{notif.eventName}</h4>
                              <p className="date">
                                {moment
                                  .tz(`${notif.createdAt}`, `${localTZone}`)
                                  .format("DD MMM, hh:MM A")}
                              </p>
                            </div>
                            <p>{notif.message}</p>
                          </div>
                        </div>
                        {notif.survey && (
                          <a
                            className="primary-button btn-rate-event cursorPointer"
                            onClick={(e) => {
                              setRateEvent(true);
                              setRateEventID(notif.eventId);
                              setRateEventActor(notif.artistName);
                            }}
                          >
                            Rate Event
                          </a>
                        )}
                      </div>
                      {notif.markRead && <hr />}
                    </>
                  );
                } else {
                  return (
                    <>
                      <div
                        className="loop item"
                        key={index}
                        style={{ background: notif.markRead && "#0e0e0e" }}
                      >
                        <div
                          className="flex-item cursorPointer"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => {
                            if (!notif.markRead) {
                              handleMarkAsRead("single", notif._id);
                            }
                            if (
                              notif.type === "2" ||
                              notif.type === "3" ||
                              notif.type === "4"
                            ) {
                              dispatch({
                                type: "SET_EVENTID",
                                payload: {
                                  notiEventID: notif.eventId,
                                  notiBookingID: notif.bookingId,
                                },
                              });
                              setSelectedTab(9);
                              // localStorage.setItem(
                              //   "notiEventID",
                              //   notif.eventId
                              // );
                              // localStorage.setItem(
                              //   "notiBookingID",
                              //   notif.bookingId
                              // );
                            } else if (notif.type === "6") {
                              setSelectedTab(5);
                              localStorage.setItem("setTab", 4);
                            } else {
                              setSelectedTab(7);
                              // localStorage.setItem(
                              //   "notiEventID",
                              //   notif.eventId
                              // );
                              dispatch({
                                type: "SET_EVENTID",
                                payload: {
                                  notiEventID: notif.eventId,
                                  notiBookingID: notif.bookingId,
                                },
                              });
                            }
                            setNoti(!noti);
                          }}
                        >
                          <div className="img" style={{ width: "190px" }}>
                            <div
                              style={{
                                height: "130px",
                                borderRadius: "5px",
                                verticalAlign: "bottom",
                                backgroundImage: `url(${notif.eventImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          </div>
                          <div className="content" style={{ width: "100%" }}>
                            <div className="title">
                              <h4>{notif.eventName}</h4>
                              <p className="date">
                                {moment
                                  .tz(`${notif.createdAt}`, `${localTZone}`)
                                  .format("DD MMM, hh:MM A")}
                              </p>
                            </div>
                            <p>{notif.message}</p>
                          </div>
                        </div>
                        {notif.survey && (
                          <a
                            className="primary-button btn-rate-event cursorPointer"
                            onClick={(e) => {
                              setRateEvent(true);
                              setRateEventID(notif.eventId);
                              setRateEventActor(notif.artistName);
                            }}
                          >
                            Rate Event
                          </a>
                        )}
                      </div>
                      {notif.markRead && <hr />}
                    </>
                  );
                }
              })
            ) : (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                No Notifications Found
              </div>
            )}
          </div>
          <div>{loading && <Loader />}</div>
        </div>
      </div>
      <div className={`overlay ${noti && "show"}`}></div>

      {/* Rate event sidebar model */}
      <div
        className={`sidebar-model rating-sidebar-model ${rateEvent && "show"}`}
        style={{ background: "#0e0e0e" }}
      >
        <div className="model-head">
          <h2>Rate Event</h2>
          <a
            className="model-close cursorPointer"
            onClick={(e) => {
              setRateEvent(false);
              setRateEventID("");
              setRateEventActor("");
            }}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="rate-options">
            <div className="form-wrap">
              <div>
                <h3>How was your experience with {rateEventActor}?</h3>
                <div className="field-wrap">
                  <div className="radio-btn-wrap">
                    <label>
                      <span>
                        <img src={rating === "5" ? likeFilled : likeOutline} />
                      </span>
                      <input
                        type="radio"
                        id="perfect"
                        name="rating"
                        value="5"
                        onChange={(e) => setRating(e.target.value)}
                      />
                      Perfect
                    </label>
                  </div>
                </div>
                <div className="field-wrap">
                  <div className="radio-btn-wrap">
                    <label>
                      <span>
                        <img
                          src={rating === "0" ? dislikeFilled : dislikeOutline}
                        />
                      </span>
                      <input
                        type="radio"
                        id="bad"
                        name="rating"
                        value="0"
                        onChange={(e) => setRating(e.target.value)}
                      />
                      Bad
                    </label>
                  </div>
                </div>
                <div className="btn-wrap text-center">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={() => handleRating()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER, EVENT_FILE }) => {
  return {
    profilePhoto: USER_REDUCER.myAccountData?.profilePhoto,
    userName: USER_REDUCER.myAccountData?.firstName,
    vidStatus: EVENT_FILE.videoStatus,
    eventFile: EVENT_FILE.eventFile,
  };
};

export default connect(mapStateToProps, {
  LogOutAction,
  MyAccountInfo,
})(HomeTabs);
