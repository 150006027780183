import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateParticipant, CreateStream } from "../../redux/actions/events";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";
import SubscriberHLS from "./SubscriberHLS";
import Loader from "../loader/Loader";
import { Notify } from "../../helpers/notifications";
import { useHistory } from "react-router"

function Stream({ role }) {

  const history = useHistory();
  console.log(history.location.state)

  const [apiKey, setApiKey] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [broadCastURL, setBrodCastURL] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [blockedStatus, setBlockedStatus] = useState(false);
  const [isHLS, setIsHLS] = useState(false);
  const [actorName, setActorName] = useState("");

  useEffect(() => {
    if (role === "Actor") {
      setLoading(true);
      CreateStream({ eventId: history.location.state.eventId, deviceType: "web" })
        .then((res) => {
          // console.log(res);
          setApiKey(res.data.data.apiKey);
          setSessionId(res.data.data.sessionId);
          setToken(res.data.data.token);
          setEventEndTime(res.data.data.eventEndTime);
          setEventStartTime(res.data.data.startTime);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          history.push("/home/actor");
          Notify("danger", "Please wait for 10-15 seconds before restarting the event", "", "top-right", "", 10000);
        });
    } 
    else {
      setLoading(true);
      CreateParticipant({ eventId: history.location.state.eventId })
        .then((res) => {
          // console.log(res);
          setApiKey(res.data.data.apiKey);
          setSessionId(res.data.data.sessionId);
          setToken(res.data.data.token);
          setBrodCastURL(res.data.data.broadCastUrl);
          setBlockedStatus(res.data.data.chatEnable);
          setIsHLS(res.data.data.isHLS);
          setActorName(res.data.data.actorName);
          localStorage.setItem("isSessionJoined", true);
          // localStorage.setItem("uniqueTokenforViewing", res.data.data.fcmToken);
          // document.cookie=`petros-unique-id-view=${res.data.data.uniqueId}`;
          setLoading(false);
          if(!res.data.data.isHLS) {
            Notify("danger", "Session failed");
            history.push("/home/user");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          Notify("danger", "Session failed");
        });
    }
  }, []);

  return (
    <div>
      {(!loading) ? (
        <>
        {role === "Actor"  && apiKey && sessionId && token && <Publisher apiKey={apiKey} sessionId={sessionId} token={token} eventId={history.location.state.eventId} eventEndTime={eventEndTime} shareEventURL={history.location.state.shareEventURL} eventStartTime={eventStartTime} />}

        {(role === "User" || role === "Corporate") && isHLS &&
          <SubscriberHLS eventId={history.location.state.eventId} broadCastURL={broadCastURL} blockedStatus = {blockedStatus} actorName={actorName} shareEventURL={history.location.state.shareEventURL} />}
          
        {/* {(role === "User" || role === "Corporate") && !isHLS && apiKey && sessionId && token &&       
          <Subscriber apiKey={apiKey} sessionId={sessionId} token={token} eventId={history.location.state.eventId} blockedStatus = {blockedStatus} actorName={actorName} shareEventURL={history.location.state.shareEventURL} />} */}
        </>
      ) : (
        <div>
        <Loader />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, {})(Stream);
