import React from "react";
import copyIcon from "../../assets/img/copy-icon.png";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import {REACT_APP_BASE_URL_FRONTEND} from "../../helpers/env";
import copy from "copy-to-clipboard";
import { Notify } from "../../helpers/notifications";

function ShareEvent({ open, route, shareEventID, shareEventURL }) {

  const shareItems = {
    // url: `${REACT_APP_BASE_URL_FRONTEND}public/showEvent/${shareEventID}`,
    url: `${shareEventURL}`,
    title: "LiveStage - Come Book An Event Now, Be A part of our amazing community!!",
    hashtag: "LiveStage",
    subEmail: "LiveStage Events App",
    // subBody: `<h1>Come Book An Event Now, Be A part of our amazing community!!</h1><br><a href="${REACT_APP_BASE_URL_FRONTEND}public/showEvent/${shareEventID}">Click Here</a>`
    subBody: `<h1>Come Book An Event Now, Be A part of our amazing community!!</h1><br><a href="${shareEventURL}">Click Here</a>`
  }

  const copytoClipBoard = () => {
    copy(shareItems.url);
    Notify("success", "Copied to clipboard!!");
  }

  return (
    <div className={`${open && "social-wrap-2"} social-wrap ${route === "liveStream" && "transparent-back"}`}>
      <div className="social-col">
        <EmailShareButton
          subject={shareItems.subEmail}
          body={shareItems.subBody}
        >
          <EmailIcon size={28} round={true} />
        </EmailShareButton>
      </div>

      <div className="social-col">
        <FacebookShareButton
          url={shareItems.url}
          quote={shareItems.title}
          hashtag={shareItems.hashtag}
        >
          <FacebookIcon size={28} round={true} />
        </FacebookShareButton>
      </div>

      {/* <div className="social-col">
        <TwitterShareButton
          url={shareItems.url}
          title={shareItems.title}
          via={"livestageTwitter"}
          hashtags={[`${shareItems.hashtag}`]}
        >
          <TwitterIcon size={28} round={true} />
        </TwitterShareButton>
      </div> */}

      <div className="social-col">
        <WhatsappShareButton
          url={shareItems.url}
          title={shareItems.title}
        >
          <WhatsappIcon size={28} round={true} />
        </WhatsappShareButton>
      </div>

      <div className="social-col">
          <img src={copyIcon} alt="Copy" style={{width: "28px", cursor: "pointer"}} 
            onClick={() => {
              copytoClipBoard();
            }}
          />
      </div>
    </div>
  );
}

export default ShareEvent;
