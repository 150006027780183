import React, { useEffect, useState } from "react";
import iconCal from "../../../../assets/img/icon-cal.svg";
import iconWatch from "../../../../assets/img/icon-watch.svg";
import iconCard from "../../../../assets/img/icon-card.svg";
import dpPhoto from "../../../../assets/img/img_avatar.png";
import IconLike from "../../../../assets/img/icon-like.svg";
import iconPlay from "../../../../assets/img/play-button.png";
import {
  AddViewer,
  GetEventDetails,
  GetEventList,
} from "../../../../redux/actions/events";
import { Notify } from "../../../../helpers/notifications";
import { useHistory } from "react-router";
import { getEventLocalDate } from "../../../../helpers/constants";
import { connect } from "react-redux";
import Logo from "../../../loader/Loader";
import Store from "../../../../redux/store/store";
// import iconMute from "../../../../assets/img/icon-mute.png";
// import iconUnMute from "../../../../assets/img/icon-unmute.png";

function EventDetails({ setSelectedTab, eventID, role, setEventID, notifEID }) {
  // let notiEventID = localStorage.getItem("notiEventID");
  let notiEventID = notifEID;
  const [eventData, setEventData] = useState({});
  // const [eventDate, setEventDate] = useState("");
  const [videoMute, setVideoMute] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [allEventsData, setAllEventsData] = useState([]);
  const [trendingData, setTrendingData] = useState([]);
  const [eventIdChange, setEventIdChange] = useState("");

  const history = useHistory();
  // console.log("Event Details actor", history);

  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Event Details";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [eventIdChange, notiEventID]);

  useEffect(() => {
    setShowLoader(true);
    GetEventDetails(
      notiEventID ? notiEventID : eventIdChange ? eventIdChange : eventID
    )
      .then((response) => {
        // console.log(response.data);
        setEventData(response.data.data);
        // setEventDate(
        //   new Date(response.data.data.eventDate).toDateString().split(" ")
        // );
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.data.eventImage,
            videoStatus: response.data.data.videoStatus,
          },
        });
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  }, [eventIdChange, notiEventID]);

  useEffect(() => {
    GetEventList({ category: "allEvents" })
      .then((response) => {
        // console.log(response.data.data);
        setAllEventsData([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
    GetEventList({ category: "trendingData" })
      .then((response) => {
        // console.log(response);
        setTrendingData([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [eventIdChange, notiEventID]);

  // Handle Booking
  const handleBookEvent = () => {
    console.log("event booking");
    setSelectedTab(8);
  };

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  // useEffect(() => {
  //   localStorage.removeItem("notiEventID");
  // }, [history.location.pathname]);

  const handleViewer = async () => {
    let match = localStorage.getItem("petros-unique-id-view");

    let payload = {
      fcmToken: localStorage.getItem("fcmToken"),
      // fcmToken: localStorage.getItem("uniqueTokenforViewing"),
      uniqueId: match,
      eventId: eventData._id,
    };

    try {
      let response = await AddViewer(payload);

      if (response.status === 200) {
        console.log("===============>", response.data);
        console.log(response.data.data.fcmToken, response.data.data.uniqueId);
        localStorage.setItem(
          "uniqueTokenforViewing",
          `${response.data.data.fcmToken}`
        );
        // document.cookie=`petros-unique-id-view=${String(response.data.data.uniqueId)}`;
        localStorage.setItem(
          "petros-unique-id-view",
          response.data.data.uniqueId
        );
        history.push(`/streamEvent`, {
          eventId: eventData._id, shareEventURL: eventData.share_url
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  }

  return (
    <div>
      <section
        className="events-section enduser-section"
        style={{
          paddingTop: "0px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="flex-container">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    // localStorage.removeItem("notiEventID");
                    dispatch({ type: "EMPTY_EVENTID" });
                    setSelectedTab(1);
                  }}
                >
                  Home
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    // localStorage.removeItem("notiEventID");
                    dispatch({ type: "EMPTY_EVENTID" });
                    setSelectedTab(2);
                  }}
                >
                  All Events
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>Book Now</li>
            </ul>
          </div>

          {showLoader ? (
            <div>
              <Logo />
            </div>
          ) : (
            <>
              <div className="event-details">
                <h1>{eventData.eventTitle}</h1>
                <div className="content">
                  <div className="date-time">
                    <div className="flex-row">
                      <div>
                        <img src={iconCal} alt="User" />
                        {
                          getEventLocalDate(
                            eventData.eventDate,
                            eventData.eventTime,
                            eventData.setTimeZone
                          ).split(",")[0]
                        }
                      </div>
                      <div>
                        <img src={iconWatch} alt="User" />
                        {
                          getEventLocalDate(
                            eventData.eventDate,
                            eventData.eventTime,
                            eventData.setTimeZone
                          ).split(",")[1]
                        }
                      </div>
                    </div>

                    <div className="flex-row">
                      <div>
                        <img src={iconCard} alt="User" /> €{" "}
                        {eventData.earlyBirdTicket > 0
                          ? parseFloat(
                              // eventData.earlyBirdTicketPrice
                              eventData.newEarlyBirdPrice
                            ).toFixed(2)
                          : parseFloat(
                              // eventData.price
                              eventData.newPrice
                            ).toFixed(2)}
                      </div>
                    </div>

                    <div className="flex-row">
                      {eventData?.earlyBirdTicket > 0 && (
                        <div>
                          EarlyBird Tickets Available:{" "}
                          {eventData?.earlyBirdTicket}
                        </div>
                      )}
                      <div>
                        Tickets Available :{" "}
                        {/* {eventData?.availableTickets} */}
                        {eventData.ticketType === "Unlimited" ||
                        eventData.audienceType === "Corporate"
                          ? "Available"
                          : eventData?.availableTickets}
                      </div>
                    </div>
                    <div className="flex-row">
                      Category : {eventData.eventCategory}
                    </div>
                    <div className="flex-row">
                      Duration:{" "}
                      {parseInt(parseInt(eventData?.eventDuration) / 60) >= 1 &&
                        `${parseInt(
                          parseInt(eventData?.eventDuration) / 60
                        )} hours`}{" "}
                      {parseInt(parseInt(eventData?.eventDuration) % 60) > 0 &&
                        `${parseInt(
                          parseInt(eventData?.eventDuration) % 60
                        )} minutes`}
                    </div>
                  </div>

                  <div className="description-scroll">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {eventData?.description}
                    </p>
                  </div>
                </div>

                {eventData.alreadyBooked && role === "Corporate" ? (
                  eventData.isSessionStart &&
                  <button
                  className="primary-button mLeft"
                  type="button"
                  onClick={() => handleViewer()}
                >
                  Watch Now
                </button>
                ) : (
                  <>
                  {eventData.availableTickets > 0 ? <button
                    className="primary-button"
                    type="button"
                    onClick={() => {
                      // (eventData.isSessionStart && eventData.alreadyBooked)
                      //   ? history.push(`/streamEvent`, {
                      //       eventId: eventData._id,
                      //     })
                      //   : eventData.alreadyBooked
                      //   ? Notify("warning", "Event is already booked.")
                      //   :
                      handleBookEvent();
                    }}
                  >
                    {/* {(eventData.isSessionStart && eventData.alreadyBooked)
                    ? "Watch Now"
                    : eventData.alreadyBooked
                    ? "Already Booked"
                    : "Book Now"} */}
                    Book Now
                  </button> : "No Tickets Available"}
                  {eventData.alreadyBooked && eventData.isSessionStart &&
                    <button
                    className="primary-button mLeft"
                    type="button"
                    onClick={() => handleViewer()}
                  >
                    Watch Now
                  </button>
                  }
                  </>
                )}

                <div className="banner-wrap">
                  <div
                    className="content banner-content-box"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="enduser-artist"
                      // style={{ marginTop: "0px" }}
                    >
                      <h4>Artist</h4>
                      <div className="row-content">
                        {/* <div className="img">
                      <img
                        src={
                          eventData?.userId?.profilePhoto
                            ? eventData.userId?.profilePhoto
                            : dpPhoto
                        }
                        alt="User"
                      />
                    </div> */}
                        <div
                          // className="eventImage"
                          style={{
                            // paddingTop: "0px",
                            height: "200px",
                            width: "65%",
                            backgroundImage: `url(${
                              eventData?.userId?.profilePhoto
                                ? eventData.userId?.profilePhoto
                                : dpPhoto
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></div>
                        <div className="content">
                          <h5>
                            {eventData?.userId?.firstName}{" "}
                            {eventData?.userId?.lastName}
                          </h5>
                          <h6>{eventData?.userId?.category}</h6>
                          {eventData?.userId?.rateCount > 0 && (
                            <div className="rating">
                              <span
                                className="like-con-book"
                                style={{ marginBottom: "0px", cursor: "auto" }}
                              >
                                <img src={IconLike} alt="" />
                                {eventData.artistRating} %
                              </span>
                            </div>
                          )}
                          <div className="description-scroll">
                            <p
                              className="p-box"
                              style={{
                                maxWidth: "500px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {eventData?.userId?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="banner-wrap">
                <div className="content banner-content-box">
                  <h1>{eventData?.eventTitle}</h1>
                  <div className="content">
                    <div className="date-time">
                      <div className="flex-row">
                        <div>
                          <img src={iconCal} alt="User" />{" "}
                          {
                            getEventLocalDate(
                              eventData.eventDate,
                              eventData.eventTime,
                              eventData.setTimeZone
                            ).split(",")[0]
                          }
                        </div>
                        <div>
                          <img src={iconWatch} alt="User" />{" "}
                          {
                            getEventLocalDate(
                              eventData.eventDate,
                              eventData.eventTime,
                              eventData.setTimeZone
                            ).split(",")[1]
                          }
                        </div>
                      </div>
                      <div className="flex-row">
                        <div>
                          <img src={iconCard} alt="User" /> €{" "}
                          {eventData.earlyBirdTicket > 0
                            ? parseFloat(
                                eventData.earlyBirdTicketPrice
                              ).toFixed(2)
                            : parseFloat(eventData.price).toFixed(2)}
                        </div>
                      </div>

                      <div className="flex-row">
                        <div>
                          EarlyBird Tickets Available:{" "}
                          {eventData?.earlyBirdTicket}
                        </div>
                        <div>
                          Tickets Available :{" "} */}
                {/* {eventData?.availableTickets} */}
                {/* {eventData.ticketType === "Unlimited" ||
                          eventData.audienceType === "Corporate"
                            ? "Available"
                            : eventData?.availableTickets}
                        </div>
                      </div>
                      <div className="flex-row">
                        Category : {eventData.eventCategory}
                      </div>
                      <div className="flex-row">
                        Duration:{" "}
                        {parseInt(parseInt(eventData?.eventDuration) / 60) >=
                          1 &&
                          `${parseInt(
                            parseInt(eventData?.eventDuration) / 60
                          )} hours`}{" "}
                        {parseInt(parseInt(eventData?.eventDuration) % 60) >
                          0 &&
                          `${parseInt(
                            parseInt(eventData?.eventDuration) % 60
                          )} minutes`}
                      </div>
                    </div>
                    <div className="description-scroll">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {eventData?.description}
                      </p>
                    </div>
                  </div>
                  {eventData.alreadyBooked && role === "Corporate" ? (
                    ""
                  ) : (
                    <button
                      className="primary-button"
                      type="button"
                      onClick={() => { */}
                {/* // (eventData.isSessionStart && eventData.alreadyBooked)
                        //   ? history.push(`/streamEvent`, {
                        //       eventId: eventData._id,
                        //     })
                        //   : eventData.alreadyBooked
                        //   ? Notify("warning", "Event is already booked.")
                        //   :
                        handleBookEvent();
                      }}
                    > */}
                {/* {(eventData.isSessionStart && eventData.alreadyBooked)
                    ? "Watch Now"
                    : eventData.alreadyBooked
                    ? "Already Booked"
                    : "Book Now"} */}
                {/* Book Now
                    </button>
                  )} */}

                {/* <div className="enduser-artist">
                  <h4>Artist</h4>
                  <div className="row-content">
                    <div className="img">
                      <img
                        src={
                          eventData?.userId?.profilePhoto
                            ? eventData.userId?.profilePhoto
                            : dpPhoto
                        }
                        alt="User"
                      />
                    </div>
                    <div className="content">
                      <h5>
                        {eventData?.userId?.firstName}{" "}
                        {eventData?.userId?.lastName}
                      </h5>
                      <h6>{eventData?.userId?.category}</h6>
                      <div className="rating">
                        <span className="like-con-book">
                          <img src={IconLike} alt="" />
                          85 %
                        </span>
                      </div>
                      <p className="p-box">{eventData?.userId?.description}</p>
                    </div>
                  </div>
                </div> */}
                {/* </div> */}
                {/* <div className="outer-wrappper-1">
                  <div className="preview-img-wrap">
                    <div
                      className="preview-con"
                      style={{ position: "relative" }}
                    >
                      {eventData.videoStatus ? (
                        <>
                          <div
                            className="cursorPointer video-mute-div"
                            onClick={() => handleVideoMute()}
                          >
                            {videoMute ? (
                              <img src={iconMute} />
                            ) : (
                              <img src={iconUnMute} />
                            )}
                          </div>
                          <video
                            // minHeight="480"
                            height="480"
                            width="100%"
                            // controls
                            autoPlay={true}
                            loop={true}
                            muted={videoMute}
                          >
                            <source src={eventData?.eventImage} />
                          </video>
                        </>
                      ) : (
                        // <img
                        //   src={
                        //     eventData?.videoStatus
                        //       ? eventData?.thumbnailImage
                        //       : eventData?.eventImage
                        //   }
                        //   alt="User"
                        // />

                        <div
                          className="eventImage"
                          style={{
                            // paddingTop: "0px",
                            minHeight: "480px",
                            //   width: "100%",
                            backgroundImage: `url(${
                              eventData?.videoStatus
                                ? eventData?.thumbnailImage
                                : eventData?.eventImage
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
                {/* <div className="banner-wrap">
                <div
                  className="content banner-content-box"
                  style={{ width: "100%" }}
                >
                  <div className="enduser-artist" style={{ marginTop: "0px" }}>
                    <h4>Artist</h4>
                    <div className="row-content"> */}
                {/* <div className="img">
                      <img
                        src={
                          eventData?.userId?.profilePhoto
                            ? eventData.userId?.profilePhoto
                            : dpPhoto
                        }
                        alt="User"
                      />
                    </div> */}
                {/* <div
                        // className="eventImage"
                        style={{
                          // paddingTop: "0px",
                          height: "200px",
                          width: "65%",
                          backgroundImage: `url(${
                            eventData?.userId?.profilePhoto
                              ? eventData.userId?.profilePhoto
                              : dpPhoto
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div className="content">
                        <h5>
                          {eventData?.userId?.firstName}{" "}
                          {eventData?.userId?.lastName}
                        </h5>
                        <h6>{eventData?.userId?.category}</h6>
                        <div className="rating">
                          <span
                            className="like-con-book"
                            style={{ marginBottom: "0px", cursor: "auto" }}
                          >
                            <img src={IconLike} alt="" />
                            {eventData.artistRating} %
                          </span>
                        </div>
                        <div className="description-scroll">
                          <p
                            className="p-box"
                            style={{
                              maxWidth: "500px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {eventData?.userId?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>

              <div className="events-listing enduser-events">
                <div className="title">
                  <h2>Upcoming Events</h2>
                  <a
                    className="viewall cursorPointer"
                    onClick={() => {
                      // handleViewAll(catData.category)
                      // localStorage.removeItem("notiEventID");
                      dispatch({ type: "EMPTY_EVENTID" });
                      setSelectedTab(2);
                    }}
                  >
                    View All
                  </a>
                </div>

                <div className="flex-row gutter-space">
                  {allEventsData.map((data, index) => (
                    <div className="loop flex-col-4" key={index}>
                      <div
                        className="grey-wrap item cursorPointer"
                        onClick={() => {
                          setEventIdChange(data._id);
                          // history.push(`/home/user/eventDetails/${eventItem._id}`)
                          setEventID(data._id);
                          // setSelectedTab(7);
                        }}
                      >
                        <div className="img">
                          <div
                            onMouseOver={(e) => {
                              e.target.style.backgroundImage = `url(${
                                data.videoStatus ? data.gifURL : data.eventImage
                              })`;
                            }}
                            onMouseOut={(e) => {
                              e.target.style.backgroundImage = `url(${
                                data.videoStatus
                                  ? data.thumbnailImage
                                  : data.eventImage
                              })`;
                            }}
                            style={{
                              minHeight: "150px",
                              width: "100%",
                              backgroundImage: `url(${
                                data.videoStatus
                                  ? data.thumbnailImage
                                  : data.eventImage
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                          {data.videoStatus && (
                            <div
                              style={{
                                position: "absolute",
                                top: "155px",
                              }}
                            >
                              <img src={iconPlay} style={{ width: "40px" }} />
                            </div>
                          )}
                        </div>

                        <div className="details">
                          <div className="name">
                            {/* <h3>{eventItem.eventTitle}</h3> */}
                            <h3 title={data.eventTitle}>
                              {data.eventTitle.length > 19
                                ? data.eventTitle.slice(0, 19) + "..."
                                : data.eventTitle}
                            </h3>
                            <span>
                              €
                              {data.earlyBirdTicket > 0
                                ? parseFloat(
                                    // data.earlyBirdTicketPrice
                                    data.newEarlyBirdPrice
                                  ).toFixed(2)
                                : parseFloat(
                                    // data.price
                                    data.newPrice
                                  ).toFixed(2)}
                            </span>
                          </div>
                          <div className="ticket">{(`${data.userId.firstName} ${data.userId.lastName}`).length > 28 ? 
                              (`${data.userId.firstName} ${data.userId.lastName}`).slice(0, 28) + "..." : `${data.userId.firstName} ${data.userId.lastName}`}</div>
                          {data.earlyBirdTicket > 0 && (
                            <div className="ticket">
                              Earlybird Tickets Available
                              <span>{data.earlyBirdTicket}</span>
                            </div>
                          )}
                          <div className="ticket">
                            Tickets Available
                            <span>
                              {/* {data.availableTickets} */}
                              {data.ticketType === "Unlimited" ||
                              data.audienceType === "Corporate"
                                ? "Available"
                                : data?.availableTickets}
                            </span>
                          </div>
                          <div className="date-time">
                            {getEventLocalDate(
                              data.eventDate,
                              data.eventTime,
                              data.setTimeZone
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="events-listing enduser-events">
                <div className="title">
                  <h2>Trending Events</h2>
                  <a
                    className="viewall cursorPointer"
                    onClick={() => {
                      // handleViewAll(catData.category)
                      // localStorage.removeItem("notiEventID");
                      dispatch({ type: "EMPTY_EVENTID" });
                      setSelectedTab(2);
                    }}
                  >
                    View All
                  </a>
                </div>

                <div className="flex-row gutter-space">
                  {trendingData.map((data, index) => (
                    <div className="loop flex-col-4" key={index}>
                      <div
                        className="grey-wrap item cursorPointer"
                        onClick={() => {
                          setEventIdChange(data._id);
                          // history.push(`/home/user/eventDetails/${eventItem._id}`)
                          setEventID(data._id);
                          // setSelectedTab(7);
                        }}
                      >
                        <div className="img">
                          <div
                            onMouseOver={(e) => {
                              e.target.style.backgroundImage = `url(${
                                data.videoStatus ? data.gifURL : data.eventImage
                              })`;
                            }}
                            onMouseOut={(e) => {
                              e.target.style.backgroundImage = `url(${
                                data.videoStatus
                                  ? data.thumbnailImage
                                  : data.eventImage
                              })`;
                            }}
                            style={{
                              minHeight: "150px",
                              width: "100%",
                              backgroundImage: `url(${
                                data.videoStatus
                                  ? data.thumbnailImage
                                  : data.eventImage
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                          {data.videoStatus && (
                            <div
                              style={{
                                position: "absolute",
                                top: "155px",
                              }}
                            >
                              <img src={iconPlay} style={{ width: "40px" }} />
                            </div>
                          )}
                        </div>

                        <div className="details">
                          <div className="name">
                            {/* <h3>{eventItem.eventTitle}</h3> */}
                            <h3 title={data.eventTitle}>
                              {data.eventTitle.length > 19
                                ? data.eventTitle.slice(0, 19) + "..."
                                : data.eventTitle}
                            </h3>
                            <span>
                              €
                              {data.earlyBirdTicket > 0
                                ? parseFloat(
                                    // data.earlyBirdTicketPrice
                                    data.newEarlyBirdPrice
                                  ).toFixed(2)
                                : parseFloat(
                                    // data.price
                                    data.newPrice
                                  ).toFixed(2)}
                            </span>
                          </div>
                          <div className="ticket">{(`${data.userId.firstName} ${data.userId.lastName}`).length > 28 ? 
                              (`${data.userId.firstName} ${data.userId.lastName}`).slice(0, 28) + "..." : `${data.userId.firstName} ${data.userId.lastName}`}</div>
                          {data.earlyBirdTicket > 0 && (
                            <div className="ticket">
                              Earlybird Tickets Available
                              <span>{data.earlyBirdTicket}</span>
                            </div>
                          )}
                          <div className="ticket">
                            Tickets Available
                            <span>
                              {/* {data.availableTickets} */}
                              {data.ticketType === "Unlimited" ||
                              data.audienceType === "Corporate"
                                ? "Available"
                                : data?.availableTickets}
                            </span>
                          </div>
                          <div className="date-time">
                            {getEventLocalDate(
                              data.eventDate,
                              data.eventTime,
                              data.setTimeZone
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER, NOTIF_REDUCER }) => {
  return {
    role: USER_REDUCER.userData.role,
    notifEID: NOTIF_REDUCER.notiEventID,
  };
};

export default connect(mapStateToProps, {})(EventDetails);
