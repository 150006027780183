import Axios from "axios";
import store from "../store/store";
import { LogOutAction } from "../actions/user";
import { Notify } from "../../helpers/notifications";
import { REACT_APP_BASE_URL } from "../../helpers/env";

// BaseURL of Backend Server
export const baseURL = {
  // URL: "https://petros-backend.n4.iworklab.com/", // client build (SSL)
  // URL: "https://petros-backend.n1.iworklab.com/" // dev build (SSL)
  // URL: "http://petros-backend.n4.iworklab.com:3011/" // client build
  // URL: "http://petros-backend.n1.iworklab.com:3011/" // dev build
  URL: REACT_APP_BASE_URL
};

// Axios instance with a max timeout of 10s for Api pending request
const Api = () => {
  const defaultOptions = {
    baseURL: baseURL.URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // timeout: 100000,
  };

  let instance = Axios.create(defaultOptions);

  // Set the AUTH token for any request
  // instance.interceptors.request.use(function (config) {
  //   const token = localStorage.getItem("petros-user-token");
  //   if (token) {
  //     config.headers["x-auth-token"] = token;
  //   }
  //   return config;
  // });

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      console.log("From API.JS",err.response);
      const message = err.response.data;
      const status = err.response.status;

      console.log(status);
      if (status === 401 && localStorage.getItem("isLogout") == "false") {
        localStorage.setItem("isLogout", true);
        store.store.dispatch(LogOutAction());
        Notify("danger", `${"Session expired"}`)
      }
      return Promise.reject(err);
    }
  );

  return instance;
};

export default Api();
