import React, { useEffect, useState, useReducer, useRef } from "react";
import { OTSession, OTPublisher, preloadScript } from "opentok-react";
import viewerCountIcon from "../../assets/img/viewerCount.svg";
import likesCountIcon from "../../assets/img/likesCount.svg";
import iconChat from "../../assets/img/icon-chat.svg";
import iconShared from "../../assets/img/icon-shared.svg";
import iconEnd from "../../assets/img/icon-end.svg";
import iconHeartRed from "../../assets/img/icon-heartred.svg";
import microphoneIcon from "../../assets/img/microphone.svg";
import videoTestIcon from "../../assets/img/vedio-test.svg";
import iconSettings from "../../assets/img/settings.svg";
import iconVideo from "../../assets/img/video-camera.svg";
import iconVideoMute from "../../assets/img/no-video.svg";
import iconMic from "../../assets/img/microphone-filled.svg";
import iconMicMute from "../../assets/img/mute-microphone.svg";
// import iconTip from "../../assets/img/tip-icon.svg";
import iconTip from "../../assets/img/3x.png";
import addThumb from "../../assets/img/add-thumb.svg";
import watchIcon from "../../assets/img/chronograph-watch.png";
import { connect } from "react-redux";
import $ from "jquery";
import { useHistory } from "react-router";
import {
  StartBroadcast,
  CloseEvent,
  EventReportReasonList,
  ReportComment,
  GetViewerCount,
} from "../../redux/actions/events";
import { numFormatter } from "../../helpers/constants";
import ShareEvent from "../actor/shareEvent";
import firebase from "../../helpers/firebase";
import "firebase/firestore";
import { REACT_APP_BASE_URL } from "../../helpers/env";
import { FileUpload } from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import Countdown from "react-countdown";
import Comments from "./Comments";
import { Validation } from "../../helpers/validations";
import Loader from "../loader/Loader";
import Logo from "react-loader-spinner";
import Store from "../../redux/store/store";
import PreCallTestVid from "./PreCallTestVid";
import PreCallTestAudio from "./PreCallTestAudio";

const db = firebase.firestore();

const giphyFetch = new GiphyFetch("4uPoE4bDsAewB6nPcayJeWpaJLddLUIj");

function GridDemo({ onGifClick }) {
  const fetchGifs = (offset) => giphyFetch.trending({ offset, limit: 10 });
  // const [width, setWidth] = useState("380");
  // console.log(window.innerWidth, "inner Width");
  return (
    <>
      <Grid
        onGifClick={onGifClick}
        fetchGifs={fetchGifs}
        width={378}
        columns={3}
        gutter={6}
      />
    </>
  );
}

function updateCount(state, action) {
  switch (action.type) {
    case "increment":
      // return { ...state, subsCount: state.subsCount + 1 };
      return { ...state, subsCount: action.payload ? action.payload : 1 };
    // case "decrement":
    //   return { ...state, subsCount: state.subsCount - 1 };
    case "updateMessages":
      return { ...state, messages: [...state.messages, ...action.payload] };
    // return { ...state, messages: [...action.payload] };
    case "incrementLikes":
      console.log("incrementing")
      return { ...state, likesCount: state.likesCount + 1 };
    case "incrementTip":
      return {
        ...state,
        tippedAmount:
          parseFloat(state.tippedAmount) + parseFloat(action.payload),
      };
    default:
      throw new Error();
  }
}

function Publisher({
  apiKey,
  sessionId,
  token,
  userData,
  eventId,
  eventEndTime,
  shareEventURL,
  messageArray,
  likesCountSaved,
  eventID,
  eventStartTime
}) {
  // console.log(apiKey, sessionId, token, eventEndTime)

  const sessionRef = useRef();
  const publisherRef = useRef();

  const history = useHistory();

  const [state, dispatch] = useReducer(updateCount, {
    subsCount: 0,
    messages:
      messageArray?.length > 0 && eventID === eventId ? messageArray : [],
    likesCount: likesCountSaved > 0 ? likesCountSaved : 0,
    tippedAmount: 0.0,
  });

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [msgText, setMsgText] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showEndSession, setShowEndSession] = useState(false);
  const [showHardware, setShowHardware] = useState(true);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioSource, setAudioSource] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [heartLike, setHeartLike] = useState(false);
  const [startSession, setStartSession] = useState(false);
  const [broadCastID, setBroadCastID] = useState("");
  const [showLive, setShowLive] = useState(false);
  const [showGoLive, setShowGoLive] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const [eReportReason, setEReportReason] = useState("");
  const [eReportNote, setEReportNote] = useState("");
  const [eReportError, setEReportError] = useState("");
  const [eReasonList, setEReasonList] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentBy, setCommentBy] = useState("");
  const [isResponse, setIsResponse] = useState(false);
  const [endSessionResponse, setEndSessionResponse] = useState(false);
  const [showBackToHome, setShowBackToHome] = useState(false);
  const [isReportComment, setIsReportComment] = useState(false);
  const [viewC, setViewC] = useState(0);
  const [tipC, setTipC] = useState(0.0);
  const [eventLikes, setEventLikes] = useState(0);
  const [openVidTest, setOpenVidTest] = useState(false);
  const [openAudioTest, setOpenAudioTest] = useState(false);
  const [isCancelledByAdmin, setIsCancelledByAdmin] = useState(false);
  const [showGoLiveError, setShowGoLiveError] = useState(false);
  const [intervalId,setIntervalId] = useState()
  const [sessionEnd,setSessionEnd] = useState(false)
  // Window Prompt incase Actor reloads, closes tab/ closes browser while LIVE (beforeunload, unload, sendBeacon())

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "";
      return "Are you sure you want to leave?";
    });

    return () => {
      window.removeEventListener("beforeunload", function (e) {
        e.preventDefault();
        e.returnValue = "";
        return "Are you sure you want to leave?";
      });
    };
  }, []);

  useEffect(() => {
    document.title = "LiveStage";
    localStorage.setItem("flag", "false");

    if (showLive) {
      GetViewerCount({ eventId: eventId,role: "actor" })
        .then((response) => {
          // setViewC(response.data.count);
          setTipC(response.data.tipAmt);
          setEventLikes(response.data.likeCount);
          setIsCancelledByAdmin(response.data.isCancelledByAdmin);
        })
        .catch((error) => console.log(error));
    }

    if (eventID !== eventId) {
      Store.store.dispatch({ type: "EMPTY_CHAT" });
      Store.store.dispatch({ type: "SET_EVENTID", payload: eventId });
    }
  }, [showLive]);

  useEffect(()=>{
    if (showBackToHome) clearInterval(intervalId);
  },[showBackToHome])

  useEffect(() => {
    const fetchData = async () => {
      // if (showLive) {
        await GetViewerCount({ eventId: eventId,role: "actor" })
          .then((response) => {
            console.log(response.data);
            // if(response.data.streaming){
   //         setViewC(response.data.count);
            setTipC(response.data.tipAmt);
            setEventLikes(response.data.likeCount);
            setIsCancelledByAdmin(response.data.isCancelledByAdmin);
            // }
            // else{
            //   db.collection("chatrooms")
            //   .doc(`${eventId}`)
            //    .collection("Viewers")
            //    .doc('eventStatus')
            //     .update({
            //       streaming:false,
            //       });
            //   setShowLive(false);
            //   setShowBackToHome(true);
            // }
          
          })
          .catch((error) => 
          {
            // setShowLive(false);
            // setShowBackToHome(true);
            console.log("getViewerCount API",error)});
      }
    // };

    // // GET Viewers
    // db.collection("chatrooms")
    //    .doc(`${eventId}`)
    //    .collection("Viewers")
    //    .onSnapshot((querySnapshot) => {
    //      if (!querySnapshot.metadata.hasPendingWrites) {
    //        const data = querySnapshot.docs.filter((doc) => {
    //          const timeStamp = doc.data()?.timeStamp?.toDate().getTime();
    //          const now = new Date().getTime();
    //          return now < timeStamp + 30000
    //         });
    //        console.log("***Get Viewer count***",data.length);
    //        setViewC(data.length);
    //      }
    //    });

    const fetchViewerData = ()=>{
      db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("Viewers")
      .doc('eventStatus')
      .update({
        actorTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
      db.collection("chatrooms")
       .doc(`${eventId}`)
       .collection("Viewers")
       .doc("eventStatus")
       .get().then((checkEventStatus) => {
         if(checkEventStatus?.data()?.isCancelledByAdmin) setIsCancelledByAdmin(true);
         if(checkEventStatus?.data()?.streaming === false) {
            setShowLive(false);
            setShowBackToHome(true);
         };
         setViewC(checkEventStatus?.data()?.count);
         console.log("***Get Viewer count***",checkEventStatus?.data()?.count);
        })
        .catch((error)=>{
          console.log("Firebase error",error);
        })
      // db.collection("chatrooms")
      // .doc(`${eventId}`)
      // .collection("Viewers")
      // .get().then((docData) => {
      //     const data = docData.docs.filter((doc) => {
      //         const timeStamp = doc.data()?.timeStamp?.toDate().getTime();
      //         const now = new Date().getTime();
      //           return now < timeStamp + 30000
      //         });
      //     console.log("***Get Viewer count***",data.length);
      //     setViewC(data.length);
      //   });
     }
    //  fetchViewerData();  
    if (showLive) {
      const clearId = setInterval(() => {
        fetchData();
        fetchViewerData();
      }, 10000);
      setIntervalId(clearId)
      return () => clearInterval(clearId);
    }
  }, [showLive]);

  // useEffect(() => {
  //   if(state.likesCount < eventLikes) {
  //     // console.log("in useEffect");
  //     for(let i=0; i < (eventLikes - state.likesCount); i++) {
  //       console.log("in for loop", i);
  //       // dispatch({ type: "incrementLikes" });
  //     }
  //   }
  // }, [eventLikes]);

  useEffect(() => {
    if (showHardware) {
      window.OT.getDevices((error, devices) => {
        if (error) {
          console.log("get Devices error: ", error);
          return;
        }
        console.log(devices);
        const audioDevice = devices.filter(
          (device) => device.kind === "audioInput"
        );
        const videoDevice = devices.filter(
          (device) => device.kind === "videoInput"
        );

        setAudioDevices(audioDevice);
        setVideoDevices(videoDevice);
      });
    }
  }, [showHardware]);

  // useEffect(() => {
  //   if (showLive) {
  //   setTimeout (() => {
  //     console.log(showGoLive, showLive)
  //     let divTopPos = document.getElementById("commentScrolling");
  
  //     //  divTopPos &&
  //     //    $("#commentScrolling").scrollTop($("#commentScrolling").scrollHeight);
  
  //      if (divTopPos) {divTopPos.scrollTop = 10000}
      
  //     console.log($("#commentScrolling").scrollHeight)
  //   }, 1500)}
  // }, [showLive]);

  useEffect(() => {
    let divTopPos = document.getElementById("commentScrolling");
     divTopPos &&
       $("#commentScrolling").scrollTop($("#commentScrolling")[0].scrollHeight);
    // if (divTopPos) {divTopPos.scrollTop = 10000}
    // console.log($("#commentScrolling").scrollHeight)
  }, [state.messages,showChat,showLive]);

  useEffect(() => {
    console.log("likes count ", state.likesCount);
    // $(".like-auctions").on("click", function () {
    
      if(state.likesCount < eventLikes) {
        console.log("in useEffect likes count");
        for(let i=0; i < (eventLikes - state.likesCount); i++) {
        console.log("in for loop likes count", i);

        dispatch({ type: "incrementLikes" });
      (() => {
      var b = Math.floor(Math.random() * 100 + 1);
      var d = ["flowOne", "flowTwo", "flowThree"];
      var a = ["colOne", "colTwo", "colThree", "colFour", "colFive", "colSix"];
      var c = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);
      $(
        '<div class="heartanim part-' +
          b +
          " " +
          a[Math.floor(Math.random() * 6)] +
          '" style="font-size:' +
          Math.floor(Math.random() * (50 - 22) + 22) +
          `px;"><img src=${iconHeartRed} alt="chat"></div>`
      )
        .appendTo(".hearts")
        .css({
          animation:
            "" + d[Math.floor(Math.random() * 3)] + " " + c + "s linear",
        });
      $(".part-" + b).show();
      setTimeout(function () {
        $(".part-" + b).remove();
      }, c * 900);
    })();
  }}

  }, [eventLikes]);

  // Getting messages from firebase

  useEffect(() => {
    if (db) {
      console.log("DB updated");
      // GET MESSAGES

      const unsubscribe = db
        .collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .orderBy("createdAt", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.metadata.hasPendingWrites) {
            const data = querySnapshot.docs.map((doc) => {
              // console.log(doc.id)
              // console.log(doc.data())
              if (doc.data().type === "userMessage") {
                return doc.data().data;
              } else if (doc.data().type === "tipArtist") {
                // dispatch({ type: "incrementTip", payload: doc.data().data.tippedAmount});
                return doc.data().data;
              } else if (doc.data().type === "chatImage") {
                return doc.data().data;
              } else if (doc.data().type === "endSession") {
                console.log("Ending Session");
              } else if (doc.data().type === "autoEndSession") {
                handleEndSession();
              } else if (doc.data().type === "likesCount") {
                dispatch({ type: "incrementLikes" });
                // Store.store.dispatch({ type: "LIKES_COUNT" });
                // console.log(doc.data())
                // return {
                //   firstName: doc.data().data.firstName,
                //   lastName: doc.data().data.lastName,
                //   msgText: "❤️",
                //   profilePhoto: doc.data().data.profilePhoto,
                //   timeStamp: doc.data().data.timeStamp,
                //   userId: doc.data().data.userId
                // }
              }
            });
            console.log(data);
            if (localStorage.getItem("flag") !== "false") {
              if (data[0]) {
                dispatch({ type: "updateMessages", payload: data });
                Store.store.dispatch({ type: "CHAT_MESSAGES", payload: data });
                if (data[0]?.tippedAmount) {
                  dispatch({
                    type: "incrementTip",
                    payload: data[0].tippedAmount,
                  });
                }
              }
            } else {
              localStorage.setItem("flag", "true");
            }
          }
        });

      return unsubscribe;
    }
  }, [db, handleEndSession, showLive, broadCastID]);

  useEffect(() => {
    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .doc("LiveViewers")
      .set({
        type: "viewerCount",
        liveCount: firebase.firestore.FieldValue.increment(1),
        totalCount: firebase.firestore.FieldValue.increment(1),
        streaming: true,
      });

    return () => {
      // db.collection("chatrooms")
      //   .doc(`${eventId}`)
      //   .collection("messages")
      //   .doc("LiveViewers")
      //   .update({
      //     streaming: false
      //   });
      localStorage.setItem("flag", "false");
    };
  }, []);

  useEffect(() => {
    if(isCancelledByAdmin){
      sessionRef?.current?.sessionHelper.session.disconnect();
      setShowBackToHome(true);
    }
  }, [isCancelledByAdmin]);

  // useEffect(() => {
  //   // setTestRemoveViewer(true);
  //   // console.log("useEffect")
  //   setTimeout(() => {
  //     localStorage.setItem("testRemoveActor", true);
  //   }, 500);

  //   return () => {
  //     // localStorage.getItem("testRemoveActor") && RemoveViewer({eventId: eventId});
  //     localStorage.getItem("testRemoveActor") && history.push("/home/actor");
  //     // console.log("useEffefct cleanup")
  //     localStorage.removeItem("testRemoveActor");
  //   }
  // }, []);

  // Get liveviewer count
  useEffect(() => {
    if (db) {
      console.log("getting data for live viewers");
      // const unsubscribe = db.collection("chatrooms")
      //   .doc(`${eventId}`)
      //   .collection("messages")
      //   .doc("LiveViewers")
      //   .get()
      //   .then((snapshot) => {
      //     console.log(snapshot.data())
      //   })
      //   .catch(error => console.log(error));

      const unsubscribe = db
        .collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .doc("LiveViewers")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.metadata.hasPendingWrites) {
            console.log("Viewer count object", querySnapshot.data());
            dispatch({
              type: "increment",
              payload: querySnapshot.data()?.liveCount,
            });
          }
        });

      return unsubscribe;
    }
  }, [db]);

  useEffect(() => {
    EventReportReasonList()
      .then((response) => {
        console.log(response.data);
        setEReasonList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const SessionOnConnect = () => {
    console.log("SessionOnConnect ==>>  ", sessionRef, publisherRef);
  };

  const SessionOnError = (error) => {
    console.log("SessionOnError ==>>  ", error);
  };

  const sessionEventHandlers = {
    signal: (event) => {
      console.log(event);
      // // console.log("Signal sent from connection " + event.from.id);
      // if (event.type === "signal:userMessage") {
      //   // console.log("Some message data", event.data)
      //   // console.log("Connection Object",publisherRef.current.getPublisher().stream.connection)
      //   // const myConnectionId = publisherRef.current.getPublisher().stream
      //   //   .connection.connectionId;
      //   // const updatedMessage =
      //   //   event.from.connectionId === myConnectionId
      //   //     ? [{ data: event.data.msgText }]
      //   //     : [{ data: event.data.msgText }];
      //   // console.log(myConnectionId, updatedMessage);
      //   const updatedMessage = [
      //     {
      //       firstName: event.data.firstName ? event.data.firstName : "",
      //       lastName: event.data.lastName ? event.data.lastName : "",
      //       msgText: event.data.msgText ? event.data.msgText : "",
      //       timeStamp: event.data.timeStamp ? event.data.timeStamp : "",
      //       userId: event.data.userId ? event.data.userId : "",
      //       profilePhoto: event.data.profilePhoto
      //         ? event.data.profilePhoto
      //         : "",
      //     },
      //   ];
      //   dispatch({
      //     type: "updateMessages",
      //     payload: updatedMessage,
      //   });
      // } else if (event.type === "signal:eventLike") {
      //   dispatch({
      //     type: "incrementLikes",
      //   });
      // } else if (event.type === "signal:tipArtist") {
      //   console.log(event.data);

      //   const updatedMessage = [
      //     {
      //       firstName: event.data.firstName ? event.data.firstName : "",
      //       lastName: event.data.lastName ? event.data.lastName : "",
      //       tippedAmount: event.data.tippedAmount
      //         ? event.data.tippedAmount
      //         : "",
      //       timeStamp: event.data.timeStamp ? event.data.timeStamp : "",
      //       userId: event.data.userId ? event.data.userId : "",
      //       profilePhoto: event.data.profilePhoto
      //         ? event.data.profilePhoto
      //         : "",
      //     },
      //   ];

      //   dispatch({ type: "updateMessages", payload: updatedMessage });
      //   dispatch({ type: "incrementTip", payload: event.data.tippedAmount });
      // } else {
      //   console.log("No signal sent yet!!");
      // }
    },
    connectionCreated: (data) => {
      // console.log("connected person =>", data);
      //call notification here---
      // dispatch({ type: "increment" });
    },
    connectionDestroyed: (data) => {
      // dispatch({ type: "decrement" });
      // console.log("disconnected=>", data);
    },
  };

  const publisherEventHandlers = {
    streamCreated: (event) => {
      console.log("Publisher stream created!", event);
    },
    streamDestroyed: (event) => {
      console.log("Publisher stream destroyed!", event);
    },
  };

  const sendMsgSignal = () => {
    const trimmedMsg = msgText.trim();
    if (trimmedMsg) {
      let dateStamp = new Date();
      // sessionRef.current.sessionHelper.session.signal({
      //   type: "userMessage",
      //   data: {
      //     msgText,
      //     firstName: userData.firstName,
      //     lastName: userData.lastName,
      //     userId: userData._id,
      //     profilePhoto: userData.profilePhoto,
      //     timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes()}`,
      //   },
      // });

      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .add({
          type: "userMessage",
          data: {
            msgText: trimmedMsg,
            firstName: userData.firstName,
            lastName: userData.lastName,
            userId: userData._id,
            profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
            timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0"+dateStamp.getMinutes() : dateStamp.getMinutes()}`,
          },
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          console.log("Message sent successfully!!");
          setMsgText("");
        })
        .catch((error) => {
          console.log(error, "message not sent");
          setMsgText("");
        });
    }
  };

  const sendImageSignal = (imageUrl) => {
    let dateStamp = new Date();

    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .add({
        type: "chatImage",
        data: {
          image: imageUrl,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userId: userData._id,
          profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
          timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0"+dateStamp.getMinutes() : dateStamp.getMinutes()}`,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        console.log("Image sent successfully!!");
        setShowGif(false);
      })
      .catch((error) => {
        console.log(error, "Image not sent");
      });
  };

  const sendEndSession = () => {
    let dateStamp = new Date();

    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .add({
        type: "endSession",
        data: {
          msgText: "Close Event",
          firstName: userData.firstName,
          lastName: userData.lastName,
          userId: userData._id,
          profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
          timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0"+dateStamp.getMinutes() : dateStamp.getMinutes()}`,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        console.log("Event End Signal sent successfully!!");
        // db.collection("chatrooms")
        //   .doc(`${eventId}`)
        //   .collection("messages")
        //   .doc("nu8ZI72q1GWy0QBvuMwY")
        //   .delete()
        //   .then(() => {
        //     console.log("success");
        setEndSessionResponse(false);
        setShowEndSession(false);
        setShowBackToHome(true);
        // localStorage.removeItem("uniqueTokenforStreaming");
        // document.cookie = "petros-session-id=";
        // document.cookie = "petros-unique-id=";

        localStorage.removeItem("petros-session-id");
        localStorage.removeItem("petros-unique-id");
        Store.store.dispatch({ type: "EMPTY_CHAT" });
        // history.push("/home/actor");

        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      })
      .catch((error) => {
        console.log(error, "Event End Signal not sent");
      });
  };

  // useEffect(() => {
  //   if (state.subsCount > 0) {
  //     sendViewerCountSignal();
  //   }
  // }, [state.subsCount]);

  // For Update ViewerCount in End User
  // const sendViewerCountSignal = () => {
  //   sessionRef.current.sessionHelper.session.signal({
  //     type: "viewerCount",
  //     data: state.subsCount,
  //   });
  // };

  // For Likes
  // const sendEventHeartSignal = () => {
  //   sessionRef.current.sessionHelper.session.signal({
  //     type: "eventLike",
  //   });
  // };

  // useEffect(() => {
  //   console.log("showLive", showLive, broadCastID);
  // }, [showLive, broadCastID]);

  const handleEndSession = async () => {
    console.log("SESS", showLive, broadCastID);

    setSessionEnd(true)
    if (!showLive) {
      console.log("Show Live False", showLive, broadCastID);
      sessionRef?.current?.sessionHelper.session.disconnect();
      setShowEndSession(false);
      setShowBackToHome(true);
      localStorage.removeItem("petros-unique-id");
      // history.push("/home/actor");
    } else {
      console.log("Show Live true", showLive);
      setEndSessionResponse(true);

      let payload = {
        eventId: eventId,
        broadcastId: broadCastID,
      };

      console.log(payload);

      try {
        console.log("Close Event Try Catch");
        const response = await CloseEvent(payload);

        if (response && response.status === 200) {
          sessionRef.current.sessionHelper.session.disconnect();
          sendEndSession();
        }
      } catch (error) {
        console.log(error);
        sessionRef?.current?.sessionHelper.session.disconnect();
        setEndSessionResponse(true);
        setShowBackToHome(true);
        // history.push("/home/actor");
      }
    }
  };

  // useEffect(() => {
  //   console.log(sessionRef);
  //   console.log(publisherRef);
  // }, [sessionRef, publisherRef]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (startSession) {
        console.log("printed after 5 sec");
        setShowGoLive(true);
      }
    }, 6000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [startSession]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (startSession) {
        console.log("printed error msg on go live");
        setShowGoLiveError(true);
      }
    }, (eventStartTime*1000 - +new Date() < 0) ? 6000 : (eventStartTime*1000 - +new Date()) - 300000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [startSession]);

  useEffect(() => {
    window.addEventListener("offline", (event) => {
      db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("Viewers")
      .doc('eventStatus')
      .update({
        streaming:false,
      });
      setShowLive(false);
      setShowBackToHome(true);
    });
  }, []);

  const startBroadcast = async () => {
    setIsResponse(true);

    let payload = {
      sessionId: sessionId,
      eventId: eventId,
      fcmToken: localStorage.getItem("uniqueTokenforStreaming"),
      deviceType: "web",
    };

    console.log(payload);

    try {
      const response = await StartBroadcast(payload);

      if (response && response.status === 200) {
        if(response.data?.data.broadCast){
          db.collection("chatrooms")
          .doc(`${eventId}`)
          .collection("messages")
          .doc("LiveViewers")
          .update({
            streaming: true,
          });    
      db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("Viewers")
      .doc('eventStatus')
      .set({
        streaming:true,
        count:0,
        actorTimeStamp: firebase.firestore.FieldValue.serverTimestamp()
      });
        setIsResponse(false);
  
        console.log(response.data.data);
        // localStorage.setItem("broadCastUrl", response.data.data.broadCast.broadcastUrls.hls);
         setBroadCastID("Broadcast_Id");
        setShowChat(true);
        setShowLive(true);
        dispatch({
          type: "incrementTip",
          payload: response.data.data.tipAmt,
        });
        // document.cookie = `petros-session-id=${response.data.data.broadCast.id}`;
        // document.cookie = `petros-unique-id=${response.data.data.uniqueId}`;
        localStorage.setItem(
          "petros-session-id",
          sessionId
        );
        localStorage.setItem("petros-unique-id", response.data.data.uniqueId); 
        }
       else{
        setIsResponse(false);
          Notify("danger",response.data.message);
          history.push("/home/actor");  
       }
      }
    } catch (error) {
      console.log(error);
      setIsResponse(false);
        // if(error.response.data.message === "Session already started") {
      Notify("danger", `${error.response.data.message}`);
        history.push("/home/actor");
    }
  };

  const handleImage = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        if (file.size > 2048576) {
          // setError({ ...error, photo: "File size is greater than 1 MB" });
          return;
        } else {
          // let preview = URL.createObjectURL(file);
          // setPhotoPreview(preview);

          const formdata = new FormData();
          formdata.append("image", file);

          try {
            const response = await FileUpload(formdata);

            if (response && response.status === 200) {
              // sendImageSignal(`${REACT_APP_BASE_URL}${response.data.fileName}`);
              sendImageSignal(response.data.fileName);
            }
          } catch (error) {
            console.log(error);
            Notify("danger", "Error sending image");
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const countdownRenderer = ({ hours, minutes, seconds }) => {
    return (
      <span style={{ fontSize: "25px" }}>
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  // Comment Report
  const handleCommentReport = async (e) => {
    // e.preventDefault();
    console.log("In event Reporting");

    if (Validation.empty(eReportReason)) {
      setEReportError("Please select a reason to Report Comment");
      return;
    }

    let payload = {
      reason: eReportReason,
      note: eReportNote,
      eventId: eventId,
      commentText: commentText,
      commentedBy: commentBy,
    };

    console.log(payload);

    try {
      const response = await ReportComment(payload);

      if (response && response.status === 200) {
        Notify("success", "Comment reported!!");
        setEReportReason("");
        setEReportNote("");
        setShowReportForm(false);
        setCommentBy("");

        db.collection("chatrooms")
          .doc(`${eventId}`)
          .collection("messages")
          .add({
            type: "blockUser",
            data: {
              userId: commentBy,
            },
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            console.log("Blocked successfully!!");
            setMsgText("");
          })
          .catch((error) => {
            console.log(error, "message not sent");
          });
      }
    } catch (error) {
      console.log(error);
      Notify("danger", `${error.response.data.message}`);
      setEReportReason("");
      setEReportNote("");
      setShowReportForm(false);
      setCommentBy("");
    }
  };

  return (
    <div>
      <div id="app" className="page-video">
        <div className="live-video">
          {startSession && (
            <OTSession
              apiKey={apiKey}
              sessionId={sessionId}
              token={token}
              onConnect={() => SessionOnConnect()}
              onError={(error) => SessionOnError(error)}
              eventHandlers={sessionEventHandlers}
              ref={sessionRef}
            >
              <OTPublisher
                eventHandlers={publisherEventHandlers}
                properties={{
                  resolution: "1920x1080",
                  frameRate: 30,
                  maxResolution: { width: 1920, height: 1080 },
                  publishAudio: audio,
                  publishVideo: video,
                  audioSource: audioSource
                    ? audioSource
                    : audioDevices[0]?.deviceId &&
                      setAudioSource(audioDevices[0]?.deviceId),
                  videoSource: videoSource
                    ? videoSource
                    : videoDevices[0]?.deviceId &&
                      setVideoSource(videoDevices[0]?.deviceId),
                  fitMode: "contain",
                  enableStereo: true,
                  audioBitrate: 325000,
                  disableAudioProcessing: true,
                  autoGainControl: false,
                  echoCancellation: false,
                  noiseSuppression: false
                }}
                ref={publisherRef}
              />
            </OTSession>
          )}

          <div className="video-overlay">
            {/* Hardware Selector and Test */}
            <div
              className={`overlay ${
                (showHardware ||
                  showEndSession ||
                  showReportForm ||
                  showBackToHome ||
                  isResponse) &&
                "show"
              }`}
            ></div>
            {showHardware && (
              <>
                <div className="overlay-box video-setting">
                  <div className="title">
                    <h2>Audio & Video Settings</h2>
                    {startSession && (
                      <a
                        className="model-close cursorPointer"
                        onClick={() => setShowHardware(false)}
                      >
                        ✖
                      </a>
                    )}
                  </div>
                  <div
                    className="inner"
                    style={{ overflowY: "auto", height: "270px" }}
                  >
                    <div className="item">
                      <h3>
                        Microphone{" "}
                        <img
                          style={{ verticalAlign: "middle" }}
                          src={microphoneIcon}
                          alt="microphone"
                        />
                      </h3>
                      <div className="flex-item">
                        <div className="select-option">
                          <select
                            value={audioSource}
                            onChange={(e) => setAudioSource(e.target.value)}
                          >
                            {audioDevices.map((item, index) => (
                              <option
                                key={index}
                                value={item.deviceId}
                                style={{ backgroundColor: "black" }}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <a
                          className="test"
                          onClick={() => {
                            setOpenVidTest(false);
                            setOpenAudioTest(!openAudioTest);
                          }}
                        >
                          <img src={microphoneIcon} alt="microphone" />
                          Test
                        </a>
                      </div>
                      {openAudioTest && (
                        <div
                        // className="precalltestaudio"
                        >
                          <PreCallTestAudio
                            eventId={eventId}
                            audioSource={audioSource}
                            audioDevices={audioDevices}
                          />
                        </div>
                      )}
                    </div>
                    <div className="item">
                      <h3>
                        Video{" "}
                        <img
                          style={{ verticalAlign: "middle" }}
                          src={videoTestIcon}
                          alt="microphone"
                        />
                      </h3>
                      <div className="flex-item">
                        <div className="select-option">
                          <select
                            value={videoSource}
                            onChange={(e) => setVideoSource(e.target.value)}
                          >
                            {videoDevices.map((item, index) => (
                              <option
                                key={index}
                                value={item.deviceId}
                                style={{ backgroundColor: "black" }}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <a
                          className="test"
                          onClick={() => {
                            setOpenAudioTest(false);
                            setOpenVidTest(!openVidTest);
                          }}
                        >
                          <img src={videoTestIcon} alt="video" />
                          Test
                        </a>
                      </div>
                      {openVidTest && (
                        <div className="precalltest">
                          <PreCallTestVid
                            eventId={eventId}
                            videoSource={videoSource}
                            videoDevices={videoDevices}
                          />
                        </div>
                      )}
                    </div>
                    {!startSession && (
                      <div className="btn-wrap text-center">
                        <button
                          type="button"
                          className="primary-button"
                          onClick={() => {
                            setShowHardware(false);
                            setStartSession(true);
                          }}
                        >
                          Start Event
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="video-content-wrap">
              <div className="top-auctions">
                <div className="align-left">
                  <div className="gray-btn-theme">
                    <img
                      src={viewerCountIcon}
                      alt="chat"
                      className="icon-left-align"
                    />
                    {/* {console.log(state.subsCount - 1)} */}
                    {/* {state.subsCount - 1} */}
                    {/* {viewC} */}
                    {numFormatter(Number(viewC))}
                  </div>
                  <div className="gray-btn-theme">
                    <img
                      src={likesCountIcon}
                      alt="chat"
                      className="icon-left-align"
                    />
                    {/* {state.likesCount} */}
                    {eventLikes}
                  </div>
                </div>
                <div className="align-right">
                  {showLive && (
                    <button
                      className="gray-btn-theme"
                      onClick={() => setShowChat(!showChat)}
                    >
                      {`${showChat ? "Hide" : "Show"} Chat`}
                      <img
                        src={iconChat}
                        alt="chat"
                        className="icon-right-align"
                      />
                    </button>
                  )}
                  <div>
                    <button
                      className="gray-btn-theme"
                      onClick={() => setShowShare(!showShare)}
                    >
                      Share
                      <img
                        src={iconShared}
                        alt="chat"
                        className="icon-right-align"
                      />
                    </button>
                    <div className="sharedialog-livestream-wrap">
                      <ShareEvent
                        open={showShare}
                        route="liveStream"
                        shareEventID={eventId}
                        shareEventURL={shareEventURL}
                      />
                    </div>
                  </div>
                  <button
                    className="gray-btn-theme"
                    onClick={() => setShowEndSession(true)}
                  >
                    End
                    <img
                      src={iconEnd}
                      alt="chat"
                      className="icon-right-align"
                    />
                  </button>
                </div>
              </div>

              {isResponse && (
                <div className="event-loader">
                  <Loader />
                </div>
              )}
              <div className="fixed-tip-img">
              <div className="fixed-img-container">
              <div className="tip-fixed-content-img">
                <div>
                  <img src={iconTip} alt="tip-icon" style={{marginBottom: "190px"}} />
                </div>
              </div>
              <div className="tip-fixed-content">
                <div className="gray-btn-theme">
                  {/* Tip: € {state.tippedAmount} */}
                  Tip: € {tipC}
                </div>
              </div>
              </div>
              </div>
              <div
                style={{ display: "flex", position: "absolute", bottom: "0px" }}
              >
                {showLive && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3>
                      Live <span style={{ color: "red" }}>•</span>
                    </h3>
                  </div>
                )}
                <div>
                  <button
                    className="gray-btn-theme"
                    style={{ marginBottom: "0px", minWidth: "0" }}
                    onClick={() => setAudio(!audio)}
                  >
                    <img
                      src={audio ? iconMic : iconMicMute}
                      alt="chat"
                      className="icon-left-align"
                    />
                  </button>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <button
                    className="gray-btn-theme"
                    style={{ marginBottom: "0px", minWidth: "0" }}
                    onClick={() => setVideo(!video)}
                  >
                    <img
                      src={video ? iconVideo : iconVideoMute}
                      alt="chat"
                      className="icon-left-align"
                    />
                  </button>
                </div>
              </div>
              <div>
                <button
                  className="gray-btn-theme"
                  onClick={() => setShowHardware(true)}
                >
                  Config
                  <img
                    src={iconSettings}
                    alt="config"
                    className="icon-right-align"
                  />
                </button>
              </div>
              {!showLive && showGoLive && (
                <div className="btn-wrap text-center go-live">
                  <button
                    type="button"
                    className="primary-button"
                    onClick={() => {
                      if (showGoLiveError) {
                        startBroadcast();
                      } else {
                        Notify("danger", "You can Go Live 5 minutes before the event start time", "", "", "", 5000);
                      }
                    }}
                  >
                    GO LIVE
                  </button>
                  <br />
                  <br />
                  {!showGoLiveError && <div style={{color: "white", background: "black", fontFamily: '"Mulish", sans-serif', fontSize: "17px",
                    lineHeight: "27px", fontWeight: 400 }}>
                    You are not live right now. Please use this time to test
                    your video and audio. You can Go Live 5 minutes before
                    the event start time.</div>}
                </div>
              )}
              {showLive && (
                <div>
                  <img src={watchIcon} alt="timer" style={{ width: "25px" }} />
                  <Countdown
                    date={eventEndTime * 1000}
                    renderer={countdownRenderer}
                  />
                </div>
              )}

              {/* End Event */}
              {showEndSession && (
                <div className="overlay-box video-confirm-pop">
                  <h3>
                    {showLive
                      ? "Are you sure want to end this event?"
                      : "Are you sure you want to go back?"}
                  </h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={() => handleEndSession()}
                      disabled={endSessionResponse}
                    >
                      {endSessionResponse ? (
                        <div>
                          <Logo
                            type="TailSpin"
                            color="#FFFFFF"
                            height={20}
                            width={100}
                          />
                        </div>
                      ) : (
                        "Yes"
                      )}
                    </button>
                    <button
                      type="button"
                      className="secondary-button"
                      onClick={() => setShowEndSession(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}

              {/* Back To Home */}
              {showBackToHome && (
                <div className="overlay-box video-confirm-pop">
                  {isCancelledByAdmin ? (
                    <h3>
                      This event has been deactivated <br /> from admin due to
                      too many reports.
                    </h3>
                  ) : sessionEnd ? (
                    <h3>Your event is now over!!</h3>
                    ) : (
                      <h3>Something went wrong, please restart the event</h3>
                  )}
                  <div
                    className="btn-wrap text-center"
                    style={{ marginTop: "0px" }}
                  >
                    <button
                      type="button"
                      className="primary-button"
                      onClick={() => history.push("/home/actor")}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
              )}

              {/* Report Form */}
              {showReportForm && (
                <div className="overlay-box video-report-form">
                  <div className="title">
                    <h2>Report Form</h2>
                    <a
                      className="model-close cursorPointer"
                      onClick={() => {
                        setShowReportForm(false);
                        setEReportError("");
                        setEReportReason("");
                        setEReportNote("");
                        setCommentBy("");
                      }}
                    >
                      ✖
                    </a>
                  </div>
                  <div className="report-form">
                    <p>Please choose the reason below for reporting.</p>
                    <div className="form-group">
                      <select
                        onChange={(e) => setEReportReason(e.target.value)}
                        value={eReportReason}
                        onFocus={() => setEReportError("")}
                        onBlur={(e) => {
                          if (Validation.empty(eReportReason)) {
                            setEReportError(
                              "Please select a reason to Report Event"
                            );
                          }
                        }}
                      >
                        <option value="" style={{ background: "black" }}>
                          Select Reason
                        </option>
                        {eReasonList.map((item, index) => (
                          <option
                            key={index}
                            value={item.reason}
                            style={{ background: "black" }}
                          >
                            {item.reason}
                          </option>
                        ))}
                      </select>
                      {eReportError && (
                        <span style={{ color: "red" }}>{eReportError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <textarea
                        style={{ resize: "none" }}
                        onChange={(e) => setEReportNote(e.target.value)}
                        placeholder="Add Note (Optional)"
                        rows="7"
                        cols="50"
                        value={eReportNote}
                      ></textarea>
                    </div>
                  </div>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={(e) => handleCommentReport(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              {/* Chat Component */}
              {showChat && (
                <div className="live-comments">
                  <div className="comments-box">
                    <div className="comments-scroll" id="commentScrolling">
                      {state.messages?.map((msgItem, index) => (
                        <Comments
                          msgItem={msgItem}
                          key={index}
                          setShowReportForm={setShowReportForm}
                          setCommentText={setCommentText}
                          setCommentBy={setCommentBy}
                          setIsReportComment={setIsReportComment}
                        />
                        // <div className="loop user-comment" key={index}>
                        //   <img
                        //     src={
                        //       msgItem?.profilePhoto
                        //         ? msgItem.profilePhoto
                        //         : dpPhoto
                        //     }
                        //     alt="chat"
                        //     className="user-photo"
                        //   />
                        //   <div className="user-detail">
                        //     <h5>
                        //       {msgItem.firstName + " " + msgItem.lastName}{" "}
                        //       <span className="time">{msgItem.timeStamp}</span>
                        //     </h5>
                        //     {msgItem.tippedAmount ? (
                        //       <div className="tip-recieved">
                        //         Tip received €{msgItem.tippedAmount}
                        //       </div>
                        //     ) : msgItem.image ? (
                        //       <div
                        //         style={{
                        //           height: "170px",
                        //           width: "100%",
                        //           backgroundImage: `url(${msgItem.image})`,
                        //           backgroundSize: "contain",
                        //           backgroundPosition: "left center",
                        //           backgroundRepeat: "no-repeat",
                        //         }}
                        //       ></div>
                        //     ) : (
                        //       <p>{msgItem.msgText}</p>
                        //     )}
                        //   </div>
                        //   <div className="like-auctions">
                        //     {/* <img
                        //       src={iconHeartRed}
                        //       alt="chat"
                        //       className="heart-like"
                        //     /> */}
                        //     <button className="btn-comment-setting">
                        //       <img
                        //         src={likeAuctionButton}
                        //         alt="chat"
                        //         className="heart-like"
                        //       />
                        //     </button>
                        //     <div className="menu-comment-setting show">
                        //       <ul>
                        //         <li>
                        //           <a>Delete Comment</a>
                        //         </li>
                        //         <li>
                        //           <a>Report Comment</a>
                        //         </li>
                        //       </ul>
                        //     </div>
                        //   </div>
                        // </div>
                      ))}
                    </div>

                    <div className="input-user-comment">
                      {showGif && (
                        <div className="gif-div">
                          <GridDemo
                            onGifClick={(gif, e) => {
                              console.log("gif", gif);
                              e.preventDefault();
                              sendImageSignal(gif.images.preview_gif.url);
                            }}
                          />
                        </div>
                      )}
                      <div className="input-box">
                        <div className="upload-dialog">
                          <img src={addThumb} alt="add" />
                          <input
                            type="file"
                            accept="image/jpeg, image/gif, image/png"
                            onChange={(e) => handleImage(e.target.files[0])}
                            title=""
                          />
                        </div>
                        <input
                          type="text"
                          value={msgText}
                          placeholder="Write a comment...."
                          onChange={(e) => setMsgText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") sendMsgSignal();
                          }}
                        />
                        <div
                          className="gifUpload"
                          onClick={() => setShowGif(!showGif)}
                        >
                          GIF
                        </div>
                        <button type="button" onClick={() => sendMsgSignal()}>
                          Send
                        </button>
                      </div>
                      <div
                        className="like-auctions cursorPointer"
                        onClick={() => {
                          setHeartLike(true);
                        }}
                      >
                        {/* <img
                          src={iconHeartRed}
                          alt="chat"
                          className="heart-like"
                        /> */}
                        <span
                          className={`heart ${heartLike && "heart-active"}`}
                        ></span>
                        <div className="hearts"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER, CHAT_FILE }) => {
  return {
    userData: USER_REDUCER?.myAccountData,
    messageArray: CHAT_FILE.messageArray,
    likesCountSaved: CHAT_FILE.likesCount,
    eventID: CHAT_FILE.eventID,
  };
};

export default preloadScript(connect(mapStateToProps, {})(Publisher));
