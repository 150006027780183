import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import forgot from "../../assets/img/forgot.svg";
import emailSVG from "../../assets/img/email.svg";
import { Validation } from "../../helpers/validations";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import { ForgotPass } from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  // const [showMaintenance ,setShowMaintenance] = useState(false);

  useEffect(() => {
    document.title = "LiveStage | Forgot Password";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])

  // Submit Email for Forgot Password
  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (handleValidation()) {
      const payload = { email: email.trim(), type: "website" };

      try {
        const response = await ForgotPass(payload);
        // console.log(response);

        if (response && response.status === 200) {
          setShowSuccess(true);
          Notify("success", "A Reset Password link has been sent to your email");
        }
      } catch (error) {
        // console.log(error.response.data.message);
        setError(error.response.data.message)
        Notify("success", `Error: ${error.response.data.message}`);
      }
    }
  };

  // Validator function
  const handleValidation = () => {
    if (Validation.empty(email)) {
      setError("Enter your Email");
      return false;
    } else if (!Validation.email(email)) {
      setError("Please enter the email in a valid format");
      return false;
    } else if (!Validation.emailLength(email)) {
      setError("Max 100 characters allowed");
      return false;
    }
    return true;
  };

  return (
    <div id="app" className="page-login">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a href="/login">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="user-section">
        <div className="flex-container">
          {showSuccess ? (
            <ForgotPasswordSuccess />
          ) : (
            <div className="grey-wrap forgetPswd-wrap">
              <div className="flex-row flex-centered">
                <div className="flex-col-2">
                  <div className="img side-line">
                    <img src={forgot} alt="Hero" />
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="login-box content">
                    <div className="title">
                      <h1>Forgot Password</h1>
                      <p>Please provide your registered email address below.</p>
                    </div>
                    <form className="form-forgotpaswd">
                      <div className="field-wrap">
                        <img
                          className="icon-inputtype"
                          src={emailSVG}
                          alt="img"
                        />
                        <input
                          type="email"
                          name=""
                          required=""
                          placeholder="Enter Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={(e) => setError("")}
                          onBlur={(e) => handleValidation()}
                        />
                      </div>
                      {error && <span style={{ color: "red" }}>{error}</span>}
                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="primary-button"
                           onClick={(e) => handleSubmit(e)}
                        //  onClick={()=>setShowMaintenance(true)}
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

  {/* POP UP Message */}
  {/* {showMaintenance ? (
          <div
            className="overlay-box video-confirm-pop"
            style={{ maxWidth: "800px" }}
          >
            <h3>
              LiveStage is improving. We will be back on the 21/07/2022.
              <br />
              For any inquiries please contact us at{" "}
              <a href="mailto:desk@livestage.stream">desk@livestage.stream.</a>
            </h3>
            <div className="btn-wrap text-center" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="primary-button"
                onClick={() => setShowMaintenance(false)}
              >
                Back
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`overlay fix-opaicty ${showMaintenance && "show"}`}
        ></div> */}
{/* ============== */}

    </div>
  );
}

export default ForgotPassword;
